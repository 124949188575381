import React, { useState } from 'react';
import CommentAndTask from '.././component/CommentAndTask';
import closeIcon from '../../../assets/newicons/close.svg';
import frameImage from '../../../assets/newicons/Frame_image.png'
import ArrowBack from '../../../assets/newicons/arrow_back.svg';
import addanotation from '../../../assets/newicons/add-anotion-icon.svg';
import plus from '../../../assets/newicons/variant-plus.svg'
import '.././Annotation.scss'
import { useNavigate } from 'react-router-dom';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useLocation } from 'react-router-dom';


const ImageVersions = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { selectedImage } = location.state || {};
    const { variant } = location.state || false;
    const [selectedVersion, setSelectedVersion] = useState(123);

    const handleVersionChange = (event) => {
        setSelectedVersion(event.target.value);
    };

    const backToReference = () => {
        navigate('/reference')
    }
    return (
        <div>
            <div className='py-3 background-white header-border-radius d-flex justify-content-between px-sm-4 px-2'>
                <div className='d-flex gap-2 align-items-center fw-bold font-noto-sans'>
                    <span className='font-noto-sans fs-14 text-dark-black fw-bold mb-1 '>file-name.png</span>
                    <div className='rounded-pill px-2 py-1 fs-12 bg-color-gray'>PENDING</div>
                </div>
                <div className='d-flex align-items-center gap-md-3 gap-1'>
                    <button className='rounded-pill px-md-4 px-2 bg-transparent h-36 border-black text-black font-noto-sans fw-bold fs-14'>Return</button>
                    <button className='rounded-pill px-md-4 px-2 bg-color-black h-36 border-black text-white font-noto-sans fw-bold fs-14'>Approve</button>
                    <img src={closeIcon} alt='no_image' width={24} onClick={backToReference} />
                </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-md-row ">
                <div className='container-fluid p-0 position-relative heigth-calc-56'>
                    <div className='background-white rounded border d-flex justify-content-center mb-4 mb-lg-0 align-items-center gap-3 px-2 shadow-md position-absolute image-zoom-button'>
                        <button className='border-0 bg-transparent d-flex gap-2 pe-3 py-md-3 py-2 '>
                            <img src={ArrowBack} alt='addanotation' />
                            <p className='mb-0 fw-bold fs-16'>Compare versions</p>
                        </button>
                    </div>
                    <div className='row h-100'>
                        <div className='col-lg-6 px-4'>
                            <div className='d-flex align-items-center justify-content-center h-100  border-right-dashed w-100'>
                                <div className='py-3 py-md-0'>
                                    <div className='d-flex justify-content-center mb-4'>
                                        <FormControl>
                                            <Select
                                                value={selectedVersion}
                                                name="channel"
                                                onChange={handleVersionChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                className="border-2 border h-45 rounded-pill fw-bold py-0 font-noto-sans text-black border-secondary bg-transparent "
                                            >
                                                <MenuItem value={123} selected>Version 1</MenuItem>
                                                <MenuItem value={456} >Version 2</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='font-noto-sans d-flex align-items-center gap-2 mb-2'>
                                        <h6 className='fs-14 fw-bold mb-0 '>Variant 1</h6>
                                        <span className='fs-12 text-black text-dark-gray'>1350x1350px</span>
                                        <span className='fs-12 text-dark-gray'>|</span>
                                        <span className='fs-12 text-dark-gray'>220 KB</span>
                                    </div>
                                    <img src={selectedImage?.imageUrl || frameImage} alt='frameImage' className='image-versions' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 px-4'>
                            <div className='d-flex align-items-center overflow-auto justify-content-center  h-100 '>
                                <div className='py-3 py-md-0'>
                                    <div className='font-noto-sans d-flex align-items-center gap-2 mb-2 h-45 mb-4 justify-content-center'>
                                        <h6 className='fs-14 fw-bold mb-0'>Version 2 (Latest) -</h6>
                                        <span className='fs-14 fw-bold mb-0'>2</span>
                                        <h6 className='fs-14 fw-bold mb-0'>variants</h6>
                                    </div>
                                    <div>
                                        <div className='font-noto-sans d-flex align-items-center gap-2 mb-2'>
                                            <h6 className='fs-14 fw-bold mb-0 '>Variant 1</h6>
                                            <span className='fs-12 text-black text-dark-gray'>1350x1350px</span>
                                            <span className='fs-12 text-dark-gray'>|</span>
                                            <span className='fs-12 text-dark-gray'>220 KB</span>
                                        </div>
                                        <img src={selectedImage?.imageUrl || frameImage} alt='frameImage' className='image-versions' />
                                    </div>
                                    <div className='d-flex overflow-auto gap-4'>
                                        {/* <div >
                                            <div className='font-noto-sans d-flex align-items-center gap-2 mb-2'>
                                                <h6 className='fs-14 fw-bold mb-0 '>Variant 1</h6>
                                                <span className='fs-12 text-black text-dark-gray'>1350x1350px</span>
                                                <span className='fs-12 text-dark-gray'>|</span>
                                                <span className='fs-12 text-dark-gray'>220 KB</span>
                                            </div>

                                            <img src={selectedImage?.imageUrl || frameImage} alt='frameImage' className='image-versions' />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='max-w-420px w-100 bg-color-gray border-start heigth-calc-56 overflow-auto'>
                    <CommentAndTask />
                </div>



            </div>
        </div>
    );
}

export default ImageVersions;
