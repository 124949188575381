import { lazy } from "react";
import { Navigate } from "react-router-dom";
import LoginNew from "../views/Login/login";
import Dashboardd from "../views/Dashboard/dashboard";
import Search from "../views/Search";
import ImageDetails from "../views/ImageDetails";
import NewEdit from "../views/Edit/edit";
import NewUpload from "../views/NewUpload";
import Create from "../views/Create";
import CreateTemplate from "../views/CreateTemplate";
import Manager from "../views/Manager/manager";
import UserSettings from "../views/Manager/settings";
import ManagerSetting from "../views/ManagerSettings/ManagerSetting";
import Reference from "../views/Manager/Reference";
import RefpopUp from "../views/ManagerSettings/RefpopUp";
import Annotation from "../views/Manager/Annotation";
import ImageVersions from "../views/Manager/component/ImageVersions";
import NewImageAnnotate from "../views/Manager/NewImageAnnotate";
import UnAuthorizedPage from "../views/Manager/component/UnauthorizedPage";
import DesignComment from "../views/Manager/DesignComment";
import DesignVersion from "../views/Manager/component/DesignVersion";
import NewDesign from "../views/createNewDesign/NewDesign";
import ImageDesignScreen from "../views/createNewDesign/ImageDesignScreen";
import VisionStudio from "../views/Manager/VisionStudio";

const Edit = lazy(() => import("../views/Edit/index"));
const Upload = lazy(() => import("../views/Upload/index"));
const UploadEdit = lazy(() => import("../views/Upload/UploadEdit"));
const Settings = lazy(() => import("../views/Settings/index"));

export const protectedRoutes = [
  {
    path: "*",
    name: "*",
    element: <UnAuthorizedPage/>
  },
  {
    path: "/dashboard",
    name: "dashboard",
    element: <Manager />,
    new_header: true,
  },
  {
    path: "/search",
    name: "search",
    element: <Search />,
  },
  {
    path: "/edit",
    name: "edit",
    element: <Edit />,
  },
  {
    path: "/upload",
    name: "upload",
    element: <Upload />,
  },
  {
    path: "/upload-edit",
    name: "upload-edit",
    element: <UploadEdit />,
  },
  {
    path: "/settings",
    name: "settings",
    element: <Settings />,
  },
  {
    path: "/image-details",
    name: "image-details",
    element: <ImageDetails />,
  },
  {
    path: "/new_edit",
    name: "new_edit",
    element: <NewEdit />,
  },
  {
    path: "/new_upload",
    name: "new_upload",
    element: <NewUpload />,
  },
  {
    path: "/create",
    name: "create",
    element: <Create />,
  },
  {
    path: "/create-template",
    name: "create-template",
    element: <CreateTemplate />,
  },
  // {
  //   path: "/manager",
  //   name: "manager",
  //   element: <Manager />,
  //   new_header: true,
  // },
  {
    path: "/user-settings",
    name: "user-settings",
    element: <UserSettings />,
    new_header: true,
  },
  {
    path: "/manager-settings",
    name: "manager-settings",
    element: <ManagerSetting />,
    new_header: true,
  },
  {
    path: "/reference",
    name: "reference",
    element: <Reference />,
    new_header: true,
  },
  {
    path: "/ref-pop",
    name: "ref-pop",
    element: <RefpopUp />,
    new_header: true,
  },
  {
    path: "/annotation",
    name: "annotation",
    element: <Annotation />,
    new_header: true,
  },
  {
    path: "/reference-details",
    name: "newimage-annotation",
    element: <NewImageAnnotate />,
    new_header: true,
  },
  {
    path: "/design-comment",
    name: "design-comment",
    element: <DesignComment />,
    new_header: true,
  },
  {
    path: "/image-versions",
    name: "image-versions",
    element: <ImageVersions />,
    new_header: true,
  },
  {
    path: "/design-version",
    name: "designVesrion",
    element: <DesignVersion />,
    new_header: true,
  },
  {
    path: "/vision-studio",
    name: "visionStudio",
    element : <ImageDesignScreen/>,
    new_header: true,
  }

  // {
  //   path: "/new_search",
  //   name: "new-search",
  //   element: <NewSearch/>,
  // },
  // {
  //   path: "/new_dashboard",
  //   name: "new_dashboard",
  //   element: <Dashboardd />,
  // },
];

export const publicRoutes = [
  {
    path: "*",
    name: "*",
    element: <UnAuthorizedPage/>
  },
  {
    path: "/",
    name: "root",
    element: <Navigate to="/dashboard" />,
  },
  {
    path: "/login",
    name: "login",
    element: <LoginNew />,
  },
  {
    path: "/access-denied",
    name: "unauthorized",
    element: <UnAuthorizedPage />
  },
  {
    path: "/new-design",
    name: "new",
    element: <NewDesign />
  },
  {
    path: "/image-design-screen",
    name: "new",
    element: <ImageDesignScreen />
  }

]
