import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchContext } from "../../context/useSearchContext";
import searchIcon from "../../assets/icons/search.svg";
import { useAuthContext } from "../../context/useAuthContext";
import { useNavigate } from "react-router-dom";

let debounceTimer;
const SearchInput = ({ screen, getSearchText, scrollRef, setInputText = null, inputText = "" }) => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { saveSelectedSite, userSettings } = useAuthContext();
  const { formValue, setFormValue } = useSearchContext();
  const [filteredSites, setFilteredSites] = useState(userSettings?.channel_name);
  const [isSiteSearchOpen, setIsSiteSearchOpen] = useState(false);

  const onSiteChange = (index, site = "") => {
    saveSelectedSite(index, site);
    let currentInputValue = formValue.input_text || "";
    const regex = new RegExp(`in:\\s*${site.site_name}`, "g");
    if (formValue.input_text.includes("in:")) {
      currentInputValue = formValue.input_text.replace(/in:.*/, "").trim();
    }
    const newInputValue = currentInputValue.replace(regex, "").trim();
    setFormValue({ ...formValue, input_text: newInputValue });
    setFilteredSites(userSettings?.channel_name);
    setIsSiteSearchOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "input_text") {
      if (value.includes("in:")) {
        setIsSiteSearchOpen(true);
        const searchValue = value.split("in:")[1].trim();
        const filtered = userSettings?.channel_name.filter((site) =>
          site.site_name.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredSites(filtered);
      } else {
        setIsSiteSearchOpen(false);
        setFilteredSites(userSettings?.channel_name);
      }
    }
    if (setInputText) {
      setInputText(value)
    } else {
      setFormValue({ ...formValue, [name]: value });
    }
  };

  const fetchSearchText = () => {
    if (screen === "browseSearch") {
      getSearchText(false);
      if (scrollRef) {
        scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    else {
      navigate("/search")
      getSearchText(false);
      if (scrollRef) {
        scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      debounceMethod();
    }
  };

  const debounceMethod = () => {
    if (debounceTimer) clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      fetchSearchText();
    }, 700); // 300ms delay
  };

  return (
    <div className="position-relative w-100">
      <div className="d-flex w-100">
        <input
          placeholder={t("placeholderText")}
          className="search-input"
          name="input_text"
          data-track="search-field"
          key={formValue.input_text}
          defaultValue={formValue.input_text}
          onChange={(e) => handleChange(e)}
          // onKeyDown={(e) => {
          //   if (e.key === 'Enter') {
          //     fetchSearchText();
          //   }
          // }}
          onKeyDown={handleKeyDown}
        />
        <button
          className="search-button d-flex align-items-center justify-content-center"
          onClick={debounceMethod}
        >
          <img src={searchIcon} alt="searchIcon" className="serch-icon-size" />
        </button>
      </div>
      <div
        className="mt-2 position-absolute bg-white w-100"
        style={{ zIndex: "10" }}
      >
        {isSiteSearchOpen && (
          <>
            <ul className=" list-unstyled sitemenu w-100">
              {userSettings && (
                <li onClick={() => onSiteChange(-1)}>
                  <div className="sitemenu-item text-center fs-14" href="#">
                    All
                  </div>
                </li>
              )}
              {filteredSites &&
                filteredSites?.map((site, index) => (
                  <li key={index} onClick={() => onSiteChange(index, site)}>
                    <div className="sitemenu-item text-center fs-14" href="#">
                      {site?.site_name}
                    </div>
                  </li>
                ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default SearchInput;
