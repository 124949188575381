import React, { useEffect, useState } from 'react';
import Header from './component/header';
import './settings.scss';
import MainHeader from './component/mainHeader';
import person from '../../assets/newicons/person_add.svg';
import Pagination from "./Pagination";
import UserSettingTabelCompo from './component/UserSettingTabelCompo';
import headerProfile from '../../assets/newicons/header-profile.svg';
import InviteUserForm from './component/InviteUserForm';
import { t } from 'i18next';
import UserCreateForm from './component/UserCreateForm';
import { newGet } from '../../API/axios';
import { useAuthContext } from '../../context/useAuthContext';
import { useNavigate } from 'react-router-dom';

const UserSettings = () => {
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(15);
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    const { userSettings } = useAuthContext();
    const [users, setUsers] = useState("")
    const [tab, setTab] = useState(1)
    const [role, setRole] = useState([])
    const [permissions, setPermissions] = useState([])
    const [permissionsList, setPermissionsList] = useState([])
    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);

    const handleTab = (tab) => {
        setTab(tab);
    }

    const handleDefaultSelection = () => {
        setRole({ admin: true, channel_manager: false, channel_user: false });
        setPermissions({
            create_new_projects: true,
            view_projects: true,
            approve_projects: true,
            close_projects: true,
            add_comments: true,
            addUsers_to_watchList: true,
            reAssign_projects: true,
            assign_projects_to_user: true,
            change_date: true
        });
    }

    const handleUserInvitation = () => {
        // closeModal()
    }

    const closeModal = () => {
        const modalElement = document.getElementById('exampleModal');
        const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
        modalInstance.hide();
        handleClearForm()
    };

    const handleClearForm = () => {
        setRole({ admin: false, channel_manager: false, channel_user: false });
        setPermissions({
            create_new_projects: false,
            view_projects: false,
            approve_projects: false,
            close_projects: false,
            add_comments: false,
            addUsers_to_watchList: false,
            reAssign_projects: false,
            assign_projects_to_user: false,
            change_date: false,
        })
    }

    useEffect(() => {
        if (userSettings?.role_id) {
            getMasterData();
            const modalElement = document.getElementById('exampleModal');
            const handleModalHidden = () => {
                handleClearForm();
            };
            modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
            return () => {
                modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
            };
        }

    }, []);

    useEffect(() => {
        // debugger
        // if (userSettings?.role_id !== 1 || userSettings?.role_id !== 2) {
        //     
        // }
        if (userSettings?.role_id) {
            if (![1, 2].includes(userSettings?.role_id)) {
                navigate('/dashboard');
            }
        }
    }, [userSettings]);

    const getMasterData = async () => {
        await newGet('users/getMasterData').then((response) => {
            if (response && response.status_code == 200) {
                setRoles(response?.result?.roles)
                setPermissionsList(response?.result?.permissions)
                setDepartments(response?.result?.department)

            }
        })
    }

    return (
        <div className='' style={{ backgroundColor: "#F6F6F6", height: "100vh" }}>
            {/* <MainHeader /> */}
            <Header />
            <div className='d-flex justify-content-center'>
                <div className='container flex-column'>
                    {/* <div className='d-flex justify-content-center flex-column align-items-start py-2'>
                        <h4 className=''>Settings(CRUD user)</h4>
                    </div> */}



                    <div className='pt-5'>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex gap-4'>
                                <div className='' onClick={() => handleTab(1)}>
                                    <p className={`mb-2 ${tab === 1 && "fw-bold"}`}>{t('userManagement')}</p>
                                    {tab === 1 &&
                                        <hr className={`fw-bold m-0 text-black`} style={{ height: '3px', opacity: "1" }} />
                                    }
                                </div>
                                {/* <div className='' onClick={() => setTab(2)}>
                                    <p className={`mb-2 ${tab === 2 && "fw-bold"}`}>Social media</p>
                                    {tab === 2 &&
                                        <hr className={`fw-bold m-0 text-black`} style={{ height: '3px', opacity: "1" }} />
                                    }
                                </div> */}
                            </div>
                            <div className='d-flex justify-content-end gap-3'>
                                <button type="button"
                                    className="btn bg-dark text-end mt-2 rounded-pill py-1 cursor_pointer"
                                    data-bs-toggle="modal"
                                    data-bs-target="#create_profile">
                                    <img src={person} alt="person" className="pt-1 me-2" />
                                    {t('create')}
                                </button>
                                <button type="button"
                                    onClick={handleDefaultSelection}
                                    className="btn bg-dark text-end mt-2 rounded-pill py-1 cursor_pointer"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal">
                                    <img src={person} alt="person" className="pt-1 me-2" />
                                    {t('invite')}
                                </button>
                            </div>
                        </div>
                        <div className='bg-white round-0 my-3'>
                            <UserSettingTabelCompo key={'userSettingTable'} roles={roles} departments={departments} permissionsList={permissionsList} />
                        </div>
                        {/* <div className="mt-3 d-flex justify-content-end">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    </div> */}
                    </div>
                </div>
            </div>

            {/* 4 Model create User */}
            <div
                className="modal fade"
                id="create_profile"
                tabIndex="-1"
                aria-labelledby="create_profileLabel"
                aria-hidden="true"
            >
                <UserCreateForm
                    roles={roles} departments={departments}
                />
            </div>

            {/* invite user modal */}

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <h5 className="modal-title" id="exampleModalLabel"> {t('inviteUsers')}</h5>
                            <button type="button" onClick={handleClearForm} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <InviteUserForm
                            users={users}
                            setUsers={setUsers}
                            role={roles}
                            permissions={permissionsList}
                            setRole={setRole}
                            setPermissions={setPermissions}
                        />
                        <div className="d-flex justify-content-end gap-4 p-3">
                            <button type="button" className="cancel_modal_footer" data-bs-dismiss="modal" onClick={handleClearForm}>{t('cancel')}</button>
                            <button type="button" className="send_modal_footer" onClick={handleUserInvitation}>{t('invite')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserSettings;
