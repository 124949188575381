import axios from "axios";
import { deleteCookie, getCookie, hasCookie, setCookie } from "cookies-next";
import { createContext, useContext, useEffect, useState } from "react";
import { newGet, newPost, post } from "../API/axios";
import { AUTH_API_ENDPOINTS } from "../constants/api-endpoints";
import { AUTHDOMAIN, LOGIN_CLIENT_ID } from "../constants/envs";
import { AUTH_CONSTANTS } from "../constants/response-keys";
import { getAllSearch } from "../helpers/url";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { showAccessDeniedToast } from '../components/AccessDeniedToast';


const AuthContext = createContext(undefined);


export function useAuthContext() {

  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
}

export const authSessionKey = "_MICO_AUTH_TOKEN_KEY_";
export const userPermissionsKey = "_MICO_USER_PERMISSIONS_";
export const userSiteKey = "_MICO_USER_SITE_KEY";

export function AuthProvider({ children }) {
  const location = useLocation()
  const { t,i18n } = useTranslation();
  const [initialLoading, setInitialLoading] = useState(false);
  const [areAPIsLoading, setAreAPIsLoading] = useState(false);
  const [authInitialLoading, setAuthInitialLoading] = useState(true);
  const [isUserSettingsLoading, setIsUserSettingsLoading] = useState(true);
  const [userSettings, setUserSettings] = useState();

  const [isDebug, setIsDebug] = useState(false);
  const navigate = useNavigate();

  const getExistingSession = () => {
    const fetchedCookie = getCookie(authSessionKey)?.toString();
    if (!fetchedCookie) return undefined;
    else return { access_token: fetchedCookie };
  };

  const getExistingPermissions = () => {
    let fetchedPermissions = userSettings;
    if (sessionStorage.getItem(userPermissionsKey)) {
      try {
        fetchedPermissions = JSON.parse(
          sessionStorage.getItem(userPermissionsKey)
        );
      } catch (e) {
        console.error('Error parsing permissions from session storage:', e);
        sessionStorage.removeItem(userPermissionsKey);
        fetchedPermissions = undefined;
      }
    }
    return fetchedPermissions;
  };

  const getSelectedSite = () => {
    const fetchedSite = JSON.parse(sessionStorage.getItem(userSiteKey));
    if (!fetchedSite) return undefined;
    else return fetchedSite;
  };

  const [user, setUser] = useState(getExistingSession());
  const [permissions, setPermissions] = useState(getExistingPermissions());
  const [selectedSite, setSelectedSite] = useState(getSelectedSite());

  const saveSession = (newUser) => {
    const expires = new Date();
    expires.setSeconds(
      expires.getSeconds() + newUser[AUTH_CONSTANTS.expiresInKey]
    );

    setCookie(authSessionKey, newUser[AUTH_CONSTANTS.tokenKey], {
      expires,
    });
    if (user) setUser(...user, newUser);
    else setUser(newUser);

    // Handle redirection after successful login
    const savedState = localStorage.getItem('loginRedirectState');
    
    if (savedState) {
      const stateParts = savedState.split(",");
      const stateObj = {};
      
      stateParts.forEach((part) => {
        const [key, value] = part.split(":");
        stateObj[key] = value;
      });

      // Remove the saved state from localStorage
      localStorage.removeItem('loginRedirectState');

      // Handle redirect based on state parameter
      if (stateObj.redirect) {
        navigate(decodeURIComponent(stateObj.redirect));
      } else if (stateObj.id) {
        navigate(`image-details?id=${stateObj.id}`);
      }
    } else {

      // Default redirect if no saved state exists
      const params = getAllSearch();
      if (params["code"]) {
        const newUrl = window.location.href.split("?")[0];
        window.history.replaceState({}, document.title, newUrl);
      }
      navigate('/dashboard');
    }
  };

  const saveSelectedSite = (siteIndex, site) => {
    if (siteIndex === -1) {

      const siteIds = userSettings?.channel_name.map((site) => site.site_id);

      let site = { site_id: siteIds, site_name: "All" };
      sessionStorage.setItem(userSiteKey, JSON.stringify(site));
      setSelectedSite(site);
      return;
    }
    sessionStorage.setItem(userSiteKey, JSON.stringify(site));
    setSelectedSite(site);
  };

  const savePermissions = (newPermissions) => {

    const siteIds = newPermissions?.channel_name.map((site) => site.site_id);

    let site = { site_id: siteIds, site_name: "All" };
    sessionStorage.setItem(userPermissionsKey, JSON.stringify(newPermissions));
    const allSites = newPermissions.main_site_id == 0 ? true : false;
    const siteDetails = allSites
      ? site
      : newPermissions.channel_name.find(
        (a) => a.site_id == newPermissions.main_site_id
      );

    // i18n.changeLanguage(newPermissions?.pref_language);
    sessionStorage.setItem(
      userSiteKey,
      JSON.stringify(siteDetails ? siteDetails : newPermissions.channel_name[0])
    );

    if (permissions) {
      const siteDetails = allSites
        ? site
        : permissions.channel_name.find(
          (a) => a.site_id == permissions.main_site_id
        );

      // setPermissions({...permissions, ...newPermissions});
      setSelectedSite(siteDetails ? siteDetails : permissions.channel_name[0]);
    } else {
      const siteDetails = allSites
        ? site
        : newPermissions.channel_name.find(
          (a) => a.site_id == newPermissions.main_site_id
        );

      // setPermissions(newPermissions);
      setSelectedSite(
        siteDetails ? siteDetails : newPermissions.channel_name[0]
      );
    }
  };

  const removePermissions = () => {
    sessionStorage.removeItem(userPermissionsKey);
    setPermissions(undefined);
  };

  const removeSession = () => {
    deleteCookie(authSessionKey);
    removePermissions();
    setUser(undefined);
  };

  const validateToken = async () => {
    return new Promise((resolve, reject) => {
      try {
        const token = getCookie(authSessionKey);
        if (!token) {
          removeSession();
          return resolve(true);
        }
        newGet(AUTH_API_ENDPOINTS.validateToken, {
          access_token: user?.access_token,
        })
          .then((res) => {
            if (res?.status_code != 200) {
              removeSession();
            }
            resolve(true);
          })
          .catch((error) => {
            console.error("Error Validating Token", error);
            removeSession();
            reject(error);
          });
      } catch (error) {
        console.error("Error Validating Token", error);
        removeSession();
        reject(error);
      }
    });
  };

  const getUserPermissions = async () => {
    setInitialLoading(true);
    await post(AUTH_API_ENDPOINTS.getPermissions, {}).then((res) => {
      if (res?.status_code === 200) {
        savePermissions(res);
        const savedLanguage = localStorage.getItem('selectedLanguage') || res.language;
        i18n.changeLanguage(savedLanguage);
        localStorage.setItem('selectedLanguage', savedLanguage);
      }
      setAuthInitialLoading(false);
      setInitialLoading(false);
    });
  };
  const loadSavedLanguage = () => {
    const savedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    i18n.changeLanguage(savedLanguage);
};

  const verifyCodeAndGetTokens = async () => {
    setAuthInitialLoading(true);
    const params = getAllSearch();
    const clientID = LOGIN_CLIENT_ID;
    const clientSecret = "";
    const redirectUri = window.location.origin + "/dashboard";
    const data = `grant_type=authorization_code&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&code=${params["code"]
      }&client_id=${clientID}&client_secret=${clientSecret}`;
    // const fetchedCookie = getCookie(authSessionKey)?.toString();
    // if(!fetchedCookie || fetchedCookie == undefined){
    //   toast.error("Session has been expired, Please login again");
    // }

    axios
      .post(`https://${AUTHDOMAIN}/${AUTH_API_ENDPOINTS.auth2Token}`, data)

      .then(async (res) => {
        if(res.data[AUTH_CONSTANTS.tokenKey]===null || res.data[AUTH_CONSTANTS.tokenKey]===undefined){
          return
        }        
        if (res.data[AUTH_CONSTANTS.tokenKey]) {
          setTimeout(() => {
            saveSession(res.data);
            loadSavedLanguage();
          }, 300);
        }
        // else{
        //   toast.error("Session has been expired, Please login again");
        // }

        // if (!permissions) {
        //   setTimeout(() => {
        //     getUserPermissions()
        //   }, 100)
        // }
      })
      .catch((error) => {
        setAuthInitialLoading(false);
        console.error("error from authPost", error);
      });
  };

  const getUserSettings = async () => {
    setIsUserSettingsLoading(true);
    try {
      const data = await newGet(`project/userSettings`);
      if (data?.result?.[0]) {
        if (!data.result[0].channel_name || data.result[0]?.channel_name?.length == 0) {
          showAccessDeniedToast();
          setTimeout(() => {
            removeSession();
           
            // window.location.href = '/login';
          }, 5000);
          return;
        }
        setUserSettings(data.result[0]);
        savePermissions(data.result[0]);
        sessionStorage.setItem(userPermissionsKey, JSON.stringify(data.result[0]));
      }
      setIsUserSettingsLoading(false);
    } catch (error) {
      console.error("error: in setting", error);
      setIsUserSettingsLoading(false);
    }
  }

  const hasPermission = (permissionKey) => {
    return userSettings?.permissions?.hasOwnProperty(permissionKey);
  }


  useEffect(() => {
    if (location.pathname == "/search" || "/dashboard") {
      // if (user && !permissions) getUserPermissions();
      if (!user) verifyCodeAndGetTokens();
    } else {
      setAuthInitialLoading(false);
    }
  }, [user, location.pathname]);

  useEffect(() => {
    // getUserSettings()
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        initialLoading,
        isAuthenticated: hasCookie(authSessionKey),
        saveSession,
        removeSession,
        validateToken,
        selectedSite,
        saveSelectedSite,
        verifyCodeAndGetTokens,
        authInitialLoading,
        areAPIsLoading,
        setAreAPIsLoading,
        getUserSettings,
        isUserSettingsLoading,
        userSettings,
        hasPermission,
        isDebug,
        setIsDebug
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
