import React, { useRef, useEffect, useState } from 'react';
import { Group, Image, Transformer } from 'react-konva';
import { useDispatch } from 'react-redux';
import { updateElement } from '../../store/konvaSlice';

const DraggableImage = ({ element, sheetId, handleDragEnd, handleDragStart, handleTransformEnd, isSelected, onSelect }) => {
  const [image, setImage] = useState(null);
  const imageRef = useRef();
  const transformerRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (element.image_data?.data) {
      const img = new window.Image();
      img.crossOrigin = 'Anonymous';
      img.src = element.image_data.data.match(/\.(jpg|jpeg|png|gif|svg)$/i) ?
        element.image_data.data :
        `data:image/${element.image_data.format.toLowerCase()};base64,${element.image_data.data}`;
      img.onload = () => {
        setImage(img);
      };
    }
  }, [element.image_data]);

  useEffect(() => {
    if (isSelected && transformerRef.current && imageRef.current) {
      transformerRef.current.nodes([imageRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  // Calculate dimensions to fit image within page while maintaining aspect ratio
  const calculateDimensions = () => {
    if (!image) return { width: 500, height: 500 };
  
    const pageWidth = 500;
    const pageHeight = 500;
    const imageRatio = image.width / image.height;
    const pageRatio = pageWidth / pageHeight;
    const cropStrategy = element.cropStrategy || 'fit';

    let width, height;
    switch (cropStrategy) {
      case 'fill':
        // Fill the entire space while maintaining aspect ratio
        if (imageRatio > pageRatio) {
          height = pageHeight;
          width = pageHeight * imageRatio;
        } else {
          width = pageWidth;
          height = pageWidth / imageRatio;
        }
        break;
      
      case 'stretch':
        // Stretch to fill the entire space without maintaining aspect ratio
        width = pageWidth;
        height = pageHeight;
        break;
      
      case 'center':
        // Use original image dimensions centered in the space
        width = Math.min(image.width, pageWidth);
        height = Math.min(image.height, pageHeight);
        break;
      
      case 'fit':
      default:
        // Fit within the space while maintaining aspect ratio
        if (imageRatio > pageRatio) {
          width = pageWidth;
          height = pageWidth / imageRatio;
        } else {
          height = pageHeight;
          width = pageHeight * imageRatio;
        }
        break;
    }

    return { width, height };
  };

  const dimensions = calculateDimensions();

  // Calculate position based on crop strategy
  const calculatePosition = () => {
    const cropStrategy = element.cropStrategy || 'fit';
    const x = (500 - dimensions.width) / 2;
    const y = (500 - dimensions.height) / 2;

    return { x, y };
  };

  const position = calculatePosition();

  const handleTransform = (e) => {
    const node = imageRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    const rotation = node.rotation();
  
    // Reset scale to 1 after transformation
    node.scaleX(1);
    node.scaleY(1);
  
    // Get the new width and height after transformation
    const newWidth = Math.max(5, node.width() * scaleX); // Prevent the image from going too small
    const newHeight = Math.max(5, node.height() * scaleY); // Prevent the image from going too small
  
    // Create new properties to update the image
    const newProps = {
      x: node.x(),
      y: node.y(),
      width: newWidth,
      height: newHeight,
      rotation: rotation,
      opacity: element.opacity || 1,
      cornerRadius: element.cornerRadius || 0,
      globalCompositeOperation: element.blend_mode || 'source-over'
    };
  
    // Dispatch the updated properties to Redux
    dispatch(updateElement({
      sheetId,
      elementId: element.id,
      newProps
    }));
  };
  

  return (
    <Group
      x={position.x}
      y={position.y}
      draggable={element.zIndex !== 0 && element.draggable}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      dragBoundFunc={(pos) => {
        const sheet = imageRef.current?.getParent()?.getParent();
        const stage = imageRef.current?.getStage();
        if (!sheet || !imageRef.current || !stage) return pos;

        // Get stage scale
        const stageScale = stage.scaleX();

        // Get current element properties
        const currentNode = imageRef.current;
        const width = currentNode.width();
        const height = currentNode.height();

        // Get sheet position
        const sheetPos = sheet.getAbsolutePosition();
        
        // Calculate relative position within sheet, accounting for scale
        const relativePos = {
          x: (pos.x - sheetPos.x) / stageScale,
          y: (pos.y - sheetPos.y) / stageScale
        };

        // Clamp position within sheet bounds, accounting for scale
        const maxX = Math.max(0, 500 - width);
        const maxY = Math.max(0, 500 - height);
        const newX = Math.min(Math.max(0, relativePos.x), maxX);
        const newY = Math.min(Math.max(0, relativePos.y), maxY);

        // Convert back to stage coordinates, accounting for scale
        return {
          x: (newX * stageScale) + sheetPos.x,
          y: (newY * stageScale) + sheetPos.y
        };
      }}
    >
      {image && (
        <>
          <Image
            ref={imageRef}
            image={image}
            width={element.zIndex===0?dimensions.width:(element.width || dimensions.width)}
            height={element.zIndex===0?dimensions.height:(element.height || dimensions.height)}
            opacity={element.opacity || 1}
            cornerRadius={element.cornerRadius || 0}
            globalCompositeOperation={element.blend_mode || 'source-over'}
            rotation={element.rotation || 0}
            onClick={onSelect}
            onTap={onSelect}
            onTransformEnd={handleTransform}
          />

          {isSelected && (
            <Transformer
              ref={transformerRef}
              boundBoxFunc={(oldBox, newBox) => {
                const sheet = imageRef.current?.getParent()?.getParent();
                const stage = imageRef.current?.getStage();
                if (!sheet || !stage) return oldBox;

                const stageScale = stage.scaleX();
                const sheetPos = sheet.getAbsolutePosition();

                // Calculate relative position accounting for scale
                const relativeBox = {
                  x: (newBox.x - sheetPos.x) / stageScale,
                  y: (newBox.y - sheetPos.y) / stageScale,
                  width: newBox.width / stageScale,
                  height: newBox.height / stageScale
                };

                // Minimum and maximum constraints adjusted for scale
                const MIN_SIZE = 5 / stageScale;
                const MAX_SIZE = 500 / stageScale;

                // Check boundaries accounting for scale
                const rightBound = relativeBox.x + relativeBox.width;
                const bottomBound = relativeBox.y + relativeBox.height;

                if (
                  relativeBox.x < 0 ||
                  relativeBox.y < 0 ||
                  rightBound > 500 / stageScale ||
                  bottomBound > 500 / stageScale ||
                  relativeBox.width < MIN_SIZE ||
                  relativeBox.height < MIN_SIZE ||
                  relativeBox.width > MAX_SIZE ||
                  relativeBox.height > MAX_SIZE
                ) {
                  return oldBox;
                }

                return newBox;
              }}
              keepRatio={true}
              padding={5}
              enabledAnchors={[
                'top-left', 'top-right',
                'bottom-left', 'bottom-right'
              ]}
            />
          )}
        </>
      )}
    </Group>
  );
};

export default DraggableImage;