import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import Timeline from "./Timeline";
import Close from '../../../assets/newicons/close.svg';
import { newGet, newPut } from "../../../API/axios";
import { format } from 'date-fns';
import { showAlert } from "./AlertService";
// import { formatDate } from "react-datepicker/dist/date_utils";

const ProjectStages = ({ createdProjectId, projectDetails, projectStatus }) => {


  const { t, i18n } = useTranslation();
  const [timeline, setTimeLine] = useState({
    start_date: null,
    design_submission_due: null,
    client_feedback_due: null,
    implementation_date: null,
    final_delivery_date: null
  });

  const [displayTimelines, setDisplayTimelines] = useState({})

  const [timeLineformValue, setTimeLineFormValue] = useState({
    start_date: null,
    design_submission_due: null,
    client_feedback_due: null,
    implementation_date: null,
    final_delivery_date: null

  })

  const [errors, setErrors] = useState({
    start_date: null,
    design_submission_due: null,
    client_feedback_due: null,
    implementation_date: null,
    final_delivery_date: null
  })


  // Check if project is active based on status
  const isProjectActive = () => {
    if (!projectDetails?.status) return true; // Default to active if no status

    const statusId = parseInt(Object.keys(projectDetails.status)[0]);

    // Status 5 means completed/cancelled project, status 3 means inactive
    return statusId !== 5 && statusId !== 3;
  };


  const getProjectTimeline = async () => {
    try {
      await newGet(`timeline/getTimeline?project_id=${createdProjectId}`).then((res) => {
        if (res?.status_code == 1050) {
          setTimeLine({
            start_date: {
              date: res?.result[0]?.start_date ? format(res?.result[0]?.start_date, 'MM-dd-yyyy') : null,
              acronym: "SD",
              label: "",
              title: "Start Date"
            },
            design_submission_due: {
              date: res?.result[0]?.design_submission_due ? format(res?.result[0]?.design_submission_due, 'MM-dd-yyyy') : null,
              acronym: "DE",
              label: "",
              title: "Design Submission Date"
            },
            client_feedback_due: {
              date: res?.result[0]?.client_feedback_due ? format(res?.result[0]?.client_feedback_due, 'MM-dd-yyyy') : null,
              acronym: "CL",
              label: "",
              title: "Client Feedback Date"
            },
            implementation_date: {
              date: res?.result[0]?.implementation_date ? format(res?.result[0]?.implementation_date, 'MM-dd-yyyy') : null,
              acronym: "IM",
              label: "",
              title: "Implementation Date"
            },
            final_delivery_date: {
              date: res?.result[0]?.final_delivery_date ? format(res?.result[0]?.final_delivery_date, 'MM-dd-yyyy') : null,
              acronym: "FI",
              label: "",
              title: "Final Delivery Date"
            }
          })
          if (res?.result[0] !== null) {
            setTimeLineFormValue({
              start_date: res?.result[0]?.start_date ? format(res?.result[0]?.start_date, 'MM-dd-yyyy') : null,
              design_submission_due: res?.result[0]?.design_submission_due ? format(res?.result[0]?.design_submission_due, 'MM-dd-yyyy') : null,
              client_feedback_due: res?.result[0]?.client_feedback_due ? format(res?.result[0]?.client_feedback_due, 'MM-dd-yyyy') : null,
              implementation_date: res?.result[0]?.implementation_date ? format(res?.result[0]?.implementation_date, 'MM-dd-yyyy') : null,
              final_delivery_date: res?.result[0]?.final_delivery_date ? format(res?.result[0]?.final_delivery_date, 'MM-dd-yyyy') : null
            })
          }
        }
      })
    }
    catch (error) {
      console.error(error)
    }

  }

  const updateProjectTimeline = async () => {
    const requiredFields = [
      { key: "start_date", message: "Start date is required." },
      { key: "design_submission_due", message: "Design submission due date is required." },
      { key: "client_feedback_due", message: "Client feedback due date is required." },
      { key: "implementation_date", message: "Implementation date is required." },
      { key: "final_delivery_date", message: "Final delivery date is required." },
    ];

    let formIsValid = true;
    const newErrors = { ...errors };

    requiredFields.forEach((field) => {
      if (!timeLineformValue[field.key]) {
        newErrors[field.key] = field.message;
        formIsValid = false;
      } else {
        newErrors[field.key] = "";
      }
    });


    const getChangedValues = (newForm, oldForm) => {
      let changedValues = {};

      Object.keys(newForm).forEach((key) => {
        if (JSON.stringify(newForm[key]) !== JSON.stringify(oldForm[key]?.date || {})) {
          changedValues[key] = newForm[key];
        }
      });

      return changedValues;
    };

    const changedFormData = getChangedValues(timeLineformValue, timeline);


    const formData = {
      project_id: createdProjectId,
      ...(changedFormData.start_date && { start_date: changedFormData.start_date }),
      ...(changedFormData.design_submission_due && { design_submission_due: changedFormData.design_submission_due }),
      ...(changedFormData.client_feedback_due && { client_feedback_due: changedFormData.client_feedback_due }),
      ...(changedFormData.implementation_date && { implementation_date: changedFormData.implementation_date }),
      ...(changedFormData.final_delivery_date && { final_delivery_date: changedFormData.final_delivery_date }),
    };


    try {
      if (Object.keys(changedFormData).length === 0) {
        showAlert("There are no changes to update");
        return;
      }

      const res = await newPut("timeline/updateTimeline", formData);

      if (res?.status_code === 1043) {
        showAlert("Timeline Updated Successfully", "success");
        getProjectTimeline();
      }

    } catch (error) {
      console.error(error);

    }
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? format(date, 'MM-dd-yyyy') : null;
    return formattedDate;
  }

  useEffect(() => {
    getProjectTimeline();
  }, [])

  const calculateStepperPercentages = () => {
    const editedTimeline = Object.values(timeline).map(item => ({
      ...item,
      formatDate: item?.date ? new Date(item.date) : null
    })).filter(item => Boolean(item.formatDate)).sort((a, b) => new Date(a) - new Date(b))
    // Filter valid dates
    const stoppingPoints = editedTimeline.map(item => item.formatDate)
    // If fewer than two dates or all dates are the same, distribute equally
    const allDatesSame = stoppingPoints.every(date => date.getTime() === stoppingPoints[0]?.getTime());
    if (allDatesSame) {
      if (stoppingPoints.length <= 1) return
      const section = 100 / (stoppingPoints.length - 1)
      const percentageTimeline = editedTimeline.map((objVal, index) => {
        return {
          ...objVal,
          percentage: section * index,
          leftPos: (section * index) + "%"
        }
      })
      setDisplayTimelines(percentageTimeline)
      return
    }

    // Calculate segment lengths for valid dates
    let segmentLengths = [];
    for (let i = 0; i < stoppingPoints.length - 1; i++) {
      const segmentDiff = stoppingPoints[i + 1] - stoppingPoints[i];
      segmentLengths.push(segmentDiff);
    }
    const maxValue = Math.max(...stoppingPoints)
    const minVal = Math.min(...stoppingPoints)

    // const calculatePercentage = stoppingPoints.map(item => ((item - minVal) * 100 / (maxValue - minVal)).toFixed(2))
    // console.log(calculatePercentage, maxValue)

    const percentageTimeline = editedTimeline.map((objVal, index) => {
      if (!objVal) return
      if (objVal && objVal.date) {
        const percentage = ((new Date(objVal.date) - minVal) * 100 / (maxValue - minVal)).toFixed(2)
        return {
          ...objVal,
          percentage: Number(percentage),
          leftPos: percentage + "%"
        }
      }
    })

    let counter = 0
    for (let index = 1; index < percentageTimeline.length; index++) {
      if (percentageTimeline[index].percentage === percentageTimeline[index - 1].percentage) {
        counter++
        percentageTimeline[index].leftPos = `calc(${percentageTimeline[index - 1].percentage}% + ${counter * 18}px)`
      } else if (percentageTimeline[index].percentage == (percentageTimeline[index - 1].percentage - 5).toFixed(2)) {
        counter++
        percentageTimeline[index].percentage = percentageTimeline[index - 1].percentage
        percentageTimeline[index].leftPos = `calc(${percentageTimeline[index].percentage}% + ${counter * 18}px)`
      } else if (percentageTimeline[index].percentage <= percentageTimeline[index - 1].percentage + 5) {
        counter = 0
        percentageTimeline[index].percentage = percentageTimeline[index].percentage + 5
        percentageTimeline[index].leftPos = `${percentageTimeline[index].percentage}%`
      } else {
        counter = 0
      }
    }

    if (percentageTimeline[percentageTimeline.length - 1].percentage > 100) {
      const margin = percentageTimeline[percentageTimeline.length - 1].percentage - 100
      const ogVal = percentageTimeline[percentageTimeline.length - 1].percentage
      const newVal = percentageTimeline[percentageTimeline.length - 1].percentage - margin
      percentageTimeline[percentageTimeline.length - 1].leftPos = percentageTimeline[percentageTimeline.length - 1].leftPos.replace(ogVal, newVal)
      percentageTimeline[percentageTimeline.length - 1].percentage = newVal
      for (let index = percentageTimeline.length - 2; index >= 0; index--) {
        if (percentageTimeline[index].percentage + 5 >= percentageTimeline[index + 1]?.percentage || percentageTimeline[index].percentage > 100) {
          const newVal = percentageTimeline[index].percentage - margin
          if (newVal < 0) {
            percentageTimeline[index].percentage = 0;
            percentageTimeline[index].leftPos = percentageTimeline[index].leftPos.replace(
              String(percentageTimeline[index].percentage + margin),
              "0"
            );
          } else {
            percentageTimeline[index].leftPos = percentageTimeline[index].leftPos.replace(
              String(percentageTimeline[index].percentage),
              String(newVal)
            );
            percentageTimeline[index].percentage = newVal;
          }
        }
      }
    }
    setDisplayTimelines(percentageTimeline)
  };

  const handleTimeLine = (timeline) => {
    const currentDate = new Date();

    const calculateDaysDifference = (date) => {
      const targetDate = new Date(date);
      const differenceInTime = targetDate.setHours(0, 0, 0, 0) - currentDate.setHours(0, 0, 0, 0);
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
      return differenceInDays;
    };

    if (timeline !== null && timeline?.design_submission_due.date) {
      const days = calculateDaysDifference(timeline.design_submission_due.date);

      if (days > 1) {
        return `${t("designSubmission")} ${t("willBedue")} ${days} ${t("days")}.`;
      } else if (days === 1) {
        return `${t("designSubmission")} ${t("willbedueTomorrow")}.`;
      } else if (days === 0) {
        return `${t("designSubmission")} ${t("isDueToday")}.`;
      } else if (days === -1) {
        return `${t("designSubmission")} ${t("wasDueYesterday")}.`;
      } else {
        return `${t("designSubmission")} ${t("wasDue")} ${Math.abs(days)} ${t("daysAgo")}.`;
      }
    }

    return "No timeline data available";
  };

  useEffect(() => {
    calculateStepperPercentages()
  }, [timeline])

  return (
    <div className="p-lg-4 mt-3 p-md-3 p-3 bg-white bora_24 bg_white_padding">
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="mb-0 text_formate pb-lg-4 pb-md-3 pb-2">{t("projectStages")}</h6>
        {Object.values(displayTimelines).length ?
          <p className="font-noto-sans" style={{ pointerEvents: (projectStatus === 3 || projectStatus === 5) ? "none" : "auto", border: "1px solid gray", fontWeight: "600", borderRadius: "20px", padding: "8px", cursor: "pointer", display: timeline !== null && timeline?.start_date !== null ? "inline-block" : "none", fontSize: "14px", transition: "background-color 0.3s", backgroundColor: (projectStatus === 3 || projectStatus === 5) ? "#E0E0E0" : "transparent" }}
            data-bs-toggle="modal" data-bs-target="#timeline">
            {t("updateTimeline")}</p>
          : null
        }
      </div>
      {Object.values(displayTimelines).length
        ? <p className="font-noto-sans fs-20"
          style={{
            marginBottom: "2rem",
            fontWeight: "600",
            width: "calc(90% + 30px)",
            marginInline: "auto"
          }}
        >{handleTimeLine(timeline)}</p>
        : null
      }
      {timeline !== null && timeline != undefined && !!Object.values(displayTimelines).length &&
        <div className="progress-line">
          {timeline !== null &&
            <div className="d-flex justify-content-between align-content-center mt-3">
              {
                Object.values(displayTimelines || {}).length && Object.values(displayTimelines).map(item => (
                  <Tooltip title={<div>
                    <span>{item.title} <br></br> {handleDateChange(item?.date)}</span>
                  </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                    <div className="item-slot cursor-default" style={{
                      left: item.leftPos
                    }}>
                      {item.acronym}
                    </div>
                  </Tooltip>
                ))
              }
            </div>
          }
        </div>
      }
      {/* <p className="light-red-bg py-2 mt-2 px-3 rounded font-noto-sans fs-14 fw-500">
        The submitted design(s) require revision. Please make the necessary
        updates and resubmit for approval.
      </p> */}

      {/* <div className="p-lg-4 mt-3 p-md-3 p-3 bg-white bora_24 bg_white_padding"> */}
      {/* <h6 className="mb-0 text_formate pb-lg-4 pb-md-3 pb-2">{t("projectStages")}</h6> */}
      {(!timeline.start_date || !Object.values(displayTimelines).length) && (
        <div className="text-center">
          <p className='font-noto-sans' style={{ fontSize: "20px", fontWeight: "700", margin: "0px" }}> {t("timeLineText1")} </p>
          <p className='font-noto-sans' style={{ fontSize: "20px", fontWeight: "700", }}> {t("timeLineText2")}</p>
          <p className="font-noto-sans" style={{ pointerEvents: (projectStatus === 3 || projectStatus === 5) ? "none" : "auto", border: "1px solid gray", fontWeight: "600", borderRadius: "20px", padding: "8px", cursor: "pointer", display: (!timeline.start_date || !Object.values(displayTimelines).length) ? "inline-block" : "none", fontSize: "16px", transition: "background-color 0.3s", backgroundColor: (projectStatus === 3 || projectStatus === 5) ? "#E0E0E0" : "transparent" }} onMouseEnter={(e) => e.target.style.backgroundColor = "#CBC3E3"}
            onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"} data-bs-toggle="modal" data-bs-target="#timeline">
            {t("setUptimeLine")}
          </p>
        </div>
      )}

      {/* Timeline modal */}
      <div className="modal fade" id="timeline" tabIndex="-1" aria-labelledby="timelineLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content px-3">
            <div className="modal-header border-0">
              <div className='upload_design'>
                {timeline === null || timeline === undefined || timeline?.start_date === null ? t("setUptimeLine") : t("updateTimeline")}
              </div>
              <div className='cursor_pointer' data-bs-dismiss="modal" >
                <img src={Close} alt='Close' />
              </div>
            </div>
            <div className="modal-body">
              <Timeline
                isProjectActive={isProjectActive}
                operation={
                  timeline === null ||
                    timeline === undefined ||
                    timeline?.start_date === null ?
                    t("setUptimeLine") : t("updateTimeline")
                }
                timeLineformValue={timeLineformValue}
                setTimeLineFormValue={setTimeLineFormValue}
                errors={errors} setErrors={setErrors}
                updateTimeline={updateProjectTimeline}
                createdProjectId={createdProjectId}
              />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
export default ProjectStages;
