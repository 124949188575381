import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import './ColorPicker.scss'; // Create this file for styles

const ColorPicker = ({ initialColor, onColorChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(initialColor || { r: 255, g: 255, b: 255, a: 1 });

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    const rgbaColor = newColor.rgb;
    setColor(rgbaColor);
    // Convert to hex and call callback
    if (onColorChange) {
      onColorChange(newColor);
    }
  };

  return (
    <div className="color-picker-container">
      <div className="color-swatch" onClick={handleClick}>
        <div
          className="color-display"
          style={{
            background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
          }}
        />
      </div>
      {displayColorPicker && (
        <div className="color-picker-popover">
          <div className="color-picker-cover" onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;