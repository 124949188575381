import React, { useState, useEffect, useRef, useContext } from 'react';
import plusIcon from "../../../assets/newicons/add.svg";
import removeIcon from "../../../assets/newicons/remove-icon.svg";
import aiIcon from "../../../assets/newicons/ai-white-icon.svg";
import smallTabImage from "../../../assets/newimages/tabs-image.png";
import percentage from "../../../assets/newicons/percentage-icon.svg";
import opacityImage from "../../../assets/newicons/opacity-icon.svg";
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import newDesign from "../../../assets/newimages/new-design-image.png";
import refresh from "../../../assets/newicons/refresh.svg";
import aiIconBlack from "../../../assets/newicons/ai-dark-icon.svg";
import upload from '../../../assets/newicons/upload.svg';
import boldIcon from "../../../assets/newicons/bold-icon.svg";
import italicIcon from "../../../assets/newicons/italic-icon.svg";
import underline from "../../../assets/newicons/underlined-icon.svg";
import alignManu from "../../../assets/newicons/format-align.svg";
import formatList from "../../../assets/newicons/format-list.svg";
import formatLine from "../../../assets/newicons/format-line.svg";
import copyIcon from "../../../assets/newicons/copy-icon.svg";
import pastIcon from "../../../assets/newicons/past-icon.svg";
import reArrangeIcon from "../../../assets/newicons/sorting.png";
import duplicateIocn from "../../../assets/newicons/duplicate-iocn.svg";
import deleteIocn from "../../../assets/newicons/delete.svg";
import WebFont from 'webfontloader';
import KonvaCanvas from '../../../components/KonvaCanvas';
import { useKonvaContext } from '../../../context/useKonvaContext';
import roundedCorner from "../../../assets/newicons/rounded-corner-icon.svg";
import revartIcon from "../../../assets/newicons/revert-icon.svg";
import notrevartIcon from "../../../assets/newicons/not-revart-icon.svg";
import roteteImage from "../../../assets/newicons/rotete-center.svg";
import leftroteteImage from "../../../assets/newicons/left-rotete-icon.svg";
import cropIcon from "../../../assets/newicons/crop-icon.svg"
import { CircularProgress, Menu, styled, Tooltip, tooltipClasses } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { initializeCanvas, removeSheet, updateSheetStatus, updateElement, updateSheet, bulkUpdateSheets } from '../../../store/konvaSlice';
import { useDispatch, useSelector } from 'react-redux';
import MagicBrush from './MagicBrush';
import AddImagePopUp from './AddImagePopUp';
import Close from '../../../assets/newicons/close.svg';
import { imageGet, newGet, newGet_V3, newImagePost, newPost, newPut, newPut_V3 } from '../../../API/axios';
import { useReferenceContext } from '../../../context/useReferenceContext';
import UploadDesignFile from '../component/UploadDesignFile';
import { useTranslation } from 'react-i18next';
import BrowseSearch from './BrowseSearch';
import { useSearchContext } from '../../../context/useSearchContext';
import ImageContext from './ImageContext';
import ColorPicker from './ColorPicker';
import './imageComponent.scss';
import UploadComponent from '../../../components/Shared/UploadComponent';

const ImageComponent = ({ selectedImages, typeChange, setSelectedImages, setTypeChange, handleExport, sheet, setShowCommentSection, showCommentSection }) => {
  const dispatch = useDispatch();
  const canvas = useSelector(state => state.konva.canvas);
  const [scale, setScale] = useState(1);
  const [reload, setReload] = useState(0);
  const { setResetPopup, setImages } = useContext(ImageContext);

  const [sheetName, setsheetName] = useState("Sheet 1");
  const [isEditing, setIsEditing] = useState(false);
  const editorRef = useRef(null);
  const [layerId, setLayerId] = useState(null);
  const { t } = useTranslation();
  const [returnComment, setReturnComment] = useState("");
  const { designId, boardId } = useReferenceContext();
  const { setSheetCount, sheetCount, selectedSheetId, showSideModal, selectedId, selectedSheet, sheetListLoading, setSheetListLoading, selectedSheetElement, setSelectedSheetElement, setSelectedSheet, isSelecting, setIsSelecting, setSelectedSheetIds, setSelectedSheetLayerId } = useKonvaContext();

  const modalRef = useRef(null);
  const [currentSheetId, setCurrentSheetId] = useState(0);
  const [showImageDetails, setShowImageDetails] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false)
  const { setResults, setShowResults, setFormValue } = useSearchContext()
  const [selectionBox, setSelectionBox] = useState(null);
  const [details, setDetails] = useState({
    cornerRadius: selectedSheetElement?.cornerRadius || 0,
    blend_mode: selectedSheetElement?.blend_mode || "source-over",
    opacity: selectedSheetElement?.opacity || 1,
    cropStrategy: selectedSheetElement?.cropStrategy || "fit",
    color: selectedSheetElement?.color || "000000"
  })
  const [uploadPopUpOpen, setUploadPopupOpen] = useState(false)


  const { showSideModal: konvaShowSideModal,
    fontSize,
    setFontSize,
    fontFamily,
    setFontFamily,
    color,
    setColor,
    menuPosition,
    contextMenuVisible,
    setContextMenuVisible,
    newTextValue,
    setNewTextValue,
    inputPosition,
    showTextOptionsModal, setShowTextOptionsModal,
    fonts,
    selectedLayerId,
    setTextStyle,
    textStyle,
    cornerRadiusValue,
    setCornerRadiusValue,
    blendModes,
    selectedBlendMode,
    setSelectedBlendMode,
    setOpacity,
    opacity,
    setSelectedImageId,
    setSelectedId,
    setShowSideModal,
    stageRef // Add this to the destructured values,
  } = useKonvaContext();

  const loadGoogleFonts = () => {
    WebFont.load({
      google: {
        families: fonts.map(font => font.family),
      },
    });
  };


  useEffect(() => {
    if (selectedSheetElement) {
      setFontFamily(selectedSheetElement.fontFamily);
      setFontSize(selectedSheetElement.fontSize);
      setColor(selectedSheetElement.fill);
      setOpacity(getOpacity(selectedSheetElement));
      setCornerRadiusValue(selectedSheetElement.cornerRadius);
      setSelectedBlendMode(selectedSheetElement.blend_mode);
    }
  }, [selectedSheetElement])

  const getSheetList = async () => {
    const response = await newGet_V3(`sheet/getSheetList?design_id=${designId}`)
    setSheetCount(response?.result?.length)
    console.log("getSheetLisr", response);

  }

  useEffect(() => {
    loadGoogleFonts();
    // getSheetList()
    getDesignDetails(designId);
  }, []);

  const getDesignDetails = async (designId) => {
    setSheetListLoading(true)
    const urlParams = new URLSearchParams(window.location.search);
    const design_id = urlParams.get("design_id");
    try {
      const designData = await newGet_V3(`design/getDesignDetail?design_id=${design_id || designId}`);

      if ([200, 201].includes(designData?.status_code)) {
        setSheetCount(designData?.result?.sheets.length);

        // Find the layer ID from sheets that matches selectedSheetElement.id
        let layerId;
        if (selectedSheetElement) {
          for (const sheet of designData?.result?.sheets) {
            const element = sheet.layers.find(layer => {
              return layer.id === selectedSheetElement.id;
            });
            if (element) {
              setSelectedSheetElement(element);
              setSelectedSheet(sheet); // Set the selected sheet when element is found
            }
          }
        }
        console.log("designn detail update", designData.result)
        dispatch(initializeCanvas(designData.result));

        if (selectedSheetElement) {
          // Perform actions based on the selected layer
          console.log("Selected Layer:", selectedSheetElement);
          // You can dispatch actions or update the canvas here
        }

        setTimeout(() => {
          if (!initialPositions) {
            initInitialPositions();
          }
        }, 1000);

        setSheetListLoading(false)
      } else {
        setSheetListLoading(false)
        dispatch(initializeCanvas({
          id: null,
          sheets: []
        }));
      }
    }
    catch (error) {
      console.log(error)
    }
  }


  const handleRewrite = () => {
    if (selectedId && selectedId.includes('text')) {
      const newProps = {
        text: newTextValue,
      };
      dispatch(updateElement({
        sheetId: selectedSheetId,
        elementId: selectedId,
        newProps
      }));
      setShowTextOptionsModal(false);
    }
  };

  const hideContextMenu = () => {
    setContextMenuVisible(false);
  };

  const handleFontChange = (e) => {
    setFontFamily(e.target.value);
    if (selectedSheetId && selectedId) {
      dispatch(updateElement({
        sheetId: selectedSheetId,
        elementId: selectedId,
        newProps: {
          fontFamily: e.target.value,
        }
      }));
    }
  };

  const handleFontSizeChange = (e) => {
    setFontSize(e.target.value);
    if (selectedLayerId && selectedId) {
      const newProps = {
        fontSize: e.target.value,
      };
      dispatch(updateElement({ layerId: selectedLayerId, elementId: selectedId, newProps }));
    }
  };

  const handleColorChange = (color) => {

    const rgba = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    setDetails({ ...details, color: rgba })
    if (selectedId) {

      const sheetId = selectedSheetId;
      dispatch(updateSheet({
        sheetId,
        updates: {
          backgroundColor: rgba, // Change this to backgroundColor
        }
      }));
    }
  };


  const textFontStyleClick = (e, name) => {
    e.preventDefault();
    const styleMap = {
      bold: { prop: 'fontWeight', activeValue: 'bold', inactiveValue: 'normal' },
      italic: { prop: 'fontStyle', activeValue: 'italic', inactiveValue: 'normal' },
      underline: { prop: 'textDecoration', activeValue: 'underline', inactiveValue: 'none' }
    };

    const { prop, activeValue, inactiveValue } = styleMap[name];

    if (selectedLayerId && selectedId) {
      const currentValue = textStyle[name];
      const newValue = currentValue !== activeValue ? activeValue : inactiveValue;

      const newProps = {
        [prop]: newValue,
      };

      dispatch(updateElement({
        layerId: selectedLayerId,
        elementId: selectedId,
        newProps,
      }));

      setTextStyle((prevStyle) => ({
        ...prevStyle,
        [name]: newValue
      }));
    }
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },

    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 160,
      backgroundColor: "black"
    },

  }));
  const handleZoomIn = () => {
    const newScale = Math.min(scale + 0.1, 3);  // Max zoom 300%
    setScale(newScale);
  };

  const handleZoomOut = () => {
    const newScale = Math.max(scale - 0.1, 0.1);  // Min zoom 10%
    setScale(newScale);
  };

  // Handle mouse wheel zoom
  const handleWheel = (e) => {
    if (e.ctrlKey) {
      e.preventDefault();
      const delta = e.deltaY;
      setScale(prev => {
        const newZoom = delta > 0
          ? Math.max(prev - 0.1, 0.1)  // Zoom out
          : Math.min(prev + 0.1, 3);   // Zoom in
        return Number(newZoom.toFixed(1));
      });
    }
  };

  const handleSetImageUrl = (url) => {
    setImageUrl(url);
  };

  const onHandleSelectedImage = async () => {
    try {
      setLoading(true);
      let blob;

      try {
        const response = await fetch(imageUrl);
        blob = await response.blob();
      } catch (error) {
        blob = await imageGet("download_image", { image_url: imageUrl });
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        let base64Image = reader.result; // This is the base64 string

        // Remove the prefix
        base64Image = base64Image.replace(/^data:image\/jpeg;base64,/, ''); // Remove the prefix

        const file = new File([blob], `image_${Date.now()}.jpg`, { type: blob.type });
        const objectURL = URL.createObjectURL(file);
        file.objectURL = objectURL;

        console.log("Image URL:", imageUrl);
        console.log("Payload:", { layer_id: selectedSheetElement.id, layer_type: selectedSheetElement.image_type, update_column: { image_data: file } });

        console.log("Base64 Image before API call:", base64Image); // Log the base64 image
        const replacedImage = await newPut_V3(`layer/changeLayerValue`, {
          layer_id: selectedSheetElement.id,
          layer_type: selectedSheetElement.image_type,
          sheet_id: selectedSheet.id,
          updates: { url_path: imageUrl, image_data: base64Image }
        });

        if ([201, 200].includes(replacedImage.status_code)) {
          // await getDesignDetails();
          await getSheetDetails(selectedSheet.id)
          setLoading(false);
          const modalElement = document.getElementById('search_modal');
          const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
          modalInstance.hide();
          setShowImageDetails(false);
        }
      };

      reader.readAsDataURL(blob); // Convert blob to base64
    } catch (error) {
      console.error(error);
    }
  };

  // Add wheel event listener
  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      editor.addEventListener('wheel', handleWheel, { passive: false });
    }
    return () => {
      if (editor) {
        editor.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 250,
      color: 'rgb(55, 65, 81)',
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        fontWeight: 500,
        fontSize: 14,
        '& .MuiSvgIcon-root': {

          color: theme.palette.text.secondary,
          marginRight: theme.spacing(0.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[300],
      }),
    },
  }));


  const handleDoubleClick = () => {
    setIsEditing(true); // Enable editing mode
  };

  const handleChange = (event) => {
    setsheetName(event.target.value); // Update text as user types
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setIsEditing(false); // Save and exit editing mode on Enter
    }
  };

  const handleBlur = () => {
    setIsEditing(false); // Save and exit editing mode on blur
  };

  const handleRemoveSheet = async (sheetId) => {
    try {
      const response = await newPut('sheet/delete', {
        sheet_id: sheetId,
      });

      if (response?.status_code === 200) {
        dispatch(removeSheet(sheetId));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const lockNRequestApproval = async () => {
    try {
      const response = await newPut(`approval/sentForApproval`, {
        "sheet_ids": [selectedSheetId]
      });

      if ([200, 201].includes(response?.status_code)) {
        // Update Redux store with new status
        dispatch(updateSheetStatus({
          sheetId: selectedSheetId,
          status: 2  // 2 represents pending approval status
        }));
        
      }
     
    } catch (error) {
      console.error("Error in lockNRequestApproval:", error);
    }
  };

  const cancelRequestApproval = async () => {
    try {
      const response = await newPut(`approval/revoke`, {
        "sheet_ids": [selectedSheetId]
      });

      if ([200, 201].includes(response?.status_code)) {
        // This will update status and automatically unlock the sheet
        dispatch(updateSheetStatus({
          sheetId: selectedSheetId,
          status: 1  // 1 for draft status
        }));
      }
    } catch (error) {
      console.error("Error in cancelRequestApproval:", error);
    }
  };

  const handleReturnSheet = async () => {
    try {
      const response = await newPut(`approval/return`, {
        "sheet_ids": [currentSheetId],
        "return_reason": returnComment
      });

      if ([200, 201].includes(response?.status_code)) {
        dispatch(updateSheetStatus({
          sheetId: currentSheetId,
          status: 4  // 4 for returned status
        }));
        // Close modal or reset comment if needed
        setReturnComment('');
      }
    } catch (error) {
      console.error("Error in handleReturnSheet:", error);
    }
  }



  // Handle blend mode changes
  const handleBlendModeChange = (e) => {
    const value = e.target.value;
    setSelectedBlendMode(value);
    if (selectedId) {
      const element_id = selectedId.includes('shape') ? selectedId : Number(selectedId.split('_')[1]);
      const sheet = canvas.layers[0].groups.find(group =>
        group.elements.some(element => element.id === element_id)
      );

      if (sheet) {
        const newProps = {
          blend_mode: value,
        };
        setDetails({ ...details, blend_mode: value })
        dispatch(updateElement({
          sheetId: sheet.id,
          elementId: element_id,
          newProps
        }));
      }
    }
  };

  // Handle corner radius changes
  const handleCornerRadius = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if ((regex.test(value) || value === '') && Number(value) >= 0 && Number(value) <= 100) {
      setCornerRadiusValue(parseFloat(value));
      if (selectedId) {
        const element_id = selectedId.includes('shape') ? selectedId : Number(selectedId.split('_')[1]);
        const sheet = canvas.layers[0].groups.find(group =>
          group.elements.some(element => element.id === element_id)
        );
        if (sheet) {
          const newProps = {
            cornerRadius: parseFloat(value), // Single value for all corners
          };
          setDetails({ ...details, cornerRadius: parseFloat(value) })
          dispatch(updateElement({
            sheetId: sheet.id,
            elementId: element_id,
            newProps
          }));
        }
      }
    }
  };

  // Handle rotation
  const handleRotation = (direction) => {
    if (selectedId) {
      const element_id = selectedId.includes('shape') ? selectedId : Number(selectedId.split('_')[1]);
      const sheet = canvas.layers[0].groups.find(group =>
        group.elements.some(element => element.id === element_id)
      );

      if (sheet) {
        const element = sheet.elements.find(el => el.id === element_id);
        const currentRotation = element?.rotation || 0;
        const newRotation = direction === 'left' ? currentRotation - 90 : currentRotation + 90;

        const newProps = {
          rotation: newRotation,
        };
        dispatch(updateElement({
          sheetId: sheet.id,
          elementId: element_id,
          newProps
        }));
      }
    }
  };

  // Update opacity handler for images
  const handleImageOpacity = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if ((regex.test(value) || value === '') && Number(value) >= 0 && Number(value) <= 100) {
      setOpacity(parseFloat(value));

      if (selectedId) {
        const element_id = selectedId.includes('shape') ? selectedId : Number(selectedId.split('_')[1]);
        // Find the sheet containing the selected image
        const sheet = canvas.layers[0].groups.find(group =>
          group.elements.some(element => element.id === element_id)
        );

        if (sheet) {
          const newProps = {
            opacity: parseFloat(value) / 100, // Convert percentage to decimal
          };
          setDetails({ ...details, opacity: parseFloat(value) / 100 })
          dispatch(updateElement({
            sheetId: sheet.id,
            elementId: element_id,
            newProps
          }));
        }
      }
    }
  };

  // Handle crop and scale changes
  const handleCropAndScale = (e) => {
    const value = e.target.value;
    if (selectedId) {
      const element_id = Number(selectedId.split('_')[1]);
      const sheet = canvas.layers[0].groups.find(group =>
        group.elements.some(element => element.id === element_id)
      );

      if (sheet) {
        const newProps = {
          cropStrategy: value,
        };
        setDetails({ ...details, cropStrategy: value })
        dispatch(updateElement({
          sheetId: sheet.id,
          elementId: element_id,
          newProps
        }));
      }
    }
  };

  // Get the selected element from canvas state
  const getSelectedElement = () => {
    if (selectedId) {
      const element_id = Number(selectedId.split('_')[1]);
      const sheet = canvas.layers[0].groups.find(group =>
        group.elements.some(element => element.id === element_id)
      );
      if (sheet) {
        return sheet.elements.find(el => el.id === element_id);
      }
    }
    return null;
  };

  const selectedElement = getSelectedElement();
  const imageLink = (element, defaultImage) => {
    if (!element || !element.image_data) {
      return defaultImage;
    }

    if (element.image_data.data) {
      return element.image_data.data.match(/\.(jpg|jpeg|png|gif|svg)$/i) ?
        element.image_data.data :
        `data:image/${element.image_data.format?.toLowerCase() || 'png'};base64,${element.image_data.data}`;
    }

    return defaultImage;
  }

  const getOpacity = (element) => {
    return element.opacity ? element.opacity * 100 : 100;
  }

  const modalOpen = () => {
    setResetPopup('');
    setResetPopup('reset');
  }

  const [initialPositions, setInitialPositions] = useState(null);

  // Store initial positions when component mounts or canvas updates
  const initInitialPositions = () => {
    if (canvas?.layers?.[0]?.groups.length > 0) {

      const positions = {};
      canvas.layers[0].groups.forEach(sheet => {
        positions[sheet.id] = {
          x: sheet.position?.x || 50,
          y: sheet.position?.y || 50
        };
      });
      setInitialPositions(positions);
    }
  }

  const handleReset = () => {
    // Reset zoom level to 1
    setScale(1);

    // Reset stage position to 0,0 (center)
    const stage = stageRef.current;
    if (stage) {
      stage.position({ x: 0, y: 0 });
    }

    // Reset all sheets to their initial positions
    if (canvas?.layers?.[0]?.groups) {
      const bulkUpdates = canvas.layers[0].groups.map(sheet => ({
        sheetId: sheet.id,
        updates: {
          position: initialPositions[sheet.id] || { x: 50, y: 50 }
        }
      }));

      dispatch(bulkUpdateSheets(bulkUpdates));
    }
  };

  const handleReorderSheets = async () => {
    try {
      if (!canvas?.layers?.[0]?.groups?.length) return;

      setScale(1);
      const stage = stageRef.current;
      if (stage) {
        stage.position({ x: 0, y: 0 });
        stage.batchDraw();
      }

      const groups = canvas.layers[0].groups;
      const sheetIds = [];
      const positionXs = [];
      const positionYs = [];
      const Y_POSITION = 50;
      const X_SPACING = 600;

      // Create bulk update array
      const bulkUpdates = groups.map((sheet, index) => {
        const xPos = 50 + (index * X_SPACING);
        sheetIds.push(sheet.id);
        positionXs.push(xPos);
        positionYs.push(Y_POSITION);

        return {
          sheetId: sheet.id,
          updates: {
            position: {
              x: xPos,
              y: Y_POSITION
            }
          }
        };
      });

      // Dispatch single action for all updates
      dispatch(bulkUpdateSheets(bulkUpdates));

      // // Force immediate stage update
      // stage?.batchDraw();

      // Save to backend
      const response = await newPut_V3('sheet/saveAllSheetsCoordinates', {
        design_id: designId,
        sheet_ids: sheetIds,
        position_xs: positionXs,
        position_ys: positionYs
      });

      if ([200, 201].includes(response?.status_code)) {
        await getDesignDetails(designId);
      }
    } catch (error) {
      console.error('Error reordering sheets:', error);
    }
  };

  const boxWidth = 15;
  const boxHeight = 15;
  const boxX = (window.innerWidth - boxWidth) / 2;
  const boxY = 10;

  // Add this helper function at the top level of the component:
  const parseRgba = (rgba) => {

    if (!rgba) return { r: 255, g: 255, b: 255, a: 1 };
    const match = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/);

    if (match) {
      return {
        r: parseInt(match[1]),
        g: parseInt(match[2]),
        b: parseInt(match[3]),
        a: match[4] ? parseFloat(match[4]) : 1
      };
    }
    return { r: 255, g: 255, b: 255, a: 1 };
  };

  // selection of multiple sheets
  const handleMouseDown = (e) => {
    if (!isSelecting) return; // Only activate if selecting
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    // Get the actual position of the cursor
    const { x, y } = pointerPosition;

    // Set the starting point of the selection box
    setSelectionBox({ startX: x, startY: y, endX: x, endY: y });
  }

  const handleMouseMove = (e) => {
    if (!isSelecting) return;
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();

    // Get the actual position of the cursor
    const { x, y } = pointerPosition;

    // Update the end point of the selection box
    setSelectionBox((prev) => ({ ...prev, endX: x, endY: y }));
  };

  const handleMouseUp = () => {
    if (!isSelecting) return;
    setIsSelecting(false);
    // Check which sheets are within the selection box
    const selectedIds = canvas?.layers[0]?.groups
      .filter(sheet => {
        const { x, y } = sheet.position; // Ensure this is a Konva node
        return (
          x >= selectionBox.startX &&
          x <= selectionBox.endX &&
          y >= selectionBox.startY &&
          y <= selectionBox.endY
        );
      })
      .map(sheet => sheet.id);
    console.log(">>>>>>>>>>>>>>>>>>>>selectedIds", selectedIds)
    setSelectedSheetIds(selectedIds); // Update selected sheet IDs
    setSelectionBox(null); // Clear selection box
  };

  // Add these new state variables near the top of your component
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);

  // Add these new handlers inside your component
  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
    e.target.classList.add('dragging');
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragEnd = (e) => {
    setDraggedIndex(null);
    setDragOverIndex(null);
    e.target.classList.remove('dragging');
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDragOverIndex(index);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e, dropIndex) => {
    e.preventDefault();
    const dragIndex = parseInt(e.dataTransfer.getData('text/plain'));

    if (dragIndex === dropIndex) return;

    try {
      // Create a copy of the sheet
      const updatedSheet = { ...selectedSheet };

      // Create a new array with reordered elements
      const newElements = [...updatedSheet.elements];
      const [draggedElement] = newElements.splice(dragIndex, 1);
      newElements.splice(dropIndex, 0, draggedElement);

      // Update z-index values based on new order
      newElements.forEach((element, index) => {
        element.zIndex = newElements.length - index; // Higher index = appears on top
      });

      // Update the sheet with new element order
      await dispatch(updateSheet({
        sheetId: selectedSheetId,
        updates: {
          elements: newElements
        }
      }));

      // Refresh the design details to ensure everything is in sync
      await getDesignDetails(designId);
    } catch (error) {
      console.error("Error reordering layers:", error);
    }

    setDraggedIndex(null);
    setDragOverIndex(null);
  };



  const hasDetailsChanged = (details, selectedElement) => {
    // Check if any property in details is different from selectedElement
    for (const key in details) {
      if (details[key] !== selectedElement[key]) {
        return true; // Change detected
      }
    }
    return false; // No changes
  };


  const getSheetDetails = async (sheetId) => {
    const response = await newGet_V3(`sheet/getSheetDetail?sheet_id=${sheetId}`);
    if (selectedSheetElement) {

      const element = response?.result?.layers.find(layer => {
        return layer.id === selectedSheetElement.id;
      });
      if (element) {
        setSelectedSheetElement(element);
        setSelectedSheet(response.result); // Set the selected sheet when element is found
      }
    }
    const sheet = response.result
    await dispatch(updateSheet({
      sheetId: selectedSheetId,
      updates: {
        name: sheet?.sheet_name || "Untitled",
        status_id: sheet?.sheet_status_id || 1,
        is_locked: sheet?.sheet_is_locked || false,
        position: {
          x: sheet?.position?.[0] || null,
          y: sheet?.position?.[1] || null
        },
        elements: sheet.layers?.map((layer) => ({
          ...layer,
          id: layer.id,
          type: 'Image',
          sheet_id: sheet.sheet_id || sheet.id,
          x: 0,
          y: 0,
          width: 500,
          height: 500,
          image_data: {
            data: layer?.image_data?.data || '',
            format: layer?.image_data?.format || 'PNG',
            size: layer?.image_data?.size || [500, 500]
          },
          draggable: false,
          blend_mode: layer?.blend_mode || 'norm',
          opacity: layer?.opacity || 1,
          cornerRadius: layer?.cornerRadius || 0,
          position: {
            x: layer?.position?.[0] || 0,
            y: layer?.position?.[1] || 0
          },
          rotation: layer?.rotation || 0,
          visible: layer?.visible !== undefined ? layer?.visible : true,
          zIndex: layer?.zIndex || 0
        })) || []
      }
    }))

  }


  const handleSaveDetails = async () => {
    console.log("Details before checking:", selectedElement);

    // Check if details have changed compared to selectedSheetElement
    const changedDetails = Object.keys(details).reduce((acc, key) => {
      if (details[key] !== selectedSheetElement[key]) {
        acc[key] = details[key];
      }
      return acc;
    }, {});
    if (Object.keys(changedDetails).length > 0) {
      console.log("Changed details:", changedDetails);
      const updates = {
        blend_mode: changedDetails.blend_mode,
        opacity: changedDetails.opacity,
        corner_radius: changedDetails.cornerRadius,
        color: changedDetails.color
      };
      console.log("selectedSheetElement.image_type", selectedSheetElement)
      // Only include crop_scale if the image type is not 'background'
      if (selectedSheetElement.image_type !== 'background') {
        updates.crop_scale = changedDetails.cropStrategy;
        delete updates.color;
      }
      if (selectedSheetElement.image_type === 'background') {
        debugger
        delete updates.crop_scale;
      }

      console.log("updates>>>>>>>>>>>>>>>>>", updates)

      const response = await newPut_V3(`layer/changeLayerValue`, {
        layer_id: selectedSheetElement.id,
        layer_type: selectedSheetElement.image_type,
        sheet_id: selectedSheetId,
        updates: updates
      });
      if ([200, 201].includes(response?.status_code)) {
        await getSheetDetails(selectedSheetId)
      }

      // Uncomment and implement the API call if needed

    };
  }


  return (
    <div className='d-flex justify-content-center w-100 main-image-editor position-relative overflow-hidden'>
      <KonvaCanvas
        selectedImages={selectedImages}
        setSelectedImages={setSelectedImages}
        scale={scale}
        setScale={setScale}
        removeSheet={handleRemoveSheet}
        modalRef={modalRef}
        setCurrentSheetId={setCurrentSheetId}
        lockNRequestApproval={lockNRequestApproval}
        cancelRequestApproval={cancelRequestApproval}
        selectionBox={selectionBox}
        setSelectionBox={setSelectionBox}
        handleMouseDown={handleMouseDown}
        handleMouseUp={handleMouseUp}
        handleMouseMove={handleMouseMove}
        sheetListLoading={sheetListLoading}
        setShowCommentSection={setShowCommentSection}
        showCommentSection={showCommentSection}
      />

      {/* <div className={`${showSideModal && "version-image-active"} version-image-container d-flex flex-column align-items-center gap-2`}>
        {selectedSheet?.elements?.map((element, index) => (
          <div
            className={`version-image-wrapper ${draggedIndex === index ? 'dragging' : ''} 
              ${dragOverIndex === index ? 'drag-over' : ''}`}
            key={element.id}
            draggable="true"
            onDragStart={(e) => handleDragStart(e, index)}
            onDragEnd={handleDragEnd}
            onDragOver={(e) => handleDragOver(e, index)}
            onDragLeave={handleDragLeave}
            onDrop={(e) => handleDrop(e, index)}
          >
            <div
              className='version-image'
              onClick={() => {

                setSelectedSheetElement(element);
                setSelectedImageId(element.id)
                setSelectedId(`image_${element.id}`);
                setShowSideModal(true);
              }}
            >
              <img src={imageLink(element, smallTabImage)} alt='smallTabImage' className='h-100' />
            </div>
            {dragOverIndex === index && <div className="drop-indicator" />}
          </div>
        ))}
      </div> */}
      <div>


        {/* Zoom controls */}
        <div className="image-editor-controls">
          <div className="zoom-controls">
            <button
              className="zoom-btn"
              onClick={handleZoomOut}
              disabled={scale <= 0.1}
            >
              <img src={removeIcon} alt="Zoom out" />
            </button>
            <span className="zoom-level">{Math.round(scale * 100)}%</span>
            <button
              className="zoom-btn"
              onClick={handleZoomIn}
              disabled={scale >= 3}
            >
              <img src={plusIcon} alt="Zoom in" />
            </button>
            <button
              className="zoom-btn ms-2"
              onClick={handleReset}
              title={t('RESET_VIEW')}
            >
              <img src={revartIcon} alt="Reset view" style={{ width: "20px", height: "20px" }} />
            </button>
            <button
              className="zoom-btn ms-2"
              onClick={handleReorderSheets}
              title={t('REARRANGE_VIEW')}
            >
              <img src={reArrangeIcon} alt="Reset view" style={{ width: "20px", height: "20px", transform: 'rotate(270deg)' }} />
            </button>
          </div>
        </div>

        {showTextOptionsModal && (
          <div className="text-options-modal position-absolute w-100 bg-white text-start" style={{
            top: inputPosition.y,
            left: inputPosition.x,
          }}>
            <div className='position-relative'>
              <textarea
                value={newTextValue}
                onChange={(e) => setNewTextValue(e.target.value)}
                rows={3}
                className='w-100'
                placeholder='Write your prompt'
              />
              <div className='d-flex aalign-items-center gap-2 text-options-wrapper'>
                <button className='text-options-btn font-noto-sans'>More fun</button>
                <button className='text-options-btn font-noto-sans'>More formal</button>
                <button className='text-options-btn font-noto-sans'>Shorten</button>
              </div>
            </div>
            <button className="d-flex align-items-center justify-content-center gap-2 px-4 border-0 black-gradiant-btn py-md-2 py-1 rounded-pill mt-2"
              onClick={handleRewrite} style={{ height: "auto" }}>
              <img src={aiIcon} alt="aiIcon" />
              <span>Rewrite</span>
            </button>
          </div>
        )}
        {/* right side menu */}
        {showSideModal && (
          <div style={{ zIndex: '50' }} className={`${showCommentSection ? "d-none" : ""}`}>
            {selectedId && selectedId.includes('text') && (
              <div className='border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-right-conatainer '>
                <div className='d-flex align-items-center justify-content-between pb-2 pt-3  bg-white position-sticky top-0'>
                  <h5 className='fs-16 font-noto-sans fw-bolder mb-0'>{t('textLayerName')}</h5>
                </div>
                <button
                  className='d-flex align-items-center justify-content-center gap-2 px-4 border-0 black-gradiant-btn py-2 rounded-pill my-3 w-100'
                >
                  <img src={aiIcon} alt='aiIcon' />
                  <span>{t('micoAIRewriter')}</span>
                </button>
                <h5 className='font-noto-sans fs-14 mt-4 fw-bolder'>{t('typography')}</h5>
                <div className='appearance-content border-bottom-0 pb-2'>
                  <div className='w-100 d-flex flex-column gap-2 mt-3'>
                    <select className="form-select" onChange={handleFontChange} value={fontFamily}>
                      {fonts.map((font) => (
                        <option key={font.family} value={font.family}>
                          {font.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='w-100 d-flex gap-3 align-items-center mt-3'>
                    <div className='w-100 d-flex flex-column gap-2'>
                      <select className="form-select" id="inputGroupSelect01">
                        <option selected>{t('regular')}</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className='position-relative'>
                      <input className='form-control px-2 text-center' placeholder='size' type='number' min='0' max='100' value={fontSize} onChange={handleFontSizeChange} />
                    </div>
                  </div>
                  <div className='d-flex justify-content-between gap-md-3 gap-2 mt-3'>
                    <div className='d-flex align-items-center gap-md-3 gap-2'>
                      <button
                        className={`bg-transparent border-0 p-0 ${textStyle.bold === 'bold' ? 'active' : ''}`}
                        onClick={(e) => textFontStyleClick(e, 'bold')}
                      >
                        <img src={boldIcon} alt='boldIcon' />
                      </button>
                      <button
                        className={`bg-transparent border-0 p-0 ${textStyle.italic === 'italic' ? 'active' : ''}`}
                        onClick={(e) => textFontStyleClick(e, 'italic')}
                      >
                        <img src={italicIcon} alt='italicIcon' />
                      </button>
                      <button
                        className={`bg-transparent border-0 p-0 ${textStyle.underline === 'underline' ? 'active' : ''}`}
                        onClick={(e) => textFontStyleClick(e, 'underline')}
                      >
                        <img src={underline} alt='underline' />
                      </button>
                    </div>

                    <div className="header_Bigline" style={{ width: "2px" }}></div>
                    <div className='d-flex align-items-center gap-md-3 gap-2'>
                      <button className={`bg-transparent border-0 p-0 ${textStyle.align === 'center' ? 'active' : ''}`}
                        onClick={(e) => { textFontStyleClick(e, 'align') }}>
                        <img src={alignManu} alt='alignManu' />
                      </button>
                      <button className='bg-transparent border-0 p-0'>
                        <img src={formatList} alt='formatList' />
                      </button>
                      <button className='bg-transparent border-0 p-0'>
                        <img src={formatLine} alt='formatLine' />
                      </button>
                    </div>
                  </div>

                </div>
                <h5 className='font-noto-sans fs-14 mt-3 fw-bolder'>{t('appearance')}</h5>
                <div className='appearance-content border-bottom-0 pb-2'>
                  <div className='d-flex align-items-center gap-md-3 gap-2'>
                    <div className="w-100 d-flex flex-column gap-2 mt-3">
                      <label for="formControlRange">{t('opacity')}</label>
                      <div className='d-flex gap-2 align-items-center'>
                        <input type="range" className="form-control-range w-100" style={{ height: "3px", accentColor: "black" }}
                          value={selectedSheetElement?.opacity || opacity}
                          onChange={handleImageOpacity}
                          min="0"
                          max="100"
                          step="1"
                          id="formControlRange" />
                        <div className='position-relative' style={{ maxWidth: "65px" }}>
                          <input
                            className='form-control px-4 text-center'
                            type='number'
                            min="0"
                            max="100"
                            step="1"
                            value={opacity}
                            onChange={handleImageOpacity}
                          />
                          <img src={percentage} alt='percentage' className='percentage' />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='w-100 d-flex flex-column gap-2 mt-3'>
                    <label className='fs-14 font-noto-sans ms-0'>{t('blendMode')}</label>
                    <select className="form-select" id="inputGroupSelect01" value={selectedBlendMode}
                      onChange={handleBlendModeChange}>
                      <option value="source-over">{t('normal')}</option>
                      {blendModes?.map((mode, index) => (
                        <option key={mode} value={mode}>{mode}</option>
                      ))}
                    </select>
                  </div>
                  <div className='w-100 d-flex flex-column gap-2 mt-3'>
                    <label className='fs-14 font-noto-sans ms-0'>{t('color')}</label>

                    <div className='d-flex gap-md-3 gap-2 align-items-center'>
                      <div className='position-relative'>
                        <input className='form-control px-2' placeholder='' value={color} type='text' onChange={handleColorChange} />
                      </div>
                      <div className='position-relative' style={{ maxWidth: "85px" }}>
                        <input className='form-control px-4 text-center' type='number' />
                        <img src={opacityImage} alt='opacity' className='opacity' />
                        <img src={percentage} alt='percentage' className='percentage' />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            )}

            {/* background Right Menu */}
            {selectedId && selectedId.includes('background') && (
              <div className='border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-right-conatainer z-n1'>
                <div className='d-flex align-items-center justify-content-between pb-2 pt-3  bg-white position-sticky top-0'>
                  <h5 className='fs-16 font-noto-sans fw-bolder mb-0'>Background:- {selectedSheetElement?.name}</h5>
                  {/* <button className='btn-black py-md-2 py-1 px-md-4 px-3 fw-600' onClick={handleSaveDetails}>Save</button> */}
                </div>

                <div className='replace-img-container w-100 position-relative d-flex align-items-center justify-content-center'>
                  <img className='image rounded-3' src={imageLink(selectedSheetElement, newDesign)} alt='newDesign' />
                  {!(selectedSheet.is_locked || selectedSheet.status_id === 2 || selectedSheet.status_id === 3) && <button className='replace-btn d-flex px-3 py-1 border-0 rounded-pill bg-white gap-2 d-flex justify-content-center align-items-center'
                    // data-bs-toggle="modal"
                    // data-bs-target="#add_design"
                    onClick={() => {
                      setSelectedSheetLayerId(selectedSheetElement.id)
                      setUploadPopupOpen(true)
                      // setShowResults(true); modalOpen(); setFormValue((prevFormValue) => ({
                      //   ...prevFormValue,
                      //   input_text: '',
                      // }));
                    }}
                    type="button">
                    {selectedSheetElement?.image_data?.data ? (
                      <>
                        <img src={refresh} alt='refresh' />
                        <span className="font-noto-sans">{t('replace')}</span>
                      </>
                    ) : (
                      <>
                        <img src={upload} alt='upload' />
                        <span className="font-noto-sans">{t('upload')}</span>
                      </>
                    )}


                  </button>}
                </div>

                <h5 className='font-noto-sans fs-14 mt-3 fw-bolder'>Select Color</h5>
                <div className='appearance-content'>
                  <ColorPicker
                    initialColor={selectedSheet?.backgroundColor
                      ? parseRgba(selectedSheet.backgroundColor)
                      : { r: 255, g: 255, b: 255, a: 1 }}
                    onColorChange={handleColorChange}
                  />
                </div>
                {/*Commented For sprint one */}
                {/* {selectedSheetElement?.elements?.[0]?.image_data?.data && (
                  <>
                    <h5 className='font-noto-sans fs-14 mt-3 fw-bolder'>{t('appearance')}</h5>
                    <div className='appearance-content'>
                      <div className='w-100 d-flex gap-md-3 gap-2 align-items-center mt-3'>
                        <div className='w-100 d-flex flex-column gap-2'>
                          <label className='fs-14 font-noto-sans ms-0'>{t('opacity')}</label>
                          <div className='position-relative'>
                            <input
                              className='form-control px-4 text-center'
                              type='number'
                              min="0"
                              max="100"
                              step="1"
                              value={opacity}
                              onChange={handleImageOpacity}
                            />
                            <img src={opacityImage} alt='opacity' className='opacity' />
                            <img src={percentage} alt='percentage' className='percentage' />
                          </div>
                        </div>
                        <div className='w-100 d-flex flex-column gap-2'>
                          <label className='fs-14 font-noto-sans ms-0'>{t('cornerRadius')}</label>
                          <div className='position-relative'>
                            <input
                              className='form-control px-4 text-center'
                              type='number'
                              min="0"
                              max="100"
                              step="1"
                              value={cornerRadiusValue}
                              onChange={handleCornerRadius}
                            />
                            <img src={roundedCorner} alt='roundedCorner' className='roundedCorner' width={20} />
                          </div>
                        </div>
                      </div>
                      <div className='w-100 d-flex flex-column gap-2 mt-3'>
                        <label className='fs-14 font-noto-sans ms-0'>{t('blendMode')}</label>
                        <select
                          className="form-select"
                          value={selectedBlendMode}
                          onChange={handleBlendModeChange}
                        >
                          <option value="source-over">{t('normal')}</option>
                          {blendModes?.map((mode) => (
                            <option key={mode} value={mode}>{mode}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <h5 className='font-noto-sans fs-14 mt-3 fw-bolder'>{t('layoutAndComposition')}</h5>
                    <div className='appearance-content border-bottom-0'>
                      <div className='w-100 d-flex flex-column gap-2 mt-3'>
                        <label className='fs-14 font-noto-sans ms-0'>{t('cropAndScale')}</label>
                        <select
                          className="form-select"
                          onChange={handleCropAndScale}
                          value={selectedElement?.cropStrategy || 'fit'}
                        >
                          <option value="fit">{t('fit')}</option>
                          <option value="fill">{t('fill')}</option>
                          <option value="stretch">{t('stretch')}</option>
                          <option value="center">{t('center')}</option>
                        </select>
                      </div>
                      <div className='d-flex align-items-center gap-4 mt-4'>
                        <button className='p-0 bg-transparent border-0' onClick={() => handleRotation('left')}>
                          <img src={revartIcon} alt='revartIcon' />
                        </button>
                        <button className='p-0 bg-transparent border-0' onClick={() => handleRotation('right')}>
                          <img src={notrevartIcon} alt='notrevartIcon' />
                        </button>
                        <button className='p-0 bg-transparent border-0' onClick={() => handleRotation('right')}>
                          <img src={roteteImage} alt='roteteImage' />
                        </button>
                        <button className='p-0 bg-transparent border-0' onClick={() => handleRotation('left')}>
                          <img src={leftroteteImage} alt='leftroteteImage' />
                        </button>
                      </div>
                    </div>
                  </>
                )} */}

              </div>
            )}


            {/* right side image menu */}
            {selectedId && selectedId.includes('image') && (
              <div className='border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-right-conatainer z-n1 '>
                <div className='d-flex align-items-center justify-content-between pb-2 pt-3  bg-white position-sticky top-0'>
                  <h5 className='fs-16 font-noto-sans fw-bolder mb-0'>{selectedSheetElement?.name}</h5>
                  {/* <button className='btn-black py-md-2 py-1 px-md-4 px-3 fw-600' onClick={handleSaveDetails}>Save</button> */}
                </div>

                <div className='replace-img-container w-100 position-relative d-flex align-items-center justify-content-center'>
                  <img className='image rounded-3' src={imageLink(selectedSheetElement, newDesign)} alt='newDesign' />
                  {!(selectedSheet.is_locked || selectedSheet.status_id === 2 || selectedSheet.status_id === 3) && < button className='replace-btn d-flex px-3 py-1 border-0 rounded-pill bg-white gap-2 d-flex justify-content-center align-items-center'
                    // data-bs-toggle="modal"
                    // data-bs-target="#add_design"
                    onClick={() => {
                      setSelectedSheetLayerId(selectedSheetElement.id)
                      setUploadPopupOpen(true)
                      // setShowResults(true); modalOpen(); setFormValue((prevFormValue) => ({
                      //   ...prevFormValue,
                      //   input_text: '',
                      // }));
                    }}
                    type="button">

                    <img src={refresh} alt='refresh' />
                    <span className="font-noto-sans">{t('replace')}</span>
                  </button>}
                </div>
                {/* <div class="accordion mt-3" id="accordionExample">
                  <div class="accordion-item border-0">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed p-0 font-noto-sans" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        {t('enhanceWithMicoAI')}
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body p-0">
                        <div className='position-relative'>
                          <Swiper
                            cssMode={true}
                            slidesPerView={3.5}
                            spaceBetween={15}
                            navigation={true}
                            pagination={true}
                            mousewheel={true}
                            keyboard={true}
                            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                            className="mySwiper mt-2"
                          >
                            <SwiperSlide>
                              <div className='swipper-container rounded-circle'
                                data-bs-toggle="modal"
                                data-bs-target="#magicBrush"
                                type="button"
                              >
                                <BootstrapTooltip title={t('bgRemoverDescription')}>
                                  <img src={newDesign} alt='newDesign' className='w-100 h-100 rounded-circle' />
                                </BootstrapTooltip>
                                <div className='swipper-btn'>
                                  <img src={aiIconBlack} alt='aiIconBlack' />
                                </div>
                              </div>
                              <span className='label font-noto-sans text-center'>{t('bgRemover')}</span>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className='swipper-container rounded-circle'>
                                <BootstrapTooltip title={t('smartEditDescription')}>
                                  <img src={newDesign} alt='newDesign' className='w-100 h-100 rounded-circle' />
                                </BootstrapTooltip>
                                <div className='swipper-btn'>
                                  <img src={aiIconBlack} alt='aiIconBlack' />
                                </div>
                              </div>
                              <span className='label font-noto-sans text-center'>{t('smartEdit')}</span>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className='swipper-container rounded-circle'>
                                <BootstrapTooltip title={t('magicBrushDescription')}>
                                  <img src={newDesign} alt='newDesign' className='w-100 h-100 rounded-circle' />
                                </BootstrapTooltip>
                                <div className='swipper-btn'>
                                  <img src={aiIconBlack} alt='aiIconBlack' />
                                </div>
                              </div>
                              <span className='label font-noto-sans text-center'>{t('magicBrush')}</span>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className='swipper-container rounded-circle'>
                                <BootstrapTooltip title={t('bgRemoverDescription')}>
                                  <img src={newDesign} alt='newDesign' className='w-100 h-100 rounded-circle' />
                                </BootstrapTooltip>
                                <div className='swipper-btn'>
                                  <img src={aiIconBlack} alt='aiIconBlack' />
                                </div>
                              </div>
                              <span className='label font-noto-sans text-center'>{t('bgRemover')}</span>
                            </SwiperSlide>
                          </Swiper>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <h5 className='font-noto-sans fs-14 mt-3 fw-bolder'>{t('appearance')}</h5>
                <div className='appearance-content'>
                  <div className='w-100 d-flex gap-md-3 gap-2 align-items-center mt-3'>
                    <div className='w-100 d-flex flex-column gap-2'>
                      <label className='fs-14 font-noto-sans ms-0'>{t('opacity')}</label>
                      <div className='position-relative'>
                        <input
                          className='form-control px-4 text-center'
                          type='number'
                          min="0"
                          max="100"
                          step="1"
                          value={opacity}
                          onChange={handleImageOpacity}
                        />
                        <img src={opacityImage} alt='opacity' className='opacity' />
                        <img src={percentage} alt='percentage' className='percentage' />
                      </div>
                    </div>
                    <div className='w-100 d-flex flex-column gap-2'>
                      <label className='fs-14 font-noto-sans ms-0'>{t('cornerRadius')}</label>
                      <div className='position-relative'>
                        <input
                          className='form-control px-4 text-center'
                          type='number'
                          min="0"
                          max="100"
                          step="1"
                          value={cornerRadiusValue}
                          onChange={handleCornerRadius}
                        />
                        <img src={roundedCorner} alt='roundedCorner' className='roundedCorner' width={20} />
                      </div>
                    </div>
                  </div>
                  <div className='w-100 d-flex flex-column gap-2 mt-3'>
                    <label className='fs-14 font-noto-sans ms-0'>{t('blendMode')}</label>
                    <select
                      className="form-select"
                      value={selectedBlendMode}
                      onChange={handleBlendModeChange}
                    >
                      <option value="source-over">{t('normal')}</option>
                      {blendModes?.map((mode) => (
                        <option key={mode} value={mode}>{mode}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <h5 className='font-noto-sans fs-14 mt-3 fw-bolder'>{t('layoutAndComposition')}</h5>
                <div className='appearance-content border-bottom-0'>
                  <div className='w-100 d-flex flex-column gap-2 mt-3'>
                    <label className='fs-14 font-noto-sans ms-0'>{t('cropAndScale')}</label>
                    <select
                      className="form-select"
                      onChange={handleCropAndScale}
                      value={selectedElement?.cropStrategy || 'fit'}
                    >
                      <option value="fit">{t('fit')}</option>
                      <option value="fill">{t('fill')}</option>
                      <option value="stretch">{t('stretch')}</option>
                      <option value="center">{t('center')}</option>
                    </select>
                  </div>
                  <div className='d-flex align-items-center gap-4 mt-4'>
                    <button className='p-0 bg-transparent border-0' onClick={() => handleRotation('left')}>
                      <img src={revartIcon} alt='revartIcon' />
                    </button>
                    <button className='p-0 bg-transparent border-0' onClick={() => handleRotation('right')}>
                      <img src={notrevartIcon} alt='notrevartIcon' />
                    </button>
                    <button className='p-0 bg-transparent border-0' onClick={() => handleRotation('right')}>
                      <img src={roteteImage} alt='roteteImage' />
                    </button>
                    <button className='p-0 bg-transparent border-0' onClick={() => handleRotation('left')}>
                      <img src={leftroteteImage} alt='leftroteteImage' />
                    </button>
                  </div>
                </div> */}
              </div>
            )}

            {selectedId && selectedId.includes('shape') && (
              <div className='border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-right-conatainer '>
                <div className='d-flex align-items-center justify-content-between pb-2 pt-3  bg-white position-sticky top-0'>
                  <h5 className='fs-16 font-noto-sans fw-bolder mb-0'>{t('imageLayerName')}</h5>
                </div>
                <div className='d-flex flex-column gap-md-2 gap-1 my-md-3 my-2 '>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="Solid_fill" id="solid" />
                    <label className="form-check-label" for="solid">
                      {t('solidFill')}
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="Solid_fill" id="gradient" />
                    <label className="form-check-label" for="gradient">
                      {t('gradientFill')}
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="Solid_fill" id="image" />
                    <label className="form-check-label" for="image">
                      {t('imageFill')}
                    </label>
                  </div>
                </div>
                <div className='appearance-content'>
                  <div className='replace-img-container w-100 position-relative d-flex align-items-center justify-content-center'>
                    <img src={imageLink(selectedSheetElement, newDesign)} alt='newDesign' className='image' />
                    <button className='replace-btn d-flex px-3 py-1 border-0 rounded-pill bg-white d-flex align-items-center justify-content-center'>
                      <span className="font-noto-sans">{t('select')}</span>
                    </button>
                  </div>
                </div>
                <h5 className='font-noto-sans fs-14 mt-3 fw-bolder'>{t('appearance')}</h5>
                <div className='appearance-content'>
                  <div className='w-100 d-flex gap-md-3 gap-2 align-items-center mt-3'>
                    <div className='w-100 d-flex flex-column gap-2'>
                      <label className='fs-14 font-noto-sans ms-0'>{t('opacity')}</label>
                      <div className='position-relative'>
                        <input
                          className='form-control px-4 text-center'
                          type='number'
                          min="0"
                          max="100"
                          step="1"
                          value={opacity}
                          onChange={handleImageOpacity}
                        />
                        <img src={opacityImage} alt='opacity' className='opacity' />
                        <img src={percentage} alt='percentage' className='percentage' />
                      </div>
                    </div>
                    <div className='w-100 d-flex flex-column gap-2'>
                      <label className='fs-14 font-noto-sans ms-0'>{t('cornerRadius')}</label>
                      <div className='position-relative'>
                        <input
                          className='form-control px-4 text-center'
                          type='number'
                          min="0"
                          max="100"
                          step="1"
                          value={cornerRadiusValue}
                          onChange={handleCornerRadius}
                        />
                        <img src={roundedCorner} alt='roundedCorner' className='roundedCorner' width={20} />
                      </div>
                    </div>
                  </div>
                  <div className='w-100 d-flex flex-column gap-2 mt-3'>
                    <label className='fs-14 font-noto-sans ms-0'>{t('blendMode')}</label>
                    <select
                      className="form-select"
                      value={selectedBlendMode}
                      onChange={handleBlendModeChange}
                    >
                      <option value="source-over">{t('normal')}</option>
                      {blendModes?.map((mode) => (
                        <option key={mode} value={mode}>{mode}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <h5 className='font-noto-sans fs-14 mt-3 fw-bolder'>{t('layoutAndComposition')}</h5>
                <div className='appearance-content border-bottom-0'>
                  <div className='w-100 d-flex flex-column gap-2 mt-3'>
                    <label className='fs-14 font-noto-sans ms-0'>{t('cropAndScale')}</label>
                    <select
                      className="form-select"
                      onChange={handleCropAndScale}
                      value={selectedElement?.cropStrategy || 'fit'}
                    >
                      <option value="fit">{t('fit')}</option>
                      <option value="fill">{t('fill')}</option>
                      <option value="stretch">{t('stretch')}</option>
                      <option value="center">{t('center')}</option>
                    </select>
                  </div>
                  <div className='d-flex align-items-center gap-4 mt-4'>
                    <button className='p-0 bg-transparent border-0' onClick={() => handleRotation('left')}>
                      <img src={revartIcon} alt='revartIcon' />
                    </button>
                    <button className='p-0 bg-transparent border-0' onClick={() => handleRotation('right')}>
                      <img src={notrevartIcon} alt='notrevartIcon' />
                    </button>
                    <button className='p-0 bg-transparent border-0' onClick={() => handleRotation('right')}>
                      <img src={roteteImage} alt='roteteImage' />
                    </button>
                    <button className='p-0 bg-transparent border-0' onClick={() => handleRotation('left')}>
                      <img src={leftroteteImage} alt='leftroteteImage' />
                    </button>
                  </div>
                </div>
              </div>
            )}
            <MagicBrush />
          </div>
        )}

        <div>

          {/* right click big popup */}

          {contextMenuVisible && (
            <div className='bg-white text-rightclick-popup position-absolute'
              style={{
                top: `${menuPosition.y}px`,
                left: `${menuPosition.x}px`,
              }}
              onClick={hideContextMenu}
            >
              <div className='popup-body d-flex gap-3 flex-column'>
                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                  <span>{t('bgRemover')}</span>
                </button>
                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                  <span>{t('smartEdit')}</span>
                </button>
                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                  <span>{t('magicBrush')}</span>
                </button>
                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                  <span>{t('infiniteExpand')}</span>
                </button>
                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                  <span>{t('upscaler')}</span>
                </button>
              </div>
              <div className='popup-body d-flex gap-3 flex-column'>

                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={refresh} alt="refresh" width={18} style={{ filter: "brightness(0)" }} />
                  <span>{t('replace')}</span>
                </button>
                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={cropIcon} alt="cropIcon" style={{ filter: "brightness(0)" }} />
                  <span>{t('crop')}</span>
                </button>
              </div>
              <div className='popup-body d-flex gap-3 flex-column'>
                <div className='d-flex align-items-center justify-content-between'>
                  <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                    <img src={copyIcon} alt="copyIcon" />
                    <span>{t('copy')}</span>
                  </button>
                  <p className='m-0'>cmd+C</p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                  <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                    <img src={pastIcon} alt="pastIcon" />
                    <span>{t('paste')}</span>
                  </button>
                  <p className='m-0'>cmd+V</p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                  <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                    <img src={duplicateIocn} alt="duplicateIocn" />
                    <span>{t('duplicate')}</span>
                  </button>
                  <p className='m-0'>cmd+D</p>
                </div>
              </div>
              <div className='popup-footer border-bottom-0'>
                <div className='d-flex align-items-center justify-content-between'>
                  <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                    <img src={deleteIocn} alt="deleteIocn" width={20} />
                    <span>{t('delete')}</span>
                  </button>
                  <p className='m-0'>del</p>
                </div>
              </div>
            </div>)}
        </div>
        {/* <AddImagePopUp typeChange={typeChange} /> */}

        {/* Replace Modal start*/}
        {/* <div className="modal fade" id="search_modal" tabIndex="-1" aria-labelledby="search_modalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-fullscreen modal-dialog-scrollable">
            <div className="modal-content px-3">
              <div className="modal-header border-0">
                <div className='upload_design'>
                  Browse
                </div>
                <div className='cursor_pointer' data-bs-dismiss="modal" onClick={() => { setShowImageDetails(false); setResults([]); }}>
                  <img src={Close} alt='Close' />
                </div>
              </div>
              <div className="modal-body">
                <BrowseSearch onSetImageUrl={handleSetImageUrl} showImageDetails={showImageDetails} setShowImageDetails={setShowImageDetails} />
              </div>
              {showImageDetails &&
                <div className="modal-footer border-0" disabled={loading}>
                  <button type="button" className="cancel_modal_footer" data-bs-dismiss="modal" disabled={loading} onClick={() => setShowImageDetails(false)}>{t("cancel")}  </button>
                  <button type="button" className="send_modal_footer" onClick={onHandleSelectedImage}>
                    {loading ? <CircularProgress color="inherit" size={20} /> : "Select"}
                  </button>
                </div>
              }
            </div>
          </div>
        </div> */}
        {/* Replace Modal end*/}
      </div>


      {/* <div className="modal fade" id="add_design" tabIndex="-1" aria-labelledby="add_designLabel" aria-hidden="true"> */}
      {/* <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content px-3">
            <div className="modal-header border-0">
              <div className='upload_design'>
                {t('uploadDesign')}
              </div>
              <div className='cursor_pointer' data-bs-dismiss="modal">
                <img src={Close} alt='Close' />
              </div>
            </div>
            <div className="modal-body">
              <UploadDesignFile
                instanceId="normal-upload"
                screen="visionStudio"
                layerId={layerId}
                isMultiSelect={true}
              />
            </div>
          </div>
        </div> */}
      {
        uploadPopUpOpen && <UploadComponent
          instanceId="normal-upload"
          screen="visionStudio"
          layerId={layerId}
          isMultiSelect={true}
          setPopupState={setUploadPopupOpen}
          getSheetDetails={getSheetDetails}
          getDesignDetails={getDesignDetails}
        />
      }
      {/* </div> */}



      {/* Selection Box */}
      {/* {!isSelecting && <div
        className='selection-box'
        style={{
          position: 'absolute',
          left: boxX,
          top: boxY,
          width: boxWidth,
          height: boxHeight,
          // backgroundColor: 'rgba(0, 0, 0, 0.1)', // Light transparent background
          cursor: 'pointer',
          border: '2px solid grey',
          borderRadius: '2px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        title='Select multiple sheets'
        onClick={() => setIsSelecting(true)}
      />} */}


    </div >
  );
};

export default ImageComponent;