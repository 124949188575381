import EmojiPicker from 'emoji-picker-react'
import React from 'react'

const EmojiField = ({
    handleEmojiClick,
    refVal,
    search = true,
    height = 300,
    width = 250,
    previewConfig = {}
}) => {
    return (
        <div
            className="emoji-container"
            onClick={e => e.stopPropagation()}
            ref={refVal}
        >
            <EmojiPicker width={width} height={height}
                onEmojiClick={handleEmojiClick}
                searchDisabled={search}
                previewConfig={previewConfig}
            />
        </div>
    )
}

export default EmojiField
