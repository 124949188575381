import React, { useContext, useEffect, useState } from "react";
import { imageGet, newImagePost, newPost_V3, newPut_V3 } from "../../API/axios";
import { useTranslation } from "react-i18next";
import { useReferenceContext } from "../../context/useReferenceContext";
import { useKonvaContext } from "../../context/useKonvaContext";
import ImageContext from "../../views/Manager/component/ImageContext";
import { showAlert } from "../../views/Manager/component/AlertService";
import { CircularProgress } from "@mui/material";
import { set } from "react-hook-form";
// import { CloudUpload, XCircle } from "react-bootstrap-icons";

const UploadComponent = ({
  uploadKeys = {},
  onFilesFetched = () => { },
  setPopupState = () => { },
  getSheetDetails = () => { },
  getDesignDetails = () => { },
  // instanceId,
  screen,
  // layerId,
  isMultiSelect,
}) => {
  const { t } = useTranslation()
  const [files, setFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [imageProgress, setImageProgress] = useState(false)
  const { designId } = useReferenceContext()
  const { selectedId, selectedSheetElement, selectedSheet, selectedSheetId, } = useKonvaContext();
  const { setImages, resetPopup } = useContext(ImageContext);
  const [isValidImage, setIsValidImage] = useState(false);

  const handleFileChange = (e) => {

    const selectedFiles = Array.from(e.target.files);
    const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const typeDiscripency = selectedFiles.find(file => !validTypes.includes(file.type))
    if (typeDiscripency) {
      // alert('Only PNG and JPG files are allowed');
      setIsValidImage(false);
      showAlert(t("IMAGE_UPLOAD_VALIDATION"), "error");
      return;
    }
    else {
      setIsValidImage(true);
    }
    addFiles(selectedFiles);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false); // Reset the highlight effect
    const droppedFiles = Array.from(e.dataTransfer.files);

    const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const typeDiscripency = droppedFiles.find(file => !validTypes.includes(file.type))
    if (typeDiscripency) {
      // alert('Only PNG and JPG files are allowed');
      showAlert(t("IMAGE_UPLOAD_VALIDATION"), "error");
      return;
    }


    addFiles(droppedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const addFiles = (newFiles) => {
    if (selectedId?.includes('background') || selectedId?.includes('image')) {
      if (files.length + newFiles.length > 1) {
        alert("You can only upload single file at once");
      } else {
        if (isValidImage) {
          setFiles((prev) => [...prev, ...newFiles]);
        }
      }
    } else if (newFiles.length + files.length <= 5) {
      setFiles((prev) => [...prev, ...newFiles]);
    } else {
      alert("You can only upload up to 5 files at once");
    }
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const uploadValues = async () => {
    if (screen == "visionBoard") {
      setImageProgress(true)
      const uploadPromises = files.map((file) => {
        const formData = new FormData();
        formData.append("files", file);

        for (let [key, value] of Object.entries(uploadKeys)) {
          formData.append(key, value)
        }
        return newImagePost(`file/upload`, formData);
      });
      try {
        const results = await Promise.allSettled(uploadPromises);

        const uploadedFiles = results
          .filter((res) => res.status === "fulfilled" && (res.value.status_code === 200 || res.value.status_code === 201))
          .map((res) => res.value.result[0]);

        if (onFilesFetched) {
          onFilesFetched(uploadedFiles);
        }

        setPopupState(false)
        setImageProgress(false);

      } catch (error) {
        console.error("Upload failed:", error);
        setImageProgress(false);
      }
    }
    else if (screen == "visionStudio") {
      setImageProgress(true)
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      formData.append("design_id", designId);
      const uploadedURLs = await newImagePost(`upload`, formData);
      if (uploadedURLs.status_code == 201 || uploadedURLs.status_code == 200) {
        let image_urls;
        if (!isMultiSelect || !selectedId?.includes('background')) {
          image_urls = uploadedURLs?.result.map(image => image.url);
        } else {
          image_urls = uploadedURLs?.result?.[0]?.url;
        }

        let data;
        if (selectedId?.includes('background')) {
          // replacing bg
          data = await newPut_V3(`layer/changeBackgroundLayerImage`, {
            layer_id: +selectedId?.replace('background_', ''),
            layer_type: "background",
            image_url: image_urls
          });
        } else if (selectedId?.includes('image')) {
          // replacing image
          let blob;
          try {
            const response = await fetch(image_urls[0]);
            if (!response.ok) throw new Error("Network response was not ok");
            blob = await response.blob();
          } catch (error) {
            console.error("Error fetching image:", error);
            blob = await imageGet("download_image", { image_url: image_urls[0] });
            if (!blob) {
              throw new Error("Failed to fetch or download the image.");
            }
          }
          const reader = new FileReader();
          reader.onloadend = async () => {
            console.log("FileReader onloadend triggered");
            let base64Image = reader.result;
            // Remove the prefix for any image type
            base64Image = base64Image.replace(/^data:image\/(jpeg|png|gif);base64,/, ''); // Adjusted regex to handle multiple formats

            try {

              const data = await newPut_V3(`layer/changeLayerValue`, {
                layer_id: selectedSheetElement.id,
                layer_type: selectedSheetElement.image_type,
                sheet_id: selectedSheetId,
                updates: {
                  url_path: image_urls[0],
                  image_data: base64Image
                }
              });

              if ([200, 201].includes(data?.status_code)) {
                await getSheetDetails(selectedSheetId);
                await getDesignDetails();
              }
            } catch (apiError) {
              console.error("Error during API call:", apiError);
            }
          };

          reader.onerror = (error) => {
            console.error("FileReader error:", error);
          };

          try {
            reader.readAsDataURL(blob);
          } catch (error) {
            console.error("Error reading the image file:", error);
          }
        } else {
          // Create sheet from Image
          data = await newPost_V3(`sheet/createSheetLayer`, { design_id: designId, layer_type: "image", image_urls });
        }

        if ([200, 201].includes(data?.status_code)) {
          await getDesignDetails();
        }
        setImageProgress(false);
        setPopupState(false);
        setImages([]);
        setFiles([]);
      }
    }

  }

  const preventVideoDragAndDrop = (event) => {
    const items = event.dataTransfer.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === 'file' && items[i].type.startsWith('video/')) {
        event.preventDefault();
        setIsValidImage(false);
        showAlert(t("IMAGE_UPLOAD_VALIDATION"), "error");
        return;
      }
      else {
        setIsValidImage(true);
      }
    }
  };

  const closeUploadPopup = () => {
    setFiles([])
    setImages([])
    setPopupState(false)
  }


  useEffect(() => {
    const dropArea = document.querySelector('.border_desh'); // Replace with your actual drop area selector

    dropArea.addEventListener('dragover', preventVideoDragAndDrop);
    dropArea.addEventListener('drop', preventVideoDragAndDrop);

    return () => {
      dropArea.removeEventListener('dragover', preventVideoDragAndDrop);
      dropArea.removeEventListener('drop', preventVideoDragAndDrop);
    };
  }, []);



  return (
    <div className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
      style={{
        zIndex: 51,
        background: "rgba(0,0,0,0.3)"
      }}
    >
      <div className="bg-white p-4 shadow-lg"
        style={{
          maxWidth: "600px",
          width: "50%",
          minWidth: "400px",
          borderRadius: "1rem",
        }}>
        <h4 className="fw-bold mb-3">{t("UPLOAD")}</h4>
        {!selectedId && <p className="text-muted">{t("IMAGE_UPLOAD_INSTRUCTION")}</p>}

        {/* Drag and Drop Box */}
        <div
          className={`border-black rounded text-center p-4 position-relative border_desh`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          style={{
            cursor: "pointer",
            transition: "background 0.3s, border-color 0.3s",
            ...(isDragging ? {
              background: "rgba(0,0,0,0.3)",
              border: "2px solid"
            } : {
              border: "2px dashed"
            })
          }}
        >
          {/* <CloudUpload size={50} className="text-primary" /> */}
          <p className="mt-2">{t("DRAG_TEXT")}</p>
          <p className="text-muted small">OR</p>
          <label htmlFor="fileInput" className="btn btn-outline-black" style={{
            background: "#000"
          }}>
            {t("BROWSE_FILES")}
          </label>
          <input
            id="fileInput"
            type="file"
            accept=".jpg, .png"
            multiple
            className="d-none"
            onChange={handleFileChange}
          />
        </div>

        <p className="text-muted small mt-2">{t("SUPPORTED_FILES")}</p>

        {(files.length > 0 && isValidImage) && (
          <div className="mt-3">
            {files.map((file, index) => (
              <div key={index} className="d-flex align-items-center justify-content-between border rounded p-2 mb-2">
                <div className="d-flex align-items-center">
                  <img src={URL.createObjectURL(file)} alt="preview" className="me-2 rounded" style={{ width: "40px", height: "40px", objectFit: "cover" }} />
                  <div>
                    <p className="mb-0 fw-bold">{file.name}</p>
                    <p className="mb-0 text-muted small">{(file.size / 1024).toFixed(2)} KB</p>
                  </div>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => removeFile(index)}
                >X</div>
                {/* <XCircle size={20} className="text-danger cursor-pointer" onClick={() => removeFile(index)} /> */}
              </div>
            ))}
          </div>
        )}

        <div className="d-flex justify-content-between mt-3">
          <button className="btn btn-secondary" onClick={closeUploadPopup} disabled={imageProgress} style={{
            background: "#000"
          }}>
            {t("cancel")}
          </button>
          <button
            className="btn"
            disabled={imageProgress || files.length === 0 || !isValidImage}
            onClick={uploadValues}
            style={{
              background: "#000"
            }}
          >
            {!imageProgress ? `${t("uploadFile")}` : ""}
            <React.Fragment>
              {imageProgress ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
            </React.Fragment>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadComponent;
