import React, { useState, useEffect } from 'react';
import fullScreenArrow from '../../../assets/newicons/full-screen-arrow.svg';
import commentIcon from '../../../assets/newicons/chat_bubble.svg';
import AssignmentIcon from '../../../assets/newicons/assignment.svg';
import sendArrowIcon from '../../../assets/newicons/send-arrow-icon.svg';
import ChateonlyImage from '../../../assets/newicons/chate_img.svg';
import { useTranslation } from 'react-i18next';
import CommentAndTask from './CommentAndTask';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useReferenceContext } from '../../../context/useReferenceContext';
import CommentAndTaskVisionStudio from './CommentsVisionStudio';

const ChateBoat = ({ setIsOpenChat, isOpenChat, ProjectId, createdProjectId, designDetails }) => {
    const { boardId, setBoardId, designId, setDesignId } = useReferenceContext();
    const [commentType, setCommentType] = useState("general");
    const [visionBoardId, setVisionBoardId] = useState(boardId);
    const [visionStudioId, setVisionStudioId] = useState(designId);
    const [commentStatus, setCommentStatus] = useState("1");
    const [childCommentStatus, setChildCommentStatus] = useState("1");
    // const [tab, setTab] = useState(1)
    const { t } = useTranslation();
    const handleCommentType = (e) => {
        setCommentType(e.target.value);
        // let type = e.target.value;
        // console.log('type: ', type)

        // if (type === "1") {
        //     console.log("All")
        // }
        // else if (type === "2") {
        //     // setBoardId(boardId);
        //     // setDesignId(0);
        // }
        // else if (type === "3") {
        //     // setBoardId(0);
        //     // setDesignId(designId);
        // }
    };

    const handleCommentStatus = (e) => {
        const newValue = e.target.value;
        setCommentStatus(newValue);
        setChildCommentStatus(newValue);
        // Add any additional logic needed when status changes
    };

    return (
        <div className='position-relative'>
            <div className="chatebox_open">
                <div className="">
                    <div className="d-flex justify-content-between align-items-center p-3">
                        <h6 className="modal-title m-0 fs-14 text-dark-black " id="exampleModalLabel">{t("projectSpaceChat")}</h6>
                        <div className='d-flex align-items-center gap-2'>
                            {/* <img src={fullScreenArrow} alt='fullScreenArrow' /> */}
                            <div type="button" className="btn-close" onClick={() => setIsOpenChat(false)}></div>
                        </div>
                    </div>
                    <div>
                        {/* <div className='d-flex align-items-center gap-md-4 gap-2 ps-3'>
                            <button className={`font-noto-sans d-flex align-items-center gap-2 fs-16 border-0 px-1 bg-transparent add_navbar ${tab === 1 && "active"}`} onClick={() => setTab(1)}>
                                <img src={commentIcon} alt='comment-Icon' className='image-brightness-05' />
                                Comment <span>(0)</span>
                            </button>
                            <button className={`font-noto-sans d-flex align-items-center gap-2 fs-16 border-0 px-1 bg-transparent add_navbar ${tab === 2 && "active"}`} onClick={() => setTab(2)}>
                                <img src={AssignmentIcon} alt='task-icon' className='image-brightness-05' />
                                Task  <span>(0)</span>
                            </button>
                        </div> */}
                        <div className='main_contaent'>
                            {/* <div className='chate_detail_full_des'>
                                <div className='d-flex align-items-start justify-content-end gap-2'>
                                    <div className='write_end'>
                                        <div className='chate_short_title'>You created a task and mentioned Hiroshi Tanaka in a comment</div>
                                        <div className='chate_detail_bg_right mt-1 mb-2'>
                                            <div className='chate_des_detail'>
                                                Change background color @Hiroshi Tanaka
                                            </div>
                                            <div className='my-2 d-flex flex-wrap justify-content-end gap-3'>
                                                <div className='chate_des_img'>
                                                    <div className='d-flex justify-content-center align-items-center h-100'>
                                                        <img src={ChateonlyImage} alt='ChateonlyImage' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='chate_reply'>{t("reply")}</div>
                                    </div>
                                    <div className='chatbox_img'></div>
                                </div>
                            </div>
                            <div className='chate_detail_full_des'>
                                <div className='d-flex align-items-start justify-content-start gap-2'>

                                    <div className='write_start'>
                                        <div className='chate_short_title'>Hiroshi Tanaka has submitted for approval</div>
                                        <div className='chate_detail_bg_left mt-1 mb-2'>
                                            <div className='chate_des_detail'>
                                                For your approval
                                            </div>
                                            <div className='my-2 d-flex flex-wrap justify-content-start gap-3'>
                                                <div className='chate_des_img'>
                                                    <div className='d-flex justify-content-center align-items-center h-100'>
                                                        <img src={ChateonlyImage} alt='ChateonlyImage' />
                                                    </div>
                                                </div>
                                                <div className='chate_des_img'>
                                                    <div className='d-flex justify-content-center align-items-center h-100'>
                                                        <img src={ChateonlyImage} alt='ChateonlyImage' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='chate_reply'>{t("reply")}</div>
                                    </div>
                                    <div className='chatbox_img'></div>
                                </div>
                            </div>
                            <div className='chate_detail_full_des'>
                                <div className='d-flex align-items-start justify-content-end gap-2'>
                                    <div className='text-start'>
                                        <div className='chate_short_title text-end'>{t("you")}</div>
                                        <div className='chate_detail_bg_right_reply'>
                                            Hey, saw you sent over the designs. Super quick work! I'll take a look and get back to you as soon as possible.
                                        </div>

                                    </div>
                                    <div className='chatbox_img'></div>
                                </div>
                            </div>
                            <div className='chate_detail_full_des'>
                                <div className='d-flex align-items-start justify-content-between gap-2'>
                                    <div className='text-start'>
                                        <div className='chate_short_title text-start'>Hiroshi Tanaka</div>
                                        <div className='chate_detail_bg_left_reply'>
                                            Cool, thanks
                                        </div>

                                    </div>

                                </div>
                            </div> */}

                            <div className="d-flex align-items-center gap-md-4 gap-2 ps-3 border-bottom">
                                <button
                                    className={`font-noto-sans d-flex align-items-center gap-2 fs-16 border-0 px-1 bg-transparent add_navbar active
                                        `}

                                >
                                    <img
                                        src={commentIcon}
                                        alt="comment-Icon"
                                        className="image-brightness-05"
                                    />
                                    {t("comment")}

                                    {/* {userSettings?.role_id === 1 ||
                                        userSettings?.role_id === 2 ||
                                        userSettings?.role_id === 3 ||
                                        userSettings?.role_id === 4 ||
                                        userSettings?.role_id === 5 ? (
                                        <span>
                                            {comments?.length > 0 ? filterData(comments)?.length : "0"}
                                        </span>
                                    ) : (
                                        <span>
                                            {comments?.length > 0
                                                ? commentCountForClient(filterData(comments))
                                                : "0"}
                                        </span>
                                    )} */}
                                </button>
                                {/* <button className={`font-noto-sans d-flex align-items-center gap-2 fs-16 border-0 px-1 bg-transparent add_navbar ${tab === 2 && "active"}`} onClick={() => setTab(2)}>
                            <img src={detailIcon} alt='task-icon' className='image-brightness-05' />
                            {t("details")}  <span>0</span>
                        </button> */}
                            </div>
                            <div style={{ padding: "10px" }}>
                                <FormControl>
                                    <div className="annotate-border comment-select rounded-pill select-design">
                                        <Select
                                            value={commentType}
                                            name="channel"
                                            onChange={handleCommentType}
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                            className="w-100 border-none text-black"
                                        >
                                            <MenuItem value={"general"}>{t("general")}</MenuItem>
                                            <MenuItem value={"board"}>{t("wireframe")}</MenuItem>
                                            <MenuItem value={"studio"}>{t("visionStudio")}</MenuItem>
                                        </Select>
                                    </div>
                                </FormControl>

                                <FormControl>
                                    <div className="annotate-border comment-select rounded-pill mx-3">
                                        <Select
                                            value={commentStatus}
                                            name="status"
                                            onChange={handleCommentStatus}
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                            className="w-100 border-none text-black"
                                        >
                                            <MenuItem value={"1"}>{t("All")}</MenuItem>
                                            <MenuItem value={"2"}>{t("resolvedOnly")}</MenuItem>
                                            <MenuItem value={"3"}>{t("unresolvedOnly")}</MenuItem>
                                        </Select>
                                    </div>
                                </FormControl>
                            </div>

                            {commentType === "board" && (
                                <CommentAndTask
                                    key={'comments'}
                                    createdProjectId={createdProjectId}
                                    ProjectId={ProjectId}
                                    designDetails={designDetails}
                                    screen='chat'
                                    comment_Type={commentType}
                                    chatBoatcommentStatus={commentStatus}
                                    handleComment_Status={handleCommentStatus}
                                    localCommentStatus={childCommentStatus}
                                />
                            )}

                            {commentType === "general" && (
                                <CommentAndTask
                                    key={'comments'}
                                    createdProjectId={createdProjectId}
                                    ProjectId={ProjectId}
                                    designDetails={designDetails}
                                    screen='chat'
                                    comment_Type={commentType}
                                    chatBoatcommentStatus={commentStatus}
                                    handleComment_Status={handleCommentStatus}
                                    localCommentStatus={childCommentStatus}
                                />
                            )}

                            {commentType === "studio" && (
                                <CommentAndTaskVisionStudio
                                    createdProjectId={createdProjectId}
                                    ProjectId={ProjectId}
                                    designDetails={designDetails}
                                    screen='chat'
                                    chatBoatcommentStatus={commentStatus}
                                    handleComment_Status={handleCommentStatus}
                                />
                            )}
                        </div>
                    </div>
                    {/* <div className="p-3 border-top">
                        <div className='d-flex justify-content-between gap-2 w-100'>
                            <input type='text' className='w-100 font-noto-sans placeholder-text-gray border-0 focus-none' placeholder={t("enterMessage")} />
                            <img src={sendArrowIcon} alt='sendArrowIcon' />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default ChateBoat;
