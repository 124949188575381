import React, { createContext, useContext, useEffect, useRef, useState } from "react";
const KonvaContext = createContext(undefined);
export const useKonvaContext = () => {
  return useContext(KonvaContext);
};

// export const useKonvaContext = () => {
//   const context = useContext(KonvaContext);
//   if (context === undefined) {
//     throw new Error("useKonvaContext must be used within a KonvaProvider");
//   }
//   return context;
// }


export const KonvaProvider = ({ children }) => {
  const stageRef = useRef(null);
  const transformerRef = useRef(null);
  const containerRef = useRef(null);
  const [showSideModal, setShowSideModal] = useState(false);
  const [opacity, setOpacity] = useState(100);
  const [cornerRadius, setCornerRadius] = useState(0);
  const [cornerRadiusValue, setCornerRadiusValue] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const [fontSize, setFontSize] = useState(14);
  const [fontFamily, setFontFamily] = useState("");
  const [color, setColor] = useState('000000');
  const [textStyle, setTextStyle] = useState({
    bold: 'normal',
    italic: 'normal',
    underline: 'none',
    align: ''
  });
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [selectedTextElement, setSelectedTextElement] = useState(null);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [editingTextId, setEditingTextId] = useState(null);
  const [newTextValue, setNewTextValue] = useState('');
  const [inputPosition, setInputPosition] = useState({ x: 0, y: 0 });
  const [showTextOptionsModal, setShowTextOptionsModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedLayerId, setSelectedLayerId] = useState(null);
  const [selectedSheetId, setSelectedSheetId] = useState(null);
  const [selectedSheetLayerId, setSelectedSheetLayerId] = useState(null);
  const [sheetCount, setSheetCount] = useState(0);
  const [isSelecting, setIsSelecting] = useState(false)
  const [selectedSheetIds, setSelectedSheetIds] = useState([])
  const [sheetListLoading, setSheetListLoading] = useState(false)
  const [fonts, setFonts] = useState([
    { family: 'Qwitcher Grypen', name: 'Qwitcher Grypen' },
    { family: 'Space Grotesk', name: 'Space Grotesk' },
    { family: 'Epilogue', name: 'Epilogue' },
    { family: 'Lexend', name: 'Lexend' },
    { family: 'Outfit', name: 'Outfit' },
    { family: 'Clash Display', name: 'Clash Display' },
    { family: 'Cabinet Grotesk', name: 'Cabinet Grotesk' },
    { family: 'GT Walsheim', name: 'GT Walsheim' },
    { family: 'Red Hat Display', name: 'Red Hat Display' },
    { family: 'Sora', name: 'Sora' },
    { family: 'Be Vietnam Pro', name: 'Be Vietnam Pro' },
    { family: 'General Sans', name: 'General Sans' },
    { family: 'Noto Sans Japanese', name: 'Noto Sans Japanese' }
  ]);
  const [blendModes, setBlendModes] = useState([
    'source-over', 'source-in', 'source-out', 'source-atop', 'destination-over',
    'destination-in', 'destination-out', 'destination-atop', 'lighter', 'copy',
    'xor', 'multiply', 'screen', 'overlay', 'darken', 'lighten', 'color-dodge',
    'color-burn', 'hard-light', 'soft-light', 'difference', 'exclusion', 'hue',
    'saturation', 'color', 'luminosity'
  ]);
  const [selectedBlendMode, setSelectedBlendMode] = useState('source-over');
  const [createSheetFromImage, setCreateSheetFromImage] = useState(false);
  const [selectedSheetElement, setSelectedSheetElement] = useState(null)
  const [selectedSheet, setSelectedSheet] = useState(null)
  const [myFilesData, setMyFilesData] = useState([])
  const [selectedImageId, setSelectedImageId] = useState(null)
  const [uploadLayerType, setUploadLayerType] = useState(null)
  const [showCommentSection, setShowCommentSection] = useState(false);


  return (
    <KonvaContext.Provider
      value={{
        showSideModal,
        setShowSideModal,
        fontSize,
        setFontSize,
        fontFamily,
        setFontFamily,
        color,
        setColor,
        menuPosition,
        setMenuPosition,
        selectedTextElement,
        setSelectedTextElement,
        contextMenuVisible,
        setContextMenuVisible,
        editingTextId,
        setEditingTextId,
        newTextValue,
        setNewTextValue,
        inputPosition,
        setInputPosition,
        showTextOptionsModal, setShowTextOptionsModal,
        selectedId, setSelectedId,
        fonts,
        setFonts,
        setSelectedImages,
        selectedImages,
        setSelectedLayerId,
        selectedLayerId,
        setTextStyle,
        textStyle,
        setCornerRadius,
        cornerRadius,
        setOpacity,
        opacity,
        cornerRadiusValue,
        setCornerRadiusValue,
        stageRef, transformerRef, containerRef,
        selectedSheetId, setSelectedSheetId,
        selectedSheetLayerId, setSelectedSheetLayerId,
        sheetCount, setSheetCount,
        blendModes, setBlendModes,
        selectedBlendMode, setSelectedBlendMode,
        createSheetFromImage, setCreateSheetFromImage,
        selectedSheetElement, setSelectedSheetElement,
        selectedSheet, setSelectedSheet,
        myFilesData, setMyFilesData,
        selectedImageId, setSelectedImageId,
        isSelecting, setIsSelecting,
        selectedSheetIds, setSelectedSheetIds,
        uploadLayerType,
        setUploadLayerType,
        sheetListLoading, setSheetListLoading,
        showCommentSection, setShowCommentSection
      }}
    >
      {children}
    </KonvaContext.Provider>
  );
};

