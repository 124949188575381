import React, { useEffect, useRef, useState } from "react";
import commentIcon from "../../../assets/newicons/chat_bubble.svg";
import publicIcon from "../../../assets/newicons/public-Icon.svg";
import detailIcon from "../../../assets/newicons/detailIcon.svg";
import info from "../../../assets/newicons/info.svg";
import chevronLeft from "../../../assets/icons/chevron-left-solid.svg";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Tooltip,
  Zoom,
} from "@mui/material";
import trueIcon from "../../../assets/newicons/tick-black-icon.svg";
import { Mention, MentionsInput } from "react-mentions";
import { useTranslation } from "react-i18next";
import defaultStyle from "./defaultStyle";
import "./commentAndTask.scss";
import { showAlert } from "./AlertService";
import { newDelete, newDelete_V3, newGet, newGet_V3, newPost, newPost_V3, newPut, newPut_V3, post } from "../../../API/axios";
import ellipsis_vertical from "../../../assets/newicons/ellipsis_vertical.svg";
import defaultStyleForReply from "./defaultStyleForReply";
import { useAuthContext } from "../../../context/useAuthContext";
import { useLocation } from "react-router-dom";
import { useSocketContext } from "../../../context/useSocketContext";
import { parseMentions } from "../../../helpers/parseMentions";
import { Brightness1 } from "@mui/icons-material";
import { useReferenceContext } from "../../../context/useReferenceContext";
import { set } from "react-hook-form";
import EmojiField from "../EmojiField";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CommentAndTask = ({
  activeImag,
  socketData,
  designDetails,
  channelId,
  createdProjectId,
  ProjectId,
  getReferences,
  referenceList,
  referenceComments,
  setReferenceComments,
  selectedImage,
  designVersion,
  design_id,
  screen,
  comment_Type,
  chatBoatcommentStatus,
  handleComment_Status,
  localCommentStatus,
  setError
}) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [tab, setTab] = useState(1);
  const [hoveredCommentId, setHoveredCommentId] = useState(null);
  const [referenceData, setReferenceData] = useState("");
  const [commentStatus, setCommentStatus] = useState("1");

  const [commentType, setCommentType] = useState("1");
  const [designId, setDesignId] = useState(0);
  const [replyValue, setReplyValue] = useState("");
  const [replyActions, setReplyActions] = useState();
  const { userSettings } = useAuthContext();
  const [openAlert, setOpenAlert] = useState(false);
  const [mentionsUsers, setMentionsUsers] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [watchListUser, setWatchListUser] = useState([]);
  const [newProjectUsers, setNewProjectUsers] = useState([]);

  const { onMessage } = useSocketContext();

  const [comments, setComments] = useState([]);
  const [commentWithoutAnnotate, setCommentWithoutAnnotate] = useState("");
  const [editComment, setEditComment] = useState("");
  const [editCommentData, setEditCommentData] = useState();
  const [replyData, setReplyData] = useState("");
  const [editReplyData, setEditReplyData] = useState("");
  const [newComment, setNewComment] = useState(false);
  const [editCommentFlag, setEditCommentFlag] = useState(false);
  const [newReply, setNewReply] = useState(false);
  const [editReply, setEditReply] = useState(false);
  const mentionInputRef = useRef(null);
  const AddCommentRef = useRef(null);
  const addReplyRef = useRef(null);
  const commentsEndRef = useRef(null);
  const commentsTopRef = useRef(null);
  const comment_Ref = useRef({});

  const [userList, setUserList] = useState([]);
  const [isReplyFieldOpen, setIsReplyFieldOpen] = useState(false);
  const [replyCommentId, setReplyCommentId] = useState();
  const [commentIdforReply, setCommentIdforReply] = useState();
  const [allUsers, setAllUsers] = useState([]);
  const [alertShown, setAlertShown] = useState(false);
  const { boardId, sheetId, setBoardId, referencesList } =
    useReferenceContext();
  const [replyDeleteAlert, setReplyDeleteAlert] = useState(null);
  const [commentDeleteAlert, setCommentDeleteAlert] = useState(null);
  const [isCommentPublic, setIsCommentPublic] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showEmojiPickerValues, setShowEmojiPickerValues] = useState({
    edit: false,
    reply: false,
    replyEdit: false
  })
  const [backgroundColor, setBackgroundColor] = useState('#edf2fa');
  const [projectData, setProjectData] = useState([]);

  const pickerRef = useRef(null);
  const editPickerRef = useRef(null)
  const replyPickerRef = useRef(null)
  const replyEditPickerRef = useRef(null)
  const query = useQuery();
  const commentId = query.get("comment_id");
  const project_id = query.get("project_id");

  // Handle clicks outside the picker
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
      if (editPickerRef.current && !editPickerRef.current.contains(event.target)) {
        setShowEmojiPickerValues(pre => ({ ...pre, edit: false }));
      }
      if (replyPickerRef.current && !replyPickerRef.current.contains(event.target)) {
        setShowEmojiPickerValues(pre => ({ ...pre, reply: false }));
      }
      if (replyEditPickerRef.current && !replyEditPickerRef.current.contains(event.target)) {
        setShowEmojiPickerValues(pre => ({ ...pre, replyEdit: false }));
      }
    };

    if (showEmojiPicker || Object.values(showEmojiPickerValues).find(item => item)) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker, showEmojiPickerValues]);

  const scrollToBottom = () => {
    commentsEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    commentsTopRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const siteId = localStorage?.getItem("siteId");

  useEffect(() => {
    if (onMessage) {
      try {
        const socketData = onMessage;
        console.info("Received message", socketData);
        console.info("socketData.data", socketData.data);
        if (socketData.data) {
          if (socketData.message == "comment_added") {
            if (comments?.length === 0 || comments === (undefined || null)) {
              setComments([socketData.data[0]]);
            } else {
              // let datacomments = JSON.parse(JSON.stringify(comments));
              let datacomments = comments
                ? JSON.parse(JSON.stringify(comments))
                : [];
              datacomments = [...datacomments, socketData.data[0]];
              setComments(datacomments);
            }
          } else if (socketData.message == "comment_updated") {
            if (socketData.data[0]?.comment_status === 1) {
              let datacomments = JSON.parse(JSON.stringify(comments));
              const editedDataIndex = datacomments.findIndex(
                (d) => d.comment_id === socketData.data[0]?.comment_id
              );
              if (editedDataIndex !== -1) {
                datacomments[editedDataIndex].comment_status =
                  socketData.data[0].comment_status;
              }
              setComments(datacomments);
            } else if (socketData.data[0]?.is_public === 1) {
              let datacomments = JSON.parse(JSON.stringify(comments));
              const editedDataIndex = datacomments.findIndex(
                (d) => d.comment_id === socketData.data[0]?.comment_id
              );
              if (editedDataIndex !== -1) {
                datacomments[editedDataIndex].is_public =
                  socketData.data[0].is_public;
                datacomments[editedDataIndex].comment_content =
                  socketData.data[0].comment_content;
              }
              setComments(datacomments);
            } else {
              let datacomments = JSON.parse(JSON.stringify(comments));
              const editedDataIndex = datacomments.findIndex(
                (d) => d.comment_id === socketData.data[0]?.comment_id
              );
              if (editedDataIndex !== -1) {
                datacomments[editedDataIndex].comment_content =
                  socketData.data[0].comment_content;
              }
              setComments(datacomments);
            }
          } else if (socketData.message == "comment_deleted") {
            let datacomments = JSON.parse(JSON.stringify(comments));
            const editedData = datacomments.filter(
              (d) => d.comment_id !== socketData.data[0]?.comment_id
            );
            setComments(editedData);
          } else if (socketData.message == "reply_added") {
            let datacomments = JSON.parse(JSON.stringify(comments));

            const editedData = datacomments.find(
              (d) => d.comment_id == socketData.data[0]?.comment_id
            );

            if (editedData) {
              if (!editedData.replies) {
                editedData.replies = [];
              }
              editedData.replies.push(socketData.data[0]);
            }
            setComments(datacomments);
          } else if (socketData.message == "reply_updated") {
            // let commentData = JSON.parse(JSON.stringify(comments));
            // const editData = commentData.find((c) => c.comment_id === replyCommentId);
            // let replyData = editData?.replies?.find((r) => r.reply_id === socketData.data[0]?.reply_id);
            // replyData.reply_content = socketData.data[0]?.reply_content;
            // setComments(commentData);
            let commentData = JSON.parse(JSON.stringify(comments));

            const editData = commentData.find(
              (c) => c.comment_id === commentIdforReply
            );
            if (editData) {
              let replyData = editData.replies?.find(
                (r) => r.reply_id === socketData.data[0]?.reply_id
              );
              if (replyData) {
                replyData.reply_content = socketData.data[0]?.reply_content;
              }
            }
            setComments(commentData);
          } else if (socketData.message == "reply_deleted") {
            let commentData = JSON.parse(JSON.stringify(comments));
            const editData = commentData.find(
              (c) => c.comment_id === socketData.data[0]?.comment_id
            );
            if (editData && editData.replies) {
              editData.replies = editData.replies.filter(
                (r) => r.reply_id !== socketData.data[0]?.reply_id
              );
            }
            setComments(commentData);
          } else if (socketData.message == "comment_status_updated") {
            let datacomments = JSON.parse(JSON.stringify(comments));
            const editedDataIndex = datacomments.findIndex(
              (d) => d.comment_id === socketData.data[0]?.comment_id
            );
            if (editedDataIndex !== -1) {
              datacomments[editedDataIndex].comment_status =
                socketData.data[0].comment_status;
            }
            setComments(datacomments);
          }
        }
      } catch (error) {
        console.error("Failed to parse WebSocket message", error);
      }
    }
  }, [onMessage]);

  // Add useEffect to sync with parent's status
  // useEffect(() => {
  //   if (localCommentStatus !== commentStatus) {
  //     setCommentStatus(localCommentStatus);
  //     handleCommentStatus({ target: { value: localCommentStatus } });
  //   }
  // }, [localCommentStatus]);

  const handleCommentStatus = (e) => {
    const newValue = e.target.value;
    setCommentStatus(newValue);

    // Call parent's handler if it exists
    if (handleComment_Status) {
      handleComment_Status(e);
    }
  };

  const handleDesignId = (e) => {
    setDesignId(e.target.value);
    // setWireframeId(0)
  };

  const handleCommentType = (e) => {
    setCommentType(e.target.value);
    let type = e.target.value;
    if (type === "1") {
      setBoardId(referencesList.wireframe_id);
      setDesignId(0);
    } else if (type === "2") {
      setBoardId(0);
      // setDesignId(0)
    } else {
      setBoardId(0);
      setDesignId(0);
    }
  };

  // const isValidURL = (text) => {
  //     const urlPattern = new RegExp(
  //         '((https?:\\/\\/)?' + // Protocol (http or https)
  //         '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // Domain name
  //         '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
  //         '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // Port and path
  //         '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // Query string
  //         '(\\#[-a-zA-Z\\d_]*)?)', 'i' // Fragment locator
  //     );
  //     return urlPattern.test(text);
  // };

  const parseTextWithUrlsAndMentions = (text, userList) => {
    // Ensure text is a string
    if (!text) return '';

    // First replace newlines with <br> tags
    text = text.replace(/\n/g, '<br />');

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = /@\[(.*?)\]\((.*?)\)|@(\w+)/g;

    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = mentionRegex.exec(text)) !== null) {
      const [fullMatch, displayName, userId, simpleMention] = match;
      const start = match.index;

      // Add text before the mention
      if (start > lastIndex) {
        const textBeforeMention = text.substring(lastIndex, start);
        const textParts = textBeforeMention.split(urlRegex);
        textParts.forEach((part, index) => {
          if (urlRegex.test(part)) {
            parts.push(
              `<a href="${part}" target="_blank" rel="noopener noreferrer">${part}</a>`
            );
          } else {
            parts.push(part);
          }
        });
      }

      // Add the mention
      if (userId) {
        const user = userList?.find((user) => user.id === userId);
        parts.push(
          `<span style="color: #3f51b5; cursor: pointer;" class="mention">@${user ? user.display : displayName}</span>`
        );
      } else if (simpleMention) {
        parts.push(`<span class="mention">@${simpleMention}</span>`);
      }

      lastIndex = start + fullMatch.length;
    }

    // Add remaining text after the last mention
    if (lastIndex < text.length) {
      const textAfterLastMention = text.substring(lastIndex);
      const textParts = textAfterLastMention.split(urlRegex);
      textParts.forEach((part, index) => {
        if (urlRegex.test(part)) {
          parts.push(
            `<a href="${part}" target="_blank" rel="noopener noreferrer">${part}</a>`
          );
        } else {
          parts.push(part);
        }
      });
    }

    return parts.join('');
  };

  const fetchUsers = async () => {
    if (userList.length === 0) {
      try {
        const data = await newGet(`project/usersByRole?role_name=channel&site_id=${siteId}`);
        const dataClient = await newGet(`project/usersByRole?role_name=client&site_id=${siteId}`);
        const allUsers = [...data?.result, ...dataClient?.result];
        setAllUsers(allUsers);

        const newList = allUsers?.map((user) => ({
          id: user?.account_login_id,
          display: user?.username,
          email: user.email,
        }));
        setUserList(newList || []);
      } catch (error) {
        console.error("error:???????", error);
      }
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    createdProjectId = queryParams.get('project_id');
    ProjectId = queryParams.get('project_id');
  }, [commentId])

  const fetchComments = async () => {
    try {
      // if (boardId) {
      if (commentId === null) {
        const isChatGeneral = screen === "chat" && comment_Type === "general" && commentId === null;
        const endpoint = isChatGeneral
          ? `comment/fetchComments?project_id=${ProjectId || createdProjectId}`
          : `comment/fetchCommentsByBoardId?board_id=${boardId}`;
        const apiCall = isChatGeneral ? newGet_V3 : newGet;
        const response = await apiCall(endpoint);

        const comments = response?.result?.map((r) => ({
          ...r,
          replies: r.replies.reverse(),
        })) || [];

        setComments(comments);
      }
      else if (commentId !== null) {
        if (boardId != 0) {
          const isChatGeneral = screen === "chat" && comment_Type === "general";
          const endpoint = isChatGeneral
            ? `comment/fetchComments?project_id=${project_id}`
            : `comment/fetchCommentsByBoardId?board_id=${boardId}`;

          const apiCall = isChatGeneral ? newGet_V3 : newGet;
          const response = await apiCall(endpoint);

          const comments = response?.result?.map((r) => ({
            ...r,
            replies: r.replies.reverse(),
          })) || [];

          setComments(comments);
        }
      }


      // }
      // else if (sheetId) {
      // let data = await newGet(
      //   `comment/fetchCommentsBySheetId?sheet_id=${sheetId}`
      // );
      // // let data = await newGet(`comment/fetchComments?project_id=${createdProjectId || ProjectId}`);
      // data = data?.result?.map((r) => ({
      //   ...r,
      //   replies: r.replies.reverse(),
      // }));

      // setComments(data);
      // }
    } catch (error) {
      console.error("error:???????", error);
    }
  };

  const onChange = (e, newValue, newPlainTextValue, mentions) => {
    setCommentWithoutAnnotate(e.target.value);
    setMentionsUsers(mentions);
  };

  const onChangeReply = (e, newValue, newPlainTextValue, mentions) => {
    setReplyValue(e.target.value);
    setMentionsUsers(mentions);
  };
  const onReplyEditValue = (e, newValue, newPlainTextValue, mentions) => {
    setReplyValue(e.target.value);
    setMentionsUsers(mentions);
  };

  const onEditCommentChange = (e, comment) => {
    setEditComment(e.target.value);
  };

  // const onReplyChange = (e, comment) => {
  //     let a = JSON.parse(JSON.stringify(referenceComments));

  //     a[0].comments = a[0].comments.map((c) => {
  //         if (JSON.stringify(c) == JSON.stringify(comment)) {
  //             c.replyText = e.target.value;
  //         } return c;
  //     });
  //     setReferenceComments(a);
  // }

  // const onAdd = (id) => {
  //     setMentionUserId((prevIds) => (prevIds ? `${prevIds}, ${id}` : id));
  // };

  const handleAddComment = async (id, comment, action, replyItem) => {
    // debugger
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true); // Set submitting state to true

    try {
      let allIdsPresent = mentionsUsers?.every((mention) =>
        projectUsers.some((item) => item.id === mention.id)
      );
      let newUsers = mentionsUsers.filter(
        (newItem) => !projectUsers.some((user) => user.id === newItem.id)
      );
      const newUsersList = newUsers.map((user) => user?.display).join(",");
      setNewProjectUsers(newUsersList);

      if (action === "new_comment") {
        if (mentionsUsers.length === 0 || allIdsPresent) {
          await addComment(); // Await the addComment function
        } else {
          setNewComment(true);
          setOpenAlert(true);
        }
      } else if (action === "edit_comment") {
        if (mentionsUsers.length === 0 || allIdsPresent) {
          setEditId(id);
          onSubmitEditComment(id, comment);
          setEditComment(comment?.content || comment?.comment_content);
          setEditCommentFlag(false);
          handleClose();
        } else {
          setEditCommentFlag(true);
          setOpenAlert(true);
        }
      } else if (action === "reply_comment") {
        if (mentionsUsers.length === 0 || allIdsPresent) {
          await onAddReply(id, comment); // Ensure replies are awaited
        } else {
          setNewReply(true);
          setReplyData(comment);
          setOpenAlert(true);
        }
      } else if (action === "edit_reply") {
        if (mentionsUsers.length === 0 || allIdsPresent) {
          await onSubmitEditReply(replyItem); // Await the reply edit
        } else {
          setEditReply(true);
          setOpenAlert(true);
        }
      }
    } catch (error) {
      console.error("Error in handleAddComment:", error);
    } finally {
      setIsSubmitting(false); // Always reset submitting state
    }
  };

  // const handleAddComment = async (id, comment, action, replyItem) => {
  //   let allIdsPresent = mentionsUsers?.every((mention) =>
  //     projectUsers.some((item) => item.id === mention.id)
  //   );
  //   let newUsers = mentionsUsers.filter(
  //     (newItem) => !projectUsers.some((user) => user.id === newItem.id)
  //   );
  //   const newUsersList = newUsers.map((user) => user?.display).join(",");
  //   setNewProjectUsers(newUsersList);
  //   if (action === "new_comment") {
  //     //  setLoading(true);
  //     if (mentionsUsers.length === 0 || allIdsPresent) {
  //       addComment();
  //     } else {
  //       setNewComment(true);
  //       setOpenAlert(true);
  //     }
  //   } else if (action === "edit_comment") {
  //     if (mentionsUsers.length === 0 || allIdsPresent) {
  //       setEditId(id);
  //       onSubmitEditComment(id, comment);
  //       setEditComment(comment.comment_content);
  //       setEditCommentFlag(false);
  //       handleClose();
  //     } else {
  //       setEditCommentFlag(true);
  //       setOpenAlert(true);
  //     }
  //   } else if (action === "reply_comment") {
  //     if (mentionsUsers.length === 0 || allIdsPresent) {
  //       onAddReply(id, comment);
  //     } else {
  //       setNewReply(true);
  //       setReplyData(comment);
  //       setOpenAlert(true);
  //     }
  //   } else if (action === "edit_reply") {
  //     if (mentionsUsers.length === 0 || allIdsPresent) {
  //       onSubmitEditReply(replyItem);
  //     } else {
  //       setEditReply(true);
  //       setOpenAlert(true);
  //     }
  //   }
  // };

  const formatMentionUsers = (text) => {
    let ids = [...text.matchAll(/\(([\da-fA-F-]{36})\)/g)].map(
      (match) => match[1]
    );
    let result = ids.length === 1 ? ids[0] : new Set(ids);
    return result;
  };

  const addComment = async () => {
    let data = {};
    const formattedContent = mentionsUsers.reduce((acc, user) => {
      const mentionTag = `@[${user.display}](${user.id})`;
      return acc.replace(`@${user.display}`, mentionTag);
    }, commentWithoutAnnotate);
    // const cleanContent = formattedContent.replace(/\n/g, "");

    // if (!cleanContent.trim()) {
    //   return;
    // }
    // if(screen!=="visionStudio"){
    data = {
      // project_id :project_id,
      content: formattedContent,
      mention_user_id: mentionsUsers.map((user) => user?.id).join(","),
      comment_status: false,
      is_public:
        userSettings?.role_id == 4 ||
          userSettings?.role_id == 6 ||
          isCommentPublic
          ? true
          : false,
      // board_id: boardId,
      feature: (screen === "chat" && comment_Type === 'general') ? "general" : "board",
      ...((screen === "chat" && comment_Type === 'general')
        ? { project_id: ProjectId || createdProjectId }
        : { board_id: boardId }),
    };
    // }
    // else{
    //   data ={
    //     // project_id : project_id,
    //     content: cleanContent,
    //     mention_user_id: mentionsUsers.map((user) => user?.id).join(","),
    //     comment_status: false,
    //     is_public:
    //       userSettings?.role_id == 4 ||
    //       userSettings?.role_id == 6 ||
    //       isCommentPublic
    //         ? true
    //         : false,
    //     sheet_id : sheetId,
    //     feature:  "sheet",
    //   }
    // }

    try {
      const apiCall = (screen === "chat" && comment_Type === 'general') ? newPost_V3 : newPost;
      const response = await apiCall(`comment/createComment`, data);


      // await newPost(`comment/createComment`, data).then((response) => {
      if (response &&
        (response.status_code == 200 || response.status_code == 201)
      ) {
        setCommentWithoutAnnotate("");
        // fetchComments()
        setMentionsUsers([]);
        setNewComment(false);
        setTimeout(() => {
          scrollToTop();
        }, 300);
        setIsCommentPublic(false);
      }
      // });
    } catch (e) {
      console.error("error: ", e);
    }
  };

  const updateWatchlist = async () => {
    let newUsers = mentionsUsers.filter(
      (newItem) => !projectUsers.some((user) => user.id === newItem.id)
    );
    let watchlistUsers = [...(watchListUser ?? []), ...(newUsers ?? [])];

    const formData = {
      project_id: Number(ProjectId || createdProjectId),
      site_id: siteId,
      watchlist: watchlistUsers?.map((user) => user?.id).join(","),
    };
    try {
      const data = await newPut_V3(`project`, formData);
      if (data.status_code == 200) {
        // showAlert("watchlist updated successfully", "success")
        // showAlert(i18n.t(data?.message[0]?.property_message), "success");
        showAlert("User has been added in Watchlist", "success");
        if (newComment) {
          addComment();
        } else if (editCommentFlag) {
          onSubmitEditComment(editCommentData.comment_id, editCommentData);
        } else if (newReply) {
          onAddReply(replyData.comment_id, replyData);
        } else if (editReply) {
          onSubmitEditReply(editReplyData);
        }
        getProjectDetail();
      }
    } catch (error) {
      console.error("error:", error);
    }
  };

  const getProjectDetail = async () => {
    if ((ProjectId || createdProjectId) && siteId) {
      try {
        const data = await newGet_V3(
          `project/getProjectDetails?project_id=${ProjectId || createdProjectId}&site_id=${siteId}`
        );
        const assigneeList = makeUserArray(
          data?.result[0]?.assign_to_user_name
        );
        const internalApproverList = makeUserArray(
          data?.result[0]?.internal_approver_username
        );
        const clientApproverList = makeUserArray(
          data?.result[0]?.approver_username
        );
        const watchListUsers = makeUserArray(data?.result[0]?.watchlist);
        setWatchListUser(watchListUsers);
        const projectUsers = [
          ...(assigneeList ?? []),
          ...(internalApproverList ?? []),
          ...(clientApproverList ?? []),
          ...(watchListUsers ?? []),
        ];
        setProjectUsers(projectUsers);
        setProjectData(data?.result);
      } catch (error) {
        console.error("error:???????", error);
      }
    }
  };

  function makeUserArray(input) {
    if (input) {
      const objectsArray = input.split("},").map((item) => {
        item = item.trim().endsWith("}") ? item : item + "}";

        const idMatch = item.match(/id:([a-f0-9\-]+)/);
        const usernameMatch = item.match(/username:([^,}]+)/);

        return {
          id: idMatch ? idMatch[1] : null,
          username: usernameMatch ? usernameMatch[1].trim() : null,
        };
      });
      return objectsArray;
    } else {
      return;
    }
  }

  const onHandleReplyInputField = (id) => {
    setReplyCommentId(id);
    setIsReplyFieldOpen(true);
    setMentionsUsers([]);
    setEditCommentData();
    setReplyActions(null);
  };

  const extractMentionsFromComment = (comment) => {
    const mentionRegex = /\@\[(.*?)\]\((.*?)\)/g;
    let matches;
    const mentionedUsers = [];

    while ((matches = mentionRegex.exec(comment)) !== null) {
      mentionedUsers.push({ id: matches[2], display: matches[1] });
    }

    return mentionedUsers;
  };

  const onUpadateComment = async (id, comment) => {
    setCommentWithoutAnnotate("");
    setReplyCommentId(id);
    setIsReplyFieldOpen(false);
    const mentionedUsers = extractMentionsFromComment(comment.comment_content);
    setMentionsUsers(mentionedUsers);
    setReplyActions();
    setEditComment(comment?.comment_content);
    setEditCommentData(comment);
    setAnchorEl(null);

    setTimeout(() => {
      if (mentionInputRef.current) {
        mentionInputRef.current.focus();
      }
    }, 0);
  };

  const onSubmitEditComment = async (id, comment) => {
    let data = {};
    const formattedContent = mentionsUsers.reduce((acc, user) => {
      const mentionTag = `@[${user.display}](${user.id})`;
      return acc.replace(`@${user.display}`, mentionTag);
    }, editComment);
    // const cleanContent = formattedContent.replace(/\n/g, "");

    if (!formattedContent.trim()) {
      return;
    }

    // if(screen==="visionStudio"){
    //   data = {
    //     sheet_id: sheetId,
    //     project_id: ProjectId,
    //     comment_id: id,
    //     content: cleanContent,
    //     comment_status: false,
    //     feature:  "sheet",
    //     mention_user_id: mentionsUsers.map((user) => user?.id).join(","),
    //   }}
    // else {
    if (screen === "chat" && comment_Type === 'general') {
      data = {
        project_id: ProjectId,
        comment_id: id,
        content: formattedContent,
        comment_status: false,
        feature: "general",
        mention_user_id: mentionsUsers.map((user) => user?.id).join(","),
      };
    } else {
      data = {
        board_id: boardId,
        project_id: ProjectId,
        comment_id: id,
        content: formattedContent,
        comment_status: false,
        feature: "board",
        mention_user_id: mentionsUsers.map((user) => user?.id).join(","),
      };
    }

    // }
    try {
      const apiCall = (screen === "chat" && comment_Type === 'general') ? newPut_V3 : newPut;
      const response = await apiCall(`comment/updateComment`, data);
      // await newPut(`comment/updateComment`, data).then((response) => {
      if (response?.status_code == 200) {
        const commentData = JSON.parse(JSON.stringify(comments));
        const editData = commentData.find(
          (c) => c.comment_id === comment.comment_id
        );
        editData.content = formattedContent;
        // setComments(JSON.parse(JSON.stringify(commentData)));
        setComments(JSON.parse(JSON.stringify(commentData)));
        setReplyActions(null);
        setMentionsUsers([]);
        setEditCommentFlag(true);
        setEditComment("");
        fetchComments();
      }
      // });
    } catch (error) {
      console.error("error:", error);
    }
  };

  const onDeleteComment = async (id, comment) => {
    setCommentDeleteAlert(id);
    setReplyDeleteAlert(null);
    setAnchorEl(null);
    setMenuOpenCommentId(null);
    // try {
    //     await newDelete(`comment/deleteComment?comment_id=${id}`);
    //     handleClose()
    // }
    // catch (error) {
    //     console.log(error)
    // }
  };

  const handleDeleteComment = async (id, feature) => {
    try {
      const isChatGeneral = screen === "chat" && comment_Type === "general";
      const endpoint = `comment/deleteComment?comment_id=${id}&feature=${isChatGeneral ? "general" : boardId ? "board" : "sheet"}`;
      const apiCall = isChatGeneral ? newDelete_V3 : newDelete;

      await apiCall(endpoint);

      setComments((prevItems) => prevItems.filter((item) => item.comment_id !== id));
      handleClose();
      setCommentDeleteAlert(null);

    } catch (error) {
      console.error(error);
    }
  };

  const handleCommentLink = (id) => {
    if (comment_Ref.current) {
      comment_Ref.current.innerText = ""; // ✅ Clears the text inside the div
    }
    showAlert("Link copied", "success");
    const fullUrl = window.location.origin + location.pathname + location.search;
    navigator.clipboard.writeText(`${fullUrl}&comment_id=${id}`);
    handleClose()
    //  console.log("Comment ref", comment_Ref.current) ;
  }


  useEffect(() => {
    if (commentId === null) {
      return;
    }
    if (comments.length > 0) {
      const isCommentPresent = comments.some((comment) => comment?.comment_id == commentId);
      if (!isCommentPresent && !alertShown && screen !== "chat") {
        if (typeof setError === 'function') {
          setError(true);
        }
        showAlert("Invalid link", "error");
        setAlertShown(true);
        return;
      }
    }


    if (projectData?.length > 0 || boardId !== 0) {
      if (commentId && comment_Ref.current) {
        const timer1 = setTimeout(() => {
          const element = comment_Ref.current[commentId];
          element?.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 500);

        const timer2 = setInterval(() => {
          setBackgroundColor((prevColor) => (prevColor === '#d7e2f4' ? '#edf2fa' : '#d7e2f4'));
        }, 300);

        const stopBlinking = setTimeout(() => {
          clearInterval(timer2);
          setBackgroundColor('#edf2fa'); // Reset to the original color
        }, 3000);


        return () => { clearTimeout(timer1); clearInterval(timer2); clearTimeout(stopBlinking) };
      }
    }

  }, [commentId, projectData, comments]);

  const onResolveComment = async (id, comment) => {
    handleClose();
    let data = {};
    try {
      if (screen === "chat" && comment_Type === 'general') {
        if (id) {
          await newPut_V3(`comment/updateCommentStatus`, {
            comment_id: id,
            comment_status: comment.comment_status === "1" ? true : false,
            feature: "general",
          });
        }
        data = {
          comment_id: id,
          reply_content: "Marked as resolved",
          comment_status: comment.comment_status === "1" ? false : true,
          mention_user_id: "",
          feature: "general",
        };
      }
      else {
        if (id) {
          await newPut(`comment/updateCommentStatus`, {
            comment_id: id,
            comment_status: comment.comment_status === "1" ? true : false,
            feature: boardId ? "board" : "sheet",
          });
        }

        data = {
          comment_id: id,
          reply_content: "Marked as resolved",
          comment_status: comment.comment_status === "1" ? false : true,
          mention_user_id: "",
          feature: boardId ? "board" : "sheet",
        };
      }

      try {
        const apiCall = (screen === "chat" && comment_Type === 'general') ? newPost_V3 : newPost;
        const response = await apiCall(`reply/create`, data);
        // await newPost(`reply/create`, data).then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          //   setCommentStatus(false);
          // }
          // console.log("xbgtt", JSON.stringify(response?.comment_status));
          // setComments((prevItems) =>
          //     prevItems.filter((item) => item.comment_id !== id)
          //   );
          fetchComments();
        }
        // });
      } catch (error) {
        console.log(error);
      }
      handleClose();

    } catch (error) {
      console.error(error);
    }
  };

  const onReopenComment = async (id, comment) => {
    handleClose();
    let data = {};
    try {
      if (screen === "chat" && comment_Type === 'general') {
        if (id) {
          await newPut_V3(`comment/updateCommentStatus`, {
            comment_id: id,
            comment_status: comment.comment_status === "1" ? false : true,
            feature: "general",
          });
        }
        data = {
          comment_id: id,
          reply_content: "Re-opened",
          // task_flag: false,
          comment_status: comment.comment_status === "1" ? true : false,
          mention_user_id: "",
          feature: "general",
        };
      }
      else {
        if (id) {
          await newPut(`comment/updateCommentStatus`, {
            comment_id: id,
            comment_status: comment.comment_status === "1" ? false : true,
            feature: boardId ? "board" : "sheet",
          });
        }

        data = {
          comment_id: id,
          reply_content: "Re-opened",
          // task_flag: false,
          comment_status: comment.comment_status === "1" ? true : false,
          mention_user_id: "",
          feature: boardId ? "board" : "sheet",
        };
      }

      try {
        const apiCall = (screen === "chat" && comment_Type === 'general') ? newPost_V3 : newPost;
        const response = await apiCall(`reply/create`, data);
        // await newPost(`reply/create`, data).then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          //   setCommentStatus(false);
          // }
          // console.log("xbgtt", JSON.stringify(response?.comment_status));
          // setComments((prevItems) =>
          //     prevItems.filter((item) => item.comment_id !== id)
          //   );
          fetchComments();
        }
        // });
      } catch (error) {
        console.log(error);
      }
      handleClose();
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const onMakeCommentPublic = async (id, comment) => {
    let data = {}
    try {
      if (screen === "chat" && comment_Type === 'general') {
        data = {
          project_id: ProjectId,
          comment_id: id,
          content: comment.comment_content,
          comment_status: false,
          feature: "general",
          is_public: true,
          mention_user_id: mentionsUsers.map((user) => user?.id).join(","),
        };
      }
      else {
        data = {
          board_id: boardId,
          project_id: ProjectId,
          comment_id: id,
          content: comment.comment_content,
          comment_status: false,
          feature: "board",
          is_public: true,
          mention_user_id: mentionsUsers.map((user) => user?.id).join(","),
        };
      }

      // }

      if (id) {
        const apiCall = (screen === "chat" && comment_Type === 'general') ? newPut_V3 : newPut;
        const response = await apiCall(`comment/updateComment`, data);
        // await newPut(`comment/updateComment`, data);
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const makePublicComment = () => {
    setIsCommentPublic(!isCommentPublic);
  };

  // const fetchReply = async (data) => {
  //     try {
  //         if (data.length > 0) {
  //             let replyDataArray = []

  //             for (const element of data) {
  //                 try {
  //                     const response = await newGet(`reply/getReply?comment_id=${element}`);
  //                     if (response?.status_code === '200') {
  //                         let replyResponse = {
  //                             "comment_id": element,
  //                             "replyData": response.result
  //                         }

  //                         replyDataArray.push(replyResponse)
  //                     }
  //                     setReplyData(replyDataArray)
  //                 } catch (error) {
  //                     console.error("Error fetching reply data:", error);
  //                 }
  //             }
  //         }
  //     }
  //     catch (error) {
  //         console.log(error)
  //     }
  // }

  const onAddReply = async (id, comment, reOpen) => {
    handleClose();
    let data = {};
    const formattedContent = mentionsUsers.reduce((acc, user) => {
      const mentionTag = `@[${user.display}](${user.id})`;
      return acc.replace(`@${user.display}`, mentionTag);
    }, replyValue);
    // const cleanContent = formattedContent.replace(/\n/g, "");
    try {
      if (!formattedContent.trim() && !reOpen) {
        return;
      }
      if (screen === "chat" && comment_Type === 'general') {
        if (id) {
          await newPut_V3(`comment/updateCommentStatus`, {
            comment_id: id,
            comment_status: comment.comment_status === "1" ? false : true,
            feature: "general",
          });
        }

        data = {
          comment_id: id,
          reply_content: reOpen ? reOpen : formattedContent,
          // task_flag: false,
          mention_user_id: mentionsUsers?.map((user) => user?.id).join(","),
          feature: "general",
          // ...(comment.comment_status && { comment_status: false })
          comment_status: comment?.comment_status === "1" ? true : false,
        };
      }
      else {
        if (id) {
          await newPut(`comment/updateCommentStatus`, {
            comment_id: id,
            comment_status: comment.comment_status === "1" ? false : true,
            feature: boardId ? "board" : "sheet",
          });
        }

        data = {
          comment_id: id,
          reply_content: reOpen ? reOpen : formattedContent,
          // task_flag: false,
          mention_user_id: mentionsUsers?.map((user) => user?.id).join(","),
          feature: boardId ? "board" : "sheet",
          // ...(comment.comment_status && { comment_status: false })
          comment_status: comment?.comment_status === "1" ? true : false,
        };
      }


      const apiCall = (screen === "chat" && comment_Type === 'general') ? newPost_V3 : newPost;
      const response = await apiCall(`reply/create`, data);

      // await newPost(`reply/create`, data).then((response) => {
      // console.log(response);
      if (
        response &&
        (response.status_code == 200 || response.status_code == 201)
      ) {
        setReplyValue("");
        setIsReplyFieldOpen(false);
        setNewReply(false);
        fetchComments();
        //   setIsCommentPublic(!isCommentPublic)
        //   setCommentStatus(true);
        //   console.log("xbgtt2: ", JSON.stringify(response?.comment_status));
        //   setCommentStatus()
      }
      // });
      // if (comment?.comment_status === "1") {
      //   console.log(
      //     "comment_status: ",
      //     comment?.comment_status === 1 ? true : false
      //   );
      //   await newPut(`comment/updateCommentStatus`, {
      //     comments: [
      //       {
      //         comment_id: id,
      //         comment_status: comment?.comment_status === "1" ? false : true,
      //         feature: boardId ? "board" : "sheet",
      //       },
      //     ],
      //   });
      // }
    } catch (error) {
      console.error("error:", error);
    }
  };


  const onUpdateReply = async (reply, commentId) => {
    setReplyCommentId(reply.reply_id);
    setCommentIdforReply(commentId);
    setCommentWithoutAnnotate("");
    const mentionedUsers = extractMentionsFromComment(reply.reply_content);
    setMentionsUsers(mentionedUsers);
    setReplyValue(reply.reply_content);
    setEditReplyData(reply);
    setAnchorElReply(null);
    setIsReplyFieldOpen(true);
  };

  const onSubmitEditReply = async (result) => {
    let data = {};
    const formattedContent = mentionsUsers.reduce((acc, user) => {
      const mentionTag = `@[${user.display}](${user.id})`;
      return acc.replace(`@${user.display}`, mentionTag);
    }, replyValue);
    // const cleanContent = formattedContent.replace(/\n/g, "");

    if (!formattedContent.trim()) {
      return;
    }

    if (screen === "chat" && comment_Type === 'general') {
      data = {
        reply_id: result?.reply_id,
        reply_content: formattedContent,
        // task_flag: false,
        feature: "general",
        mention_user_id: mentionsUsers.map((user) => user?.id).join(","),
      };
    }
    else {
      data = {
        reply_id: result?.reply_id,
        reply_content: formattedContent,
        // task_flag: false,
        feature: boardId ? "board" : "sheet",
        mention_user_id: mentionsUsers.map((user) => user?.id).join(","),
      };
    }


    try {
      const apiCall = (screen === "chat" && comment_Type === 'general') ? newPut_V3 : newPut;
      const response = await apiCall(`reply/update`, data);
      // await newPut(`reply/update`, data).then((response) => {
      if (response.status_code == 200) {
        // const commentData = JSON.parse(JSON.stringify(comments));
        // const editData = commentData.find((c) => c.comment_id === comment.comment_id);
        // let replyData = editData?.replies?.find((r) => r.reply_id === result.reply_id);
        // replyData.reply_content = formattedContent;
        // setComments(commentData);
        fetchComments();
        setEditReply(false);
        setIsReplyFieldOpen(false);
      }
      // });
    } catch (error) {
      console.error("error:", error);
    }
  };

  const onDeleteReply = async (id) => {
    setReplyDeleteAlert(id);
    setCommentDeleteAlert(null);
    setAnchorElReply(null);
    setMenuOpenReplyId(null);
    // try {
    //     await newDelete(`reply/delete?reply_id=${id}`).then((response) => {
    //         onCloseReplyMenu();
    //     })
    // }
    // catch (error) {
    //     console.log(error)
    // }
  };

  const handleReplyDelete = async (id) => {

    try {

      const isChatGeneral = screen === "chat" && comment_Type === "general";
      const endpoint = `reply/delete?reply_id=${id}&feature=${isChatGeneral ? "general" : boardId ? "board" : "sheet"}`;
      const apiCall = isChatGeneral ? newDelete_V3 : newDelete;
      // await newDelete(
      //   `reply/delete?reply_id=${id}&feature=${boardId ? "board" : "sheet"}`
      // );
      await apiCall(endpoint);

      setComments((prevItems) => {
        const updatedComments = prevItems
          .map((comment) => {
            if (comment.replies) {
              const updatedReplies = comment.replies.filter(
                (reply) => reply.reply_id !== id
              );
              return { ...comment, replies: updatedReplies }; // Ensure a new object is returned
            }
            return comment;
          })
          .filter(
            (comment) =>
              comment.comment_id !== id ||
              (comment.replies && comment.replies.length > 0)
          );

        return updatedComments;
      });

      handleClose();
      setCommentDeleteAlert(null);
    } catch (error) {
      console.error("Error Deleting Reply:", error);
    }
    // console.log("reply ID",id)
    // try {
    //     await newDelete(`reply/delete?reply_id=${id}&feature=${boardId?"board" : "sheet"}`).then((response) => {
    //         console.log('response: ', response)
    //         onCloseReplyMenu();
    //         setReplyDeleteAlert(null);
    //     })
    // }
    // catch (error) {
    //     console.log(error)
    // }
  };

  const filterData = (data) => {
    let dd = JSON.parse(JSON.stringify(data));

    if (boardId && comment_Type !== "general") {
      dd = data.filter((d) => boardId === d.board_id);
    } else if (design_id || designId) {
      dd = data.filter((d) => d?.design_id == (design_id || designId));
    }
    else if (screen === "chat" && comment_Type === "general" && commentId === null) {
      dd = data.filter((d) => d?.project_id === (ProjectId || createdProjectId));
    }
    else if (screen === "chat" && comment_Type === "general" && commentId !== null) {
      dd = data.filter((d) => d?.project_id == (project_id));
    }
    else if (commentType === "2") {
      dd = data.filter((d) => null == d.board_id);
    }
    // Use both local and parent status 
    const statusToUse = chatBoatcommentStatus || commentStatus;

    switch (statusToUse) {
      case "2":
        return dd.filter((d) => d.comment_status === 0);
      case "3":
        return dd.filter((d) => d.comment_status === 1);
      case "1":
        return dd;
      default:
        return dd;

    }

  };

  const [menuOpenCommentId, setMenuOpenCommentId] = useState(null);
  const [menuOpenReplyId, setMenuOpenReplyId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElReply, setAnchorElReply] = useState(null);
  const handleClick = (event, comment_id) => {
    setAnchorEl(event.currentTarget);
    setMenuOpenCommentId(comment_id);
  };

  const onHandleReplyMenu = (event, reply_id) => {
    setAnchorElReply(event.currentTarget);
    setMenuOpenReplyId(reply_id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpenCommentId(null);
  };

  const onCloseReplyMenu = () => {
    setAnchorElReply(null);
    setMenuOpenReplyId(null);
  };

  useEffect(() => {
    fetchUsers();
    getProjectDetail();
    fetchFileterData();
  }, [selectedImage]);

  const fetchFileterData = () => {
    if (selectedImage) {
      const refernceData = referenceList.filter(
        (reference) => reference.file_url == selectedImage
      );
      setReferenceData(refernceData);
    }
  };

  const handleRejection = () => {
    setOpenAlert(false);
  };

  const handleCloseDialog = () => {
    // setOpenAlert(false);
    // setMentionUserId()
  };

  const handleConfirmation = () => {
    setOpenAlert(false);
    updateWatchlist();
  };

  const getCommentedUser = (id) => {
    const user = allUsers?.find((user) => user.account_login_id === id);
    return user ? user.username : "";
  };

  function formatCommentDate(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const pad = (num) => num.toString().padStart(2, "0");
    const time = `${pad(date.getHours())}:${pad(date.getMinutes())}`;

    const isToday = date.toDateString() === now.toDateString();

    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const isYesterday = date.toDateString() === yesterday.toDateString();

    if (isToday) {
      return `${time} today`;
    } else if (isYesterday) {
      return `${time} yesterday`;
    } else {
      const day = pad(date.getDate());
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      return `${time} ${day} ${month} ${year}`;
    }
  }

  const formatCommentDate2 = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
    });
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedTime} ${formattedDate}`;
  };
  //   useEffect(() => {
  //     return () => {
  //         // Reset comments state when unmounting the component
  //         setComments([]);
  //     };
  // }, []);

  useEffect(() => {
    fetchComments();
    setTimeout(() => {
      fetchComments();
    }, 1000);
  }, [boardId, sheetId]);

  useEffect(() => {
    fetchComments();
  }, [comment_Type])

  // const getReplyData = (data) => return <p></p>
  const handleMouseEnter = (id) => {
    setHoveredCommentId(id);
  };

  const handleMouseLeave = () => {
    setHoveredCommentId(null);
  };
  const handleKeyDown = (e, id, comment, type, replyItem) => {
    if (e.key === "Enter") {
      if (e?.target?.value?.trim() == "") {
        return;
      }
      if (e.shiftKey) {
        // Shift + Enter: Insert a new line
        e.preventDefault();

        const cursorPosition = e.target.selectionStart;
        let updatedText;

        if (type === "new_comment") {
          updatedText = insertAtCursor(
            commentWithoutAnnotate,
            "\n",
            cursorPosition
          );
          setCommentWithoutAnnotate(updatedText);
        } else if (type === "edit_comment") {
          updatedText = insertAtCursor(editComment, "\n", cursorPosition);
          setEditComment(updatedText);
        } else if (type === "reply_comment" || type === "edit_reply") {
          updatedText = insertAtCursor(replyValue, "\n", cursorPosition);
          setReplyValue(updatedText);
        }

        // Maintain the cursor position after the state update
        setTimeout(() => {
          e.target.focus();
          e.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
        }, 0);
      } else {
        // Enter: Submit the comment
        e.preventDefault();

        if (type === "new_comment") {
          handleAddComment("", "", type);
        } else if (type === "edit_comment") {
          handleAddComment(id, comment, type);
        } else if (type === "reply_comment") {
          handleAddComment(id, comment, type);
        } else if (type === "edit_reply") {
          handleAddComment(id, comment, type, replyItem);
        }
      }
    }
  };

  const insertAtCursor = (text, insertText, position) => {
    // Check if the cursor is at the end of the text
    const isCursorAtEnd = position === text.length;

    // If the cursor is at the end, append the insertText
    if (isCursorAtEnd) {
      return text + insertText;
    }

    // Adjust the cursor position if it falls within any mentions
    let adjustedPosition = position;
    let currentIndex = text.indexOf("@[", 0);

    // Iterate through all occurrences of mentions
    while (currentIndex !== -1) {
      // If the cursor is within the mention range, adjust it to after the mention
      if (
        adjustedPosition > currentIndex &&
        adjustedPosition <= currentIndex + 300
      ) {
        adjustedPosition += currentIndex + 300;
      }
      // Find the next occurrence of a mention
      currentIndex = text.indexOf("@[", currentIndex + 1);
    }

    // Insert the text at the adjusted position
    return (
      text.slice(0, adjustedPosition) +
      insertText +
      text.slice(adjustedPosition)
    );
  };

  const onClickOfReadMore = (comment) => {
    // comment.isSlice = !comment.isSlice;
    let datacomments = JSON.parse(JSON.stringify(comments)); // Make a deep copy
    const editedDataIndex = datacomments.findIndex(
      (d) => d.comment_id === comment?.comment_id
    );
    if (editedDataIndex !== -1) {
      // Toggle the isSlice state to show more or less content
      datacomments[editedDataIndex].isSlice =
        !datacomments[editedDataIndex].isSlice;
    }
    setComments(datacomments); // Update the state with the new list
  };

  const commentCountForClient = (comments) => {
    const count = comments.filter((comment) => comment.is_public);
    return count?.length;
  };


  const fetchreplyDate = () => {
    let ss = "";
    comments?.map((item) => {
      {
        item?.replies.map((item) => {
          // {}
          ss = formatCommentDate2(item?.reply_updated_timestamp);
          return formatCommentDate2(item?.reply_updated_timestamp);
        });
      }
    });
    return ss;
  };

  // Add this check function at the top of the component
  const isCommentOwner = (autherData) => {
    return userSettings?.account_login_id === autherData?.author_user_id || userSettings?.account_login_id === autherData?.[0]?.author_user_id || autherData?.reply_author_user_id;
  };

  const handleEmojiClick = (emojiObject, ref, value = "", valueSetter, mentionsMarkup = "@[__display__](__id__)") => {
    if (!ref || !valueSetter) return;

    const emoji = emojiObject.emoji;
    const input = ref.current;
    if (!input) {
      valueSetter((prev) => prev + emoji);
      return;
    }

    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;

    // Convert cursor position in visible text to the corresponding position in raw value
    let displayText = getDisplayText(value, mentionsMarkup);
    let adjustedStartPos = mapCursorPosition(startPos, value, displayText);
    let adjustedEndPos = mapCursorPosition(endPos, value, displayText);

    // Insert emoji at the correct position
    const newText =
      value.substring(0, adjustedStartPos) +
      emoji +
      value.substring(adjustedEndPos);

    valueSetter(newText);

    // Move cursor after inserted emoji
    setTimeout(() => {
      input.selectionStart = input.selectionEnd = adjustedStartPos + emoji.length;
      input.focus();
    }, 0);
  };
  const getDisplayText = (rawValue, mentionsMarkup) => {
    return rawValue.replace(/@\[(.*?)\]\((.*?)\)/g, "$1"); // Extracts only the display names
  };
  const mapCursorPosition = (cursorPos, rawValue, displayText) => {
    let rawIndex = 0, displayIndex = 0;

    while (rawIndex < rawValue.length && displayIndex < cursorPos) {
      if (rawValue[rawIndex] === "@" && rawValue.substring(rawIndex).match(/^\@\[(.*?)\]\((.*?)\)/)) {
        const mentionMatch = rawValue.substring(rawIndex).match(/^\@\[(.*?)\]\((.*?)\)/);
        const mentionText = mentionMatch[1]; // Extract mention name
        rawIndex += mentionMatch[0].length; // Move raw index past mention
        displayIndex += mentionText.length; // Move displayed index
      } else {
        rawIndex++;
        displayIndex++;
      }
    }

    return rawIndex;
  };

  return (
    <div
      className="pb-2 h-100 d-flex flex-column justify-content-between w-100"
      style={{}}
    >
      <div className="comment-content">
        <div ref={commentsTopRef} />
        <div className="">
          <Dialog
            open={openAlert}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="mention-alert-dialog"
          >
            <DialogTitle
              id="alert-dialog-title"
              className="font-noto-sans"
            >
              {t("MENTION_ALERT_TITLE")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className="font-noto-sans"
              >
                <span className="text-primary">{newProjectUsers}</span> {t("MENTION_ALERT_MSG")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleRejection}
                className="rounded-pill reject-button"
              >
                No
              </Button>
              <Button
                onClick={handleConfirmation}
                autoFocus
                className="background-black text-white confirm-button"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {screen !== "chat" &&
          <div
            className="bg-color-gray position-sticky top-0 pt-2 pb-1 chatbox-space"
            style={{ zIndex: "2" }}
          >
            <div className="d-flex align-items-center gap-md-4 gap-2 ps-3 border-bottom">
              <button
                className={`font-noto-sans d-flex align-items-center gap-2 fs-16 border-0 px-1 bg-transparent add_navbar ${tab === 1 && "active"
                  }`}
                onClick={() => setTab(1)}
              >
                <img
                  src={commentIcon}
                  alt="comment-Icon"
                  className="image-brightness-05"
                />
                {t("comment")}

                {userSettings?.role_id === 1 ||
                  userSettings?.role_id === 2 ||
                  userSettings?.role_id === 3 ||
                  userSettings?.role_id === 4 ||
                  userSettings?.role_id === 5 ? (
                  <span>
                    {comments?.length > 0 ? filterData(comments)?.length : "0"}
                  </span>
                ) : (
                  <span>
                    {comments?.length > 0
                      ? commentCountForClient(filterData(comments))
                      : "0"}
                  </span>
                )}
              </button>
              {/* <button className={`font-noto-sans d-flex align-items-center gap-2 fs-16 border-0 px-1 bg-transparent add_navbar ${tab === 2 && "active"}`} onClick={() => setTab(2)}>
                            <img src={detailIcon} alt='task-icon' className='image-brightness-05' />
                            {t("details")}  <span>0</span>
                        </button> */}
            </div>
            {tab === 1 && (
              <div className="d-flex  mt-3 ms-3 ">
                <FormControl>
                  <div className="annotate-border comment-select rounded-pill " >
                    <Select
                      value={commentStatus}
                      name="channel"
                      onChange={handleCommentStatus}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="w-100 border-none text-black"
                    >
                      <MenuItem value={"1"}>{t("All")}</MenuItem>
                      <MenuItem value={"2"}>{t("resolvedOnly")}</MenuItem>
                      <MenuItem value={"3"}>{t("unresolvedOnly")}</MenuItem>
                    </Select>
                  </div>
                </FormControl>
                {/* <FormControl >
                                <div className='annotate-border comment-select rounded-pill'>
                                    <Select

                                        name="channel"

                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        className="w-100 border-none text-black"
                                    >
                                        <MenuItem selected>{t("thisFile")}</MenuItem>
                                        <MenuItem >{t("allfiles")}</MenuItem>
                                    </Select>
                                </div>
                            </FormControl> */}
                {/* <FormControl>
                <div className="annotate-border comment-select rounded-pill select-design">
                  <Select
                    value={commentType}
                    name="channel"
                    onChange={handleCommentType}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="w-100 border-none text-black"
                  >
                    <MenuItem value={"1"}>{t("All")}</MenuItem>
                    <MenuItem value={"2"}>{t("wireframe")}</MenuItem>
                    <MenuItem value={"3"}>Vision Studio</MenuItem>
                  </Select>
                </div>
              </FormControl> */}
                <FormControl>
                  {/* <div className="annotate-border comment-select rounded-pill select-design">
                  <Select
                    value={designId}
                    name="channel"
                    onChange={handleDesignId}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="w-100 border-none text-black"
                    disabled={commentType === "1"}
                  >
                    <MenuItem value={0}>
                      <em>None</em>
                    </MenuItem>
                    {designDetails?.length > 0 &&
                      designDetails?.map((design) => (
                        <MenuItem value={design?.design_id} key={design?.design_id}>
                          <img
                            src={design?.image_url}
                            alt="design"
                            height={35}
                            width={35}
                          ></img>
                        </MenuItem>
                      ))}
                  </Select>
                </div> */}
                  {/* <FormHelperText>Design</FormHelperText> */}
                </FormControl>
              </div>
            )}
          </div>
        }

        {/* add comment */}
        {comments?.length > 0 ? (
          <div className="pb-4 position-relative">
            {tab === 1 && (
              <div className="">
                {comments &&
                  comments?.length > 0 &&
                  filterData(comments)
                    .sort(
                      (a, b) =>
                        new Date(b.updated_timestamp) -
                        new Date(a.updated_timestamp)
                    )
                    .map((comment, i) => {
                      let publicComment = comment?.is_public;
                      let currentUser = userList.find(
                        (user) => user.id === comment?.updated_by
                      );

                      return (
                        userSettings?.role_id === 1 ||
                        userSettings?.role_id === 2 ||
                        userSettings?.role_id === 3 ||
                        userSettings?.role_id === 4 ||
                        userSettings?.role_id === 5 ||
                        ((userSettings?.role_id == 4 ||
                          userSettings?.role_id === 6) &&
                          publicComment))
                        ? (
                          <div className="position-relative pt-1" key={i} id={`${comment?.comment_id}`} ref={(el) => (comment_Ref.current[comment?.comment_id] = el)}>
                            <div
                              className="mt-2 py-3 px-xxl-4 px-2 chat-padding mx-2"
                              style={{
                                backgroundColor: comment?.comment_id == commentId ? backgroundColor : "#edf2fa",
                                borderRadius: "10px",
                              }}
                            >
                              <div className="d-flex gap-2 align-items-start justify-content-between chat-align">
                                <div
                                  className="d-flex gap-2 align-items-center cursor-pointer"
                                  onClick={() =>
                                    onHandleReplyInputField(comment?.comment_id)
                                  }
                                >
                                  <div className="icon-box-width font-noto-sans  fs-16 text-white d-flex justify-content-center align-items-center position-relative background-black rounded-circle">
                                    <div className="d-flex align-items-center">
                                      {/* <p className="mb-0">
                                                                <span>{userSettings?.username?.slice(0, 2)?.toUpperCase()}</span>
                                                            </p> */}
                                      <p className="mb-0">
                                        {/* <span>{getCommentedUser(comment?.updated_by)?.charAt(0)?.toUpperCase()}</span> */}
                                        <span>
                                          {comment?.author_details?.author_user_name
                                            ?.charAt(0)
                                            ?.toUpperCase() ||
                                            comment?.author_details[0]?.author_user_name
                                              ?.charAt(0)
                                              ?.toUpperCase()}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="d-flex  flex-column gap-0 mt-2">
                                      <Tooltip
                                        // key={`${id}-${start}`}
                                        title={
                                          <div>
                                            <strong>
                                              {currentUser?.display}
                                            </strong>
                                            <br />
                                            Email: {currentUser?.email}
                                          </div>
                                        }
                                        arrow
                                        placement="top"
                                        PopperProps={{
                                          style: { zIndex: 3000 }, // Ensures tooltip appears above other elements
                                        }}
                                      >
                                        <h6 className="font-noto-sans fs-14 text-black mb-0 fw-bold">
                                          {/* {getCommentedUser(comment?.updated_by)} */}
                                          {comment?.author_details
                                            ?.author_user_name ||
                                            comment?.author_details[0]
                                              ?.author_user_name}
                                        </h6>
                                      </Tooltip>

                                      <p className="font-noto-sans text-dark-gray fs-12 mb-0">
                                        {comment?.created_timestamp &&
                                          formatCommentDate2(
                                            comment?.created_timestamp
                                          )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                  {/* {comment.comment &&
                                                        <button className='border-0 bg-transparent'>
                                                            <img src={commenticon} alt='comment-Icon' />
                                                        </button>
                                                    } */}

                                  {comment?.is_public &&
                                    (userSettings?.role_id !== 4 ||
                                      userSettings?.role_id !== 6) ? (
                                    <button className="border-0 bg-transparent p-0">
                                      <img src={publicIcon} alt="publicIcon" />
                                    </button>
                                  ) : null}

                                  {comment?.comment_status !== 0 && (
                                    <Tooltip
                                      title="Mark as resolved"
                                      slotProps={{
                                        popper: {
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -8],
                                              },
                                            },
                                          ],
                                        },
                                      }}
                                    >
                                      <button
                                        className="border-0 bg-transparent p-0"
                                        onClick={() =>
                                          onResolveComment(
                                            comment.comment_id,
                                            comment
                                          )
                                        }
                                      >
                                        <img src={trueIcon} alt="trueIcon" />
                                      </button>
                                    </Tooltip>
                                  )}

                                  {/* {((userSettings?.account_login_id === comment?.author_details?.author_user_id) || (userSettings?.account_login_id === comment?.author_details[0]?.author_user_id)) && */}

                                  <div className="comment-options">
                                    {isCommentOwner(comment?.author_details) && (
                                      <Button
                                        id="basic-button"
                                        aria-controls={menuOpenCommentId === comment.comment_id ? "basic-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={menuOpenCommentId === comment.comment_id ? "true" : undefined}
                                        onClick={(e) => handleClick(e, comment.comment_id)}
                                        style={{}}
                                        className="border-0"
                                      >
                                        <img src={ellipsis_vertical} alt="menu" />
                                      </Button>
                                    )}

                                    <Menu
                                      id="basic-menu"
                                      anchorEl={
                                        menuOpenCommentId === comment.comment_id
                                          ? anchorEl
                                          : null
                                      }
                                      open={
                                        menuOpenCommentId ===
                                        comment.comment_id && Boolean(anchorEl)
                                      }
                                      onClose={handleClose}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      {isCommentOwner(comment?.author_details) ? (
                                        <>
                                          <MenuItem
                                            onClick={() =>
                                              handleAddComment(
                                                comment.comment_id,
                                                comment,
                                                "edit_comment"
                                              )
                                            }
                                          >
                                            {t("edit")}
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              onDeleteComment(
                                                comment.comment_id,
                                                comment
                                              )
                                            }
                                          >
                                            {t("delete")}
                                          </MenuItem>
                                          <MenuItem onClick={() => handleCommentLink(comment?.comment_id)}>{t("commentLink")}</MenuItem>
                                        </>
                                      ) : null}
                                      {(userSettings?.role_id === 1 ||
                                        userSettings?.role_id === 2 ||
                                        userSettings?.role_id === 3) &&
                                        !comment?.comment_status && (
                                          <MenuItem
                                            onClick={() =>
                                              onMakeCommentPublic(
                                                comment.comment_id,
                                                comment
                                              )
                                            }
                                            disabled={comment?.is_public}
                                          >
                                            {t("makePublic")}
                                          </MenuItem>
                                        )}
                                      {comment?.comment_status === 0 ? (
                                        <MenuItem
                                          onClick={
                                            () =>
                                              onReopenComment(
                                                comment?.comment_id,
                                                comment
                                              )
                                            // onAddReply(
                                            //   comment.comment_id,
                                            //   comment,
                                            //   "Re-opened"
                                            // )
                                          }
                                        >
                                          {t("reOpen")}
                                        </MenuItem>
                                      ) : (
                                        <MenuItem
                                          onClick={() =>
                                            onResolveComment(
                                              comment.comment_id,
                                              comment
                                            )
                                          }
                                        >
                                          {t("resolve")}
                                        </MenuItem>
                                      )}
                                    </Menu>
                                  </div>
                                  {/* } */}
                                </div>
                              </div>
                              <div
                                style={{ position: "relative" }}
                                onMouseEnter={() =>
                                  handleMouseEnter(comment.comment_id)
                                }
                                onMouseLeave={handleMouseLeave}
                              >
                                {/* Display the parsed comment */}
                                {/* {isValidURL(comment?.content || comment?.comment_content) ?
                                                                (<a href={comment?.content || comment?.comment_content} target="_blank" rel="noopener noreferrer" style={{ overflowWrap: 'anywhere' }}>{comment?.content || comment?.comment_content}</a>)
                                                                : ( */}
                                {/* <p className="text-black fs-14 font-noto-sans mb-0 fw-normal mt-1 text-break">
                                {parseTextWithUrlsAndMentions(
                                  comment?.content || comment?.comment_content,
                                  userList
                                )}
                              </p> */}

                                <p
                                  className="text-black fs-14 font-noto-sans mb-0 fw-normal mt-1 text-break"
                                  style={{
                                    whiteSpace: 'pre-wrap',
                                    overflowWrap: 'break-word',
                                    wordBreak: 'break-word'
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: parseTextWithUrlsAndMentions(
                                      (comment?.content || comment?.comment_content || '')
                                        .replace(/\n/g, '<br />'),
                                      userList
                                    )
                                  }}
                                />
                                {/* )} */}

                                {/* {comment?.comment_status ? (
                                                            <p className='text-gray font-noto-sans mb-0 fw-normal mt-1 text-break' style={{ fontSize: "11px" }}>
                                                                Marked as resolved
                                                            </p>
                                                        ) : <></>
                                                        } */}

                                {/* Reply and Edit buttons */}
                                {hoveredCommentId === comment.comment_id ? (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "-25px",
                                      right: "0",
                                      display: "flex",
                                      gap: "5px",
                                    }}
                                  >
                                    <button
                                      className="hover-btn btn btn-outline-secondary"
                                      onClick={() =>
                                        onHandleReplyInputField(
                                          comment?.comment_id
                                        )
                                      }
                                    >
                                      {t("reply")}
                                    </button>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>

                              {/* edit comment */}
                              {/* {replyActions !== comment?.comment_id && */}
                              {!editCommentFlag &&
                                editId === comment?.comment_id &&
                                !editCommentFlag && (
                                  <div className="annotate-border d-flex  w-100 mt-3 bg-transparent px-3">
                                    <MentionsInput
                                      className="replyy"
                                      value={editComment}
                                      onChange={(e) =>
                                        onEditCommentChange(e, comment)
                                      }
                                      // onFocus={() => { setReplyActions(comment?.comment_id); setEditComment(comment?.comment_content) }}
                                      placeholder="Edit your comment"
                                      inputRef={mentionInputRef}
                                      a11ySuggestionsListLabel={
                                        "Suggested mentions"
                                      }
                                      style={defaultStyleForReply}
                                      forceSuggestionsAboveCursor={true}
                                      markup="@[__display__](__id__)"
                                      maxLength={250}
                                      onKeyDown={(e) =>
                                        handleKeyDown(
                                          e,
                                          comment.comment_id,
                                          comment,
                                          "edit_comment"
                                        )
                                      }
                                    >
                                      <Mention
                                        data={
                                          userList?.length > 0 ? userList : []
                                        }
                                        // onAdd={onAdd}
                                        appendSpaceOnAdd={true}
                                        displayTransform={(id, display) =>
                                          `@${display}`
                                        }
                                        style={{
                                          backgroundColor: "#d0e3ff",
                                          borderRadius: "4px",
                                        }}
                                      />
                                    </MentionsInput>
                                    <div
                                      className="comment-option-container edit-emoji-container"
                                      style={{
                                        width: "fit-content"
                                      }}
                                    >
                                      <button type="button" className="position-relative emoji-button" onClick={() => setShowEmojiPickerValues(pre => ({ ...pre, edit: !pre.edit }))}>
                                        😊
                                        {
                                          showEmojiPickerValues.edit
                                            ? <EmojiField
                                              refVal={editPickerRef}
                                              handleEmojiClick={(args) => handleEmojiClick(args, mentionInputRef, editComment, setEditComment)}
                                              search={true}
                                              height={200}
                                              previewConfig={{
                                                showPreview: false
                                              }}
                                            />
                                            : null
                                        }
                                      </button>
                                    </div>
                                  </div>
                                )}

                              {editId === comment?.comment_id &&
                                !editCommentFlag && (
                                  <div className="d-flex justify-content-end gap-2 mt-3 align-items-center">
                                    <button
                                      className="text-nowrap cancel_modal_footer cancel_modal_footer2"
                                      onClick={() => {
                                        setReplyActions(null);
                                        setMentionsUsers([]);
                                        setEditComment("");
                                        setEditCommentData();
                                        setEditCommentFlag(true);
                                      }}
                                    >
                                      {t("cancel")}
                                    </button>
                                    <button
                                      className="header-submit rounded-pill text-nowrap header-submit2"
                                      // onClick={() => onSubmitEditComment(comment.comment_id, comment)}

                                      onClick={() =>
                                        // {comment?.comment_status === 0 ? onReopenComment(comment?.comment_id, comment) : handleAddComment(comment.comment_id, comment, "edit_comment")}
                                        handleAddComment(
                                          comment.comment_id,
                                          comment,
                                          "edit_comment"
                                        )
                                      }
                                      disabled={!editComment.trim()}
                                    >
                                      {t("save")}
                                    </button>
                                  </div>
                                )}

                              {/* Open Reply Input Field */}
                              {isReplyFieldOpen &&
                                replyCommentId === comment?.comment_id && (
                                  <div className="annotate-border d-flex w-100 mt-3 bg-transparent px-3 pb-3">
                                    <MentionsInput
                                      className="replyy"
                                      value={replyValue}
                                      onChange={onChangeReply}
                                      placeholder={
                                        comment.comment_status === 0
                                          ? "Adding a reply will re-open this discussion..."
                                          : "Reply or add others with @"
                                      }
                                      inputRef={addReplyRef}
                                      a11ySuggestionsListLabel={
                                        "Suggested mentions"
                                      }
                                      style={defaultStyleForReply}
                                      forceSuggestionsAboveCursor={true}
                                      markup="@[__display__](__id__)"
                                      maxLength={250}
                                      onKeyDown={(e) =>
                                        handleKeyDown(
                                          e,
                                          comment.comment_id,
                                          comment,
                                          "reply_comment"
                                        )
                                      }
                                    >
                                      <Mention
                                        data={
                                          userList?.length > 0 ? userList : []
                                        }
                                        // onAdd={onAdd}
                                        appendSpaceOnAdd={true}
                                        displayTransform={(id, display) =>
                                          `@${display}`
                                        }
                                        style={{
                                          backgroundColor: "#d0e3ff",
                                          borderRadius: "4px",
                                        }}
                                      />
                                    </MentionsInput>
                                    <div
                                      className="comment-option-container edit-emoji-container"
                                      style={{
                                        width: "fit-content"
                                      }}
                                    >
                                      <button type="button" className="position-relative emoji-button" onClick={() => setShowEmojiPickerValues(pre => ({ ...pre, reply: !pre.reply }))}>
                                        😊
                                        {
                                          showEmojiPickerValues.reply
                                            ? <EmojiField
                                              refVal={replyPickerRef}
                                              handleEmojiClick={(args) => handleEmojiClick(args, addReplyRef, replyValue, setReplyValue)}
                                              search={true}
                                              height={200}
                                              previewConfig={{
                                                showPreview: false
                                              }}
                                            />
                                            : null
                                        }
                                      </button>
                                    </div>
                                  </div>
                                )}

                              {isReplyFieldOpen &&
                                replyCommentId === comment?.comment_id && (
                                  <div className="d-flex justify-content-end gap-2 mt-3 align-items-center">
                                    <button
                                      className="text-nowrap cancel_modal_footer cancel_modal_footer2"
                                      onClick={() => {
                                        setIsReplyFieldOpen(false);
                                        setReplyValue("");
                                        setNewReply(false);
                                      }}
                                    >
                                      {t("cancel")}
                                    </button>
                                    <button
                                      className="header-submit rounded-pill text-nowrap header-submit2"
                                      // onClick={() => onAddReply(comment?.comment_id, comment?.mention_user_id)}
                                      onClick={() =>
                                        handleAddComment(
                                          comment.comment_id,
                                          comment,
                                          "reply_comment"
                                        )
                                      }
                                      disabled={!replyValue.trim()}
                                    >
                                      {t("save")}
                                    </button>
                                  </div>
                                )}

                              <div className="mt-2 ">
                                {comment?.replies?.length > 0 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        flexGrow: 1,
                                        height: "1px",
                                        backgroundColor: "#D3D3D3",
                                      }}
                                    ></div>
                                    {comment?.replies?.length <= 2 ? (
                                      // If there are 3 or fewer replies, show the count
                                      <>
                                        <span
                                          className="font-noto-sans chat-replay"
                                          style={{
                                            padding: "0 10px",
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {comment?.replies?.length} {t("reply")}
                                        </span>
                                        <div
                                          style={{
                                            flexGrow: 1,
                                            height: "1px",
                                            backgroundColor: "#D3D3D3",
                                          }}
                                        ></div>
                                      </>
                                    ) : (
                                      <>
                                        {/* When there are more than 3 replies */}
                                        {!comment?.isSlice ? (
                                          // If isSlice is false, show Read More with the hidden replies count
                                          <>
                                            <span
                                              onClick={() =>
                                                onClickOfReadMore(comment)
                                              }
                                              className="font-noto-sans chat-replay cursor-pointer bg-secondary rounded-pill px-2"
                                              style={{
                                                padding: "0 10px",
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                                color: "#fff",
                                              }}
                                            >
                                              {`${comment?.replies?.length - 2
                                                } ${t("More Replies")}`}
                                            </span>
                                            <div
                                              style={{
                                                flexGrow: 1,
                                                height: "1px",
                                                backgroundColor: "#D3D3D3",
                                              }}
                                            ></div>
                                          </>
                                        ) : (
                                          // If isSlice is true, show Read Less
                                          <>
                                            <span
                                              onClick={() =>
                                                onClickOfReadMore(comment)
                                              }
                                              className="font-noto-sans chat-replay cursor-pointer bg-secondary rounded-pill px-2"
                                              style={{
                                                padding: "0 10px",
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                                color: "#fff",
                                              }}
                                            >
                                              {t("Read Less")}
                                            </span>
                                            <div
                                              style={{
                                                flexGrow: 1,
                                                height: "1px",
                                                backgroundColor: "#D3D3D3",
                                              }}
                                            ></div>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                )}
                                {(comment?.isSlice
                                  ? comment?.replies
                                  : comment?.replies?.slice(0, 2)
                                )?.map((replyItem, i) => {
                                  let replyUser = userList.find(
                                    (user) =>
                                      user.id ===
                                      (replyItem?.author_user_id ||
                                        replyItem?.reply_author_details
                                          ?.reply_author_user_id)
                                  );
                                  return (
                                    <div
                                      key={i}
                                      className="mb-2 ms-4 position-relative d-flex align-items-center justify-content-center"
                                    >
                                      <div className="w-100">
                                        <div className="d-flex gap-2 align-items-center chat-replay-wrap">
                                          <div className="icon-box-width font-noto-sans  fs-16 text-white d-flex justify-content-center align-items-center position-relative background-black rounded-circle">
                                            <div className="d-flex align-items-center">
                                              <p className="mb-0">
                                                <span>
                                                  {replyItem?.reply_author_details?.reply_author_user_name
                                                    ?.charAt(0)
                                                    ?.toUpperCase() ||
                                                    replyItem?.author_user_name
                                                      ?.charAt(0)
                                                      ?.toUpperCase()}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div>
                                            <div className="d-flex  flex-column gap-0 mt-2">
                                              <Tooltip
                                                title={
                                                  <div>
                                                    <strong>
                                                      {replyUser?.display}
                                                    </strong>
                                                    <br />
                                                    Email: {replyUser?.email}
                                                  </div>
                                                }
                                                arrow
                                                placement={
                                                  i === 0 ? "bottom" : "top"
                                                }
                                                PopperProps={{
                                                  style: { zIndex: 3000 },
                                                }}
                                              >
                                                <h6 className="font-noto-sans fs-14 text-black mb-0 fw-bold cursor-pointer">
                                                  {replyItem?.reply_author_details
                                                    ?.reply_author_user_name ||
                                                    replyItem?.author_user_name}
                                                </h6>
                                              </Tooltip>

                                              {comments?.map((comment) => (
                                                <div key={comment?.comment_id}>
                                                  {comment?.replies?.map(
                                                    (reply) => {
                                                      if (
                                                        reply?.reply_comment_id ===
                                                        comment?.comment_id &&
                                                        reply?.reply_content ===
                                                        parseTextWithUrlsAndMentions(
                                                          replyItem?.reply_content
                                                        ).toString() &&
                                                        reply?.reply_id ===
                                                        replyItem?.reply_id
                                                      ) {
                                                        return (
                                                          <div
                                                            key={reply?.reply_id}
                                                          >
                                                            <p className="font-noto-sans text-dark-gray fs-12 mb-0">
                                                              {formatCommentDate2(
                                                                reply?.reply_updated_timestamp
                                                              )}
                                                            </p>
                                                          </div>
                                                        );
                                                      }
                                                      return null;
                                                    }
                                                  )}
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                          {(userSettings?.account_login_id ===
                                            replyItem?.reply_author_details
                                              ?.reply_author_user_id ||
                                            userSettings?.account_login_id ===
                                            replyItem?.author_user_id) && (
                                              <div
                                                className="comment-options"
                                                style={{ marginLeft: "auto" }}
                                              >
                                                <Button
                                                  id="basic-button"
                                                  aria-controls={
                                                    menuOpenReplyId ===
                                                      replyItem?.reply_id
                                                      ? "basic-menu"
                                                      : undefined
                                                  }
                                                  aria-haspopup="true"
                                                  aria-expanded={
                                                    menuOpenReplyId ===
                                                      replyItem?.reply_id
                                                      ? "true"
                                                      : undefined
                                                  }
                                                  onClick={(e) =>
                                                    onHandleReplyMenu(
                                                      e,
                                                      replyItem?.reply_id
                                                    )
                                                  }
                                                  style={{}}
                                                  className="border-0"
                                                >
                                                  <img
                                                    src={ellipsis_vertical}
                                                    alt="menu"
                                                  />
                                                </Button>
                                                <Menu
                                                  id="basic-menu"
                                                  anchorEl={
                                                    menuOpenReplyId ===
                                                      replyItem?.reply_id
                                                      ? anchorElReply
                                                      : null
                                                  }
                                                  open={
                                                    menuOpenReplyId ===
                                                    replyItem?.reply_id &&
                                                    Boolean(anchorElReply)
                                                  }
                                                  onClose={onCloseReplyMenu}
                                                  MenuListProps={{
                                                    "aria-labelledby":
                                                      "basic-button",
                                                  }}
                                                >
                                                  {isCommentOwner(replyItem?.reply_author_details) ||
                                                    isCommentOwner(replyItem) ? (
                                                    <>
                                                      <MenuItem
                                                        onClick={() =>
                                                          onUpdateReply(
                                                            replyItem,
                                                            comment?.comment_id
                                                          )
                                                        }
                                                      >
                                                        {t("edit")}
                                                      </MenuItem>
                                                      <MenuItem
                                                        onClick={() =>
                                                          onDeleteReply(
                                                            replyItem?.reply_id
                                                          )
                                                        }
                                                      >
                                                        {t("delete")}
                                                      </MenuItem>
                                                    </>
                                                  ) : null}
                                                </Menu>
                                              </div>
                                            )}
                                        </div>

                                        {/* <p className="text-black fs-14 font-noto-sans mb-0 fw-normal mt-1 chat-replay-content text-break">
                                        {parseTextWithUrlsAndMentions(
                                          replyItem?.content ||
                                          replyItem?.reply_content,
                                          userList
                                        )}
                                      </p> */}
                                        <p
                                          className="text-black fs-14 font-noto-sans mb-0 fw-normal mt-1 chat-replay-content text-break"
                                          style={{
                                            whiteSpace: 'pre-wrap',
                                            overflowWrap: 'break-word',
                                            wordBreak: 'break-word'
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: parseTextWithUrlsAndMentions(
                                              (replyItem?.content || replyItem?.reply_content || '')
                                                .replace(/\n/g, '<br />'),
                                              userList
                                            )
                                          }}
                                        />
                                        {/* Open Reply Edit Input Field */}

                                        {isReplyFieldOpen &&
                                          replyCommentId ===
                                          replyItem?.reply_id && (
                                            <div className="annotate-border d-flex w-100 mt-3 bg-transparent px-3">
                                              <MentionsInput
                                                className="replyy"
                                                value={replyValue}
                                                onChange={onReplyEditValue}
                                                placeholder="Edit Reply"
                                                inputRef={addReplyRef}
                                                markup="@[__display__](__id__)"
                                                a11ySuggestionsListLabel={
                                                  "Suggested mentions"
                                                }
                                                style={defaultStyleForReply}
                                                maxLength={250}
                                                onKeyDown={(e) =>
                                                  handleKeyDown(
                                                    e,
                                                    comment.comment_id,
                                                    comment,
                                                    "edit_reply",
                                                    replyItem
                                                  )
                                                }
                                              >
                                                <Mention
                                                  data={
                                                    userList?.length > 0
                                                      ? userList
                                                      : []
                                                  }
                                                  // onAdd={onAdd}
                                                  appendSpaceOnAdd={true}
                                                  displayTransform={(
                                                    id,
                                                    display
                                                  ) => `@${display}`}
                                                  style={{
                                                    backgroundColor: "#d0e3ff",
                                                    borderRadius: "4px",
                                                  }}
                                                />
                                              </MentionsInput>
                                              <div
                                                className="comment-option-container edit-emoji-container"
                                                style={{
                                                  width: "fit-content"
                                                }}
                                              >
                                                <button type="button" className="position-relative emoji-button" onClick={() => setShowEmojiPickerValues(pre => ({ ...pre, replyEdit: !pre.replyEdit }))}>
                                                  😊
                                                  {
                                                    showEmojiPickerValues.replyEdit
                                                      ? <EmojiField
                                                        refVal={replyEditPickerRef}
                                                        handleEmojiClick={(args) => handleEmojiClick(args, addReplyRef, replyValue, setReplyValue)}
                                                        search={true}
                                                        height={200}
                                                        previewConfig={{
                                                          showPreview: false
                                                        }}
                                                      />
                                                      : null
                                                  }
                                                </button>
                                              </div>
                                            </div>
                                          )}

                                        {isReplyFieldOpen &&
                                          replyCommentId ===
                                          replyItem?.reply_id && (
                                            <div className="d-flex justify-content-end gap-2 mt-3 align-items-center">
                                              <button
                                                className="text-nowrap cancel_modal_footer cancel_modal_footer2"
                                                onClick={() => {
                                                  setIsReplyFieldOpen(false);
                                                  setReplyValue("");
                                                  setEditReply(false);
                                                }}
                                              >
                                                {t("cancel")}
                                              </button>
                                              <button
                                                className="header-submit rounded-pill text-nowrap header-submit2"
                                                // onClick={() => onSubmitEditReply(replyItem, comment)}
                                                onClick={() =>
                                                  handleAddComment(
                                                    comment.comment_id,
                                                    comment,
                                                    "edit_reply",
                                                    replyItem
                                                  )
                                                }
                                                disabled={!replyValue.trim()}
                                              >
                                                {t("save")}
                                              </button>
                                            </div>
                                          )}
                                      </div>

                                      {replyDeleteAlert ===
                                        replyItem?.reply_id && (
                                          <div className="position-absolute w-100 h-100">
                                            <div
                                              className="position-relative p-1 d-flex justify-content-center align-items-center h-100"
                                              style={{
                                                backgroundColor: "#212529d4",
                                              }}
                                            >
                                              <div
                                                className="d-flex gap-3 justify-content-center position-absolute"
                                                style={{ zIndex: "1" }}
                                              >
                                                <button
                                                  className="bg-white border-0 rounded-pill px-2 py-1"
                                                  onClick={() =>
                                                    handleReplyDelete(
                                                      replyItem?.reply_id
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </button>
                                                <button
                                                  className="bg-white border-0 rounded-pill px-2 py-1"
                                                  onClick={() =>
                                                    setReplyDeleteAlert(null)
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div>
                              {commentDeleteAlert === comment?.comment_id && (
                                <div className="position-absolute w-100 h-100 top-0">
                                  <div
                                    className="position-relative p-1 h-100 d-flex justify-content-center"
                                    style={{ backgroundColor: "#212529d4" }}
                                  >
                                    <div
                                      className=" position-absolute top-0"
                                      style={{ zIndex: "1" }}
                                    >
                                      <p className="mb-0 py-1 text-white">
                                        Delete this comment thread?
                                      </p>
                                      <div className="d-flex gap-3 justify-content-center">
                                        <button
                                          className="bg-white border-0 rounded-pill px-2 py-1"
                                          onClick={() =>
                                            handleDeleteComment(
                                              comment?.comment_id,
                                              comment?.feature
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                        <button
                                          className="bg-white border-0 rounded-pill px-2 py-1"
                                          onClick={() =>
                                            setCommentDeleteAlert(null)
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null;
                    })}
              </div>
            )}
            {tab === 2 && <div></div>}
          </div>
        ) : (
          <div className="d-flex justify-content-center h-100  align-content-center"></div>
        )}

        <div ref={commentsEndRef} />
      </div>
      {tab === 1 && (
        <div
          className="add-comment p-1 border-top w-100 position-sticky bottom-0"
          style={{ zIndex: "1" }}
        >
          <div className="comment-container">
            <MentionsInput
              value={commentWithoutAnnotate}
              onChange={onChange}
              className="comment-input-container"
              placeholder={t("Annotate&comment")}
              inputRef={AddCommentRef}
              a11ySuggestionsListLabel={"Suggested mentions"}
              // style={defaultStyle}
              style={{
                ...defaultStyle,
                input: {
                  ...defaultStyle.input,
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word'
                },
                suggestions: {
                  ...defaultStyle.suggestions
                }
              }}
              forceSuggestionsAboveCursor={true}
              markup="@[__display__](__id__)"
              onKeyDown={(e) => handleKeyDown(e, "", "", "new_comment")}
              maxLength={250}
            >
              <Mention
                data={userList?.length > 0 ? userList : []}
                // onAdd={onAdd}
                appendSpaceOnAdd={true}
                displayTransform={(id, display) => `@${display}`}
                style={{
                  backgroundColor: "#d0e3ff",
                  borderRadius: "4px",
                }}
              />

            </MentionsInput>
            <div className="comment-option-container">
              <button type="button" className="position-relative emoji-button" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                😊
                {
                  showEmojiPicker
                    ? <EmojiField
                      refVal={pickerRef}
                      handleEmojiClick={(args) => handleEmojiClick(args, AddCommentRef, commentWithoutAnnotate, setCommentWithoutAnnotate)}
                    />
                    : null
                }
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-3">
            {/* <button className='header-submit rounded-pill text-nowrap'
                            onClick={() => makePublicComment()}
                            disabled={!isCommentPublic || !commentWithoutAnnotate.trim()}>Make Public
                        </button> */}
            <FormControlLabel
              label={t("makePublic")}
              control={
                <Checkbox
                  checked={isCommentPublic}
                  onChange={makePublicComment}
                  inputProps={{ "aria-label": "controlled" }}
                  className="font-noto-sans fs-14 fw-400"
                  disabled={!commentWithoutAnnotate.trim()}
                />
              }
            />


            {/* <FormControlLabel  className='font-noto-sans fs-14 fw-400' disabled={isCommentPublic || !commentWithoutAnnotate.trim()} checked={isCommentPublic} onChange={makePublicComment} label="Make Public"  /> */}
            {/* <img src={info} alt='info' /> */}
            <button
              className="header-submit rounded-pill text-nowrap"
              onClick={() => handleAddComment("", "", "new_comment")}
              disabled={!commentWithoutAnnotate.trim() || isSubmitting} // Disable during submission
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                t("addComment")
              )}
            </button>
            {/* <button
              className="header-submit rounded-pill text-nowrap"
              onClick={() => handleAddComment("", "", "new_comment")}
              disabled={!commentWithoutAnnotate.trim()}
            > */}
            {/* {!loading ? `${t("addComment")}` : ""}
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                            </React.Fragment> */}
            {/* {t("addComment")}
            </button> */}
          </div>
        </div>
      )}
    </div>
    // </div>
  );
};

export default CommentAndTask;
