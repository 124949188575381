import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Group, Rect, Text, Image, Circle, Transformer } from 'react-konva';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DraggableImage from './DraggableImage';
import { useKonvaContext } from '../../context/useKonvaContext';
import { addSheet, updateSheetStatus, updateSheetName, initializeCanvas, removeSheet, updateSheetLock, updateLayerElement, addElement, updateSheet } from '../../store/konvaSlice';
import threeDots from '../../assets/newicons/three-dots.svg';
import lockOpen from "../../assets/newicons/lock_open.svg";
import lockClose from "../../assets/newicons/lock_close.svg";
import './Konva.css';
import verified from "../../assets/newicons/verified.svg";
import { Menu, MenuItem, Divider, styled, Select, alpha } from '@mui/material';
import remove from "../../assets/newicons/delete1.svg";
//css
import './Konva.css'
import { useReferenceContext } from '../../context/useReferenceContext';
import { newImagePost, newPut, newPost_V3, imageGet, newGet_V3, newPut_V3 } from '../../API/axios';
import { newPost } from '../../API/axios';
import { newGet } from '../../API/axios';
import { Html } from 'react-konva-utils';
import { showConfirmationDialog, showAlert } from "../../views/Manager/component/AlertService";
import { useAuthContext } from '../../context/useAuthContext';
import { useNavigate } from 'react-router-dom';
import DraggableText from './DraggableText';
import CountdownPopup from '../Shared/CountdownPopup';
import UploadComponent from '../Shared/UploadComponent';


const KonvaCanvas = ({ selectedImages, scale, setScale, canvasData, modalRef, setCurrentSheetId, lockNRequestApproval, cancelRequestApproval, sheetListLoading, setShowCommentSection, showCommentSection }) => {
  const { t } = useTranslation();
  const { stageRef, containerRef, setSelectedSheetLayerId, selectedImageId, setSelectedImageId, setSelectedSheet, setSelectedSheetElement, setSelectedSheetId, selectedSheetId, setSelectedId, setShowSideModal, setSelectedSheetIds, setCreateSheetFromImage, isSelecting, setIsSelecting, selectedSheetElement } = useKonvaContext();
  const dispatch = useDispatch();
  const [error, setError] = useState(false)
  const canvas = useSelector(state => state.konva.canvas);
  const { boardId, designId, projectDetails, setProjectDetails, isCommentDeleted } = useReferenceContext();
  const { userSettings, selectedSite } = useAuthContext();
  const [lockLoadingStates, setLockLoadingStates] = useState({})
  const [addingNewSheetLoading, setAddingNewSheetLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedTextId, setSelectedTextId] = useState(null)
  const [createPopupPosition, setCreatePopupPosition] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const channelName = urlParams.get("channel_name");
  const projectName = urlParams.get("project_name");
  const design_Id = urlParams.get("design_id");
  const { setSheetCount, setEditingTextId, setInputPosition, selectedId, setShowTextOptionsModal, setNewTextValue } = useKonvaContext();

  const selectedSheet = canvas?.layers?.[0]?.groups?.find(group => group.id === selectedSheetId);

  // Destructure from selectedSheet instead of canvasData
  const {
    name: sheet_name = '',
    id: sheet_id = null,
    is_locked: sheet_is_locked = false,
    status_code: sheet_status_code = null,
    status_id: sheet_status_id = 1
  } = selectedSheet || {};

  const [stageSize, setStageSize] = useState({
    width: window.innerWidth,
    height: (window.innerHeight - 56)
  });

  console.log("canvasData", canvasData, canvas)
  // const [imag]
  const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });
  const [menuTargetSheet, setMenuTargetSheet] = useState(null);
  const [pages, setPages] = useState([]);
  const [lockIcon, setLockIcon] = useState(null);
  const [menuIcon, setMenuIcon] = useState(null);
  const [isLock, setisLock] = useState(sheet_is_locked);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState(false);
  const [editingText, setEditingText] = useState(null);
  const [designStatus, setDesignStatus] = useState(1);
  const [sheetStatus] = useState([

    { id: 2, name: 'Awaiting Approval', label: t("statusAwaitingApproval") },
    { id: 3, name: 'Approved', label: t('sheetDropdownApprove') },
    { id: 4, name: 'Returned', label: t('sheetDropdownReturn') }
  ]);
  const [dragSheetId, setDragSheetId] = useState(0)
  // Add a state to track if menu is open
  const isMenuOpen = Boolean(menuPosition);
  const [uploadPopUpOpen, setUploadPopupOpen] = useState(false)
  // Add new state for right-click menu
  const [rightClickMenu, setRightClickMenu] = useState({
    visible: false,
    x: 0,
    y: 0
  });

  // Update the useEffect to handle undefined canvasData
  useEffect(() => {
    if (canvasData && Array.isArray(canvasData)) {
      setPages(canvasData.map((canvas, index) => ({
        id: canvas?.sheet_id,
        x: 50 + (index * 600),
        y: 50
      })));
    }
  }, [canvasData]);

  // Update the useEffect for isLock
  useEffect(() => {
    if (selectedSheet?.status_id === 2 || selectedSheet?.status_id === 3) {
      setisLock(true);
    } else {
      setisLock(false);
    }
  }, [selectedSheet]);

  // Check if project is active based on status
  const isProjectActive = () => {
    if (!projectDetails?.status) return true; // Default to active if no status

    const statusId = parseInt(Object.keys(projectDetails.status)[0]);

    // Status 5 means completed/cancelled project, status 3 means inactive
    return statusId !== 5 && statusId !== 3;
  };


  // Get project details if not available
  const getProjectDetails = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const projectId = urlParams.get("project_id");
    const siteId = selectedSite?.site_id;

    if (projectId && siteId) {

      try {
        const response = await newGet(
          `project/getProjectDetails?project_id=${parseInt(projectId)}&site_id=${parseInt(siteId)}`
        );
        if (response?.message?.[0].property_message === "PROJECT_NOT_FOUND" || response?.message?.[0].property_message === "UNAUTHORIZED" || response?.result[0]?.channel_name[0]?.site_name != channelName || response?.result[0]?.project_name != projectName || response?.result[0]?.design_id != design_Id) {
          console.log(`Konvacanvas  ${response?.message?.[0].property_message}  ${response?.message?.[0].property_message}  ${response?.result[0]?.channel_name[0]?.site_name}  :: ${channelName}  :: ${response?.result[0]?.project_name}  :: ${projectName}  :: ${response?.result[0]?.design_id}  :: ${design_Id}`)
          setError(true)
          return;
        }

        setProjectDetails(response?.result[0]);

      } catch (error) {
        console.error("Error fetching project details:", error);
        setError(true)
        // navigate("/dashboard");
      }
    }
  };

  useEffect(() => {
    if (!projectDetails) {
      getProjectDetails();
    }
  }, [selectedSite, projectName, channelName, design_Id]);
  // Handle page drag
  const handlePageDragStart = (e) => {
    e.target.setAttrs({
      shadowOffset: {
        x: 5,
        y: 5
      },
      shadowBlur: 6,
      shadowOpacity: 0.3
    });
  };

  const handlePageDragEnd = async (e, pageId) => {
    const newPos = e.target.position();

    // Update Redux store first
    dispatch(updateSheet({
      sheetId: pageId,
      updates: {
        position: {
          x: newPos.x,
          y: newPos.y
        }
      }
    }));

    // Reset shadow effect
    e.target.setAttrs({
      shadowOffset: {
        x: 0,
        y: 0
      },
      shadowBlur: 0,
      shadowOpacity: 0
    });

    try {
      // Call API to save coordinates
      const response = await newPut_V3('sheet/saveSheetCoordinates', {
        sheet_id: pageId,
        position_x: Math.round(newPos.x),
        position_y: Math.round(newPos.y)
      });

      if (![200, 201].includes(response?.status_code)) {
        // If API call fails, you may want to revert the position
        console.error('Failed to save sheet coordinates');
      }
    } catch (error) {
      console.error('Error saving sheet coordinates:', error);
    }
  };

  // Add missing handler functions
  const handleDragStart = (e, layerId, elementId) => {
    // Handle drag start
    e.target.setAttrs({
      shadowOffset: {
        x: 15,
        y: 15
      },
      scaleX: 1.1,
      scaleY: 1.1
    });
  };

  const handleDragEnd = (e, layerId, elementId) => {
    // Handle drag end
    e.target.to({
      duration: 0.1,
      scaleX: 1,
      scaleY: 1,
      shadowOffsetX: 0,
      shadowOffsetY: 0
    });
  };

  const sheetHasElement = (e) => {
    return e?.elements?.length > 1 || e?.elements[0]?.image_data?.data
  }


  const handleLockNRequestApproval = async () => {
    try {
      // Check if we have a valid selectedSheetId and sheet is not already locked
      const selectedSheet = canvas?.layers?.[0]?.groups?.find(group => group.id === selectedSheetId);

      if (!selectedSheetId || !selectedSheet) {
        console.error("No sheet selected");
        return;
      }

      // Don't proceed if sheet is already locked or approved
      if (selectedSheet.status_id === 2 || selectedSheet.status_id === 3) {
        console.log("Sheet is already locked or approved");
        return;
      }

      const response = await newPut('sheet/lockUnlock', {
        sheet_id: selectedSheetId,
        is_locked: true
      });

      if (response?.status_code === 200) {
        // Update Redux state
        dispatch(updateSheetStatus({
          sheetId: selectedSheetId,
          status: 2  // Status 2 for locked/awaiting approval
        }));
        setSelectedSheet({ ...selectedSheet, status_id: 2, is_locked: true })
        setisLock(true);

        // Refresh the design details to get updated status
        await getDesignDetail();
      }
    } catch (error) {
      console.error("Error in handleLockNRequestApproval:", error);
    }
  };


  const handleCancelRequestApproval = async () => {
    try {
      const selectedSheet = canvas?.layers?.[0]?.groups?.find(group => group.id === selectedSheetId);

      if (!selectedSheetId || !selectedSheet) {
        console.error("No sheet selected");
        return;
      }

      // Only proceed if sheet is locked (status 2)
      if (selectedSheet.status_id !== 2) {
        console.log("Sheet is not in locked state");
        return;
      }

      const response = await newPut('sheet/lockUnlock', {
        sheet_id: selectedSheetId,
        is_locked: false
      });

      if (response?.status_code === 200) {
        // Update Redux state
        dispatch(updateSheetStatus({
          sheetId: selectedSheetId,
          status: 1  // Status 1 for draft/unlocked
        }));
        setSelectedSheet({ ...selectedSheet, status_id: 1, is_locked: false })
        setisLock(false);

        // Refresh the design details to get updated status
        await getDesignDetail();
      }
    } catch (error) {
      console.error("Error in handleCancelRequestApproval:", error);
    }
  };

  const handleGroupClick = (layerId, elementId) => {
    // Handle group click
    setCurrentSheetId(layerId);
  };
  const handleDoubleClick = (textElement, textNode) => {
    console.log("textElement>>>>>>>>>>>>>>>>>>>>>>>>", textElement);
    console.log("textNode>>>>>>>>>>>>>>>>>>>>>>>>", textNode);

    setEditingTextId(textElement.id);
    setSelectedId(textElement?.id);
    const textRect = textNode.getClientRect();
    const stageRect = stageRef.current.container().getBoundingClientRect();
    setInputPosition({
      x: stageRect.x + textRect.x,
      y: stageRect.y + textRect.y + textRect.height,
    });
    // console.log("selectedId---------------", selectedId)
    if (selectedId && selectedId.includes('text')) {
      setShowTextOptionsModal(true);
      setShowSideModal(true);
      setNewTextValue(textElement?.props?.text);
    }
  };
  const handleTransformEnd = (e, layerId, elementId) => {
    // Handle transform end
    const node = e.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // Reset scale and adjust width/height instead
    node.scaleX(1);
    node.scaleY(1);
    node.width(Math.max(5, node.width() * scaleX));
    node.height(Math.max(5, node.height() * scaleY));
  };

  const addNewSheet = async () => {
    if (addingNewSheetLoading) return
    setAddingNewSheetLoading(true)
    try {
      const sheetCount = canvas?.layers?.[0]?.groups?.length || 0;
      const newSheetName = `Sheet${sheetCount + 1}`;

      const response = await newPost('sheet/create', {
        design_id: designId,
        sheet_name: newSheetName,
        sheet_status_id: 1,
        sheet_is_locked: false
      });

      if (response?.status_code === 200) {
        const newSheet = {
          sheet_id: response.result.sheet_id,
          sheet_name: newSheetName,
          sheet_status_id: 1,
          sheet_is_locked: false,
          layers: [{
            id: response.result.sheet_id,
            elements: []
          }]
        };

        dispatch(addSheet(newSheet));

        setSelectedSheetId(response.result.sheet_id);
        setSelectedSheetLayerId(response.result.sheet_id);
        setSheetCount(prev => prev + 1);

        // Call getDesignDetail API after adding new sheet
        await getDesignDetail();
      }
      setTimeout(() => {
        setAddingNewSheetLoading(false)
      }, 500)
    } catch (error) {
      console.error("Error creating new sheet:", error);
      setTimeout(() => {
        setAddingNewSheetLoading(false)
      }, 500)
    }
  };

  // Add getDesignDetail function
  const getDesignDetail = async () => {
    try {
      const response = await newGet_V3(`design/getDesignDetail?design_id=${designId}`);
      if (response?.status_code === 200) {
        console.log("response.result?????????????????????????????????????????", response.result)
        dispatch(initializeCanvas(response.result));
      }
    } catch (error) {
      console.error("Error fetching design details:", error);
    }
  };


  // Handle stage resize
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setStageSize({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight
        });
      }
    };
    window.addEventListener('resize', updateDimensions);
    updateDimensions();
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);



  // Handle zoom
  const handleWheel = (e) => {
    e.evt.preventDefault();

    const stage = stageRef.current;
    const oldScale = scale;
    const pointer = stage.getPointerPosition();

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    const newScale = e.evt.deltaY > 0 ? oldScale * 0.9 : oldScale * 1.1;
    const boundedScale = Math.min(Math.max(newScale, 0.1), 3);

    setScale(boundedScale);
    setStagePosition({
      x: pointer.x - mousePointTo.x * boundedScale,
      y: pointer.y - mousePointTo.y * boundedScale,
    });
  };

  const lockAndUnlockSheet = async (sheet, canvasIdx) => {
    try {

      if (!isProjectActive()) {
        return;
      }

      if (lockLoadingStates[canvasIdx]) return
      setLockLoadingStates(prev => ({ ...prev, [canvasIdx]: true }))
      if (!sheetHasElement(sheet)) {
        setLockLoadingStates(prev => ({ ...prev, [canvasIdx]: false }))
        return;
      }

      // Check if sheet is awaiting approval (status 2)
      if (sheet.status_id === 2) {
        showAlert("Cannot unlock sheet while awaiting approval", "warning");
        setLockLoadingStates(prev => ({ ...prev, [canvasIdx]: false }))
        return;
      }


      // Check if we have a valid selectedSheetId and sheet
      const selectedSheet = canvas?.layers?.[0]?.groups?.find(group => group.id === sheet.id);

      // if (!selectedSheetId || !selectedSheet) {
      //     console.error("No sheet selected");
      // }

      // Don't proceed if sheet is approved (status 3)
      if (selectedSheet.status_id === 3) {
        console.log("Sheet is already approved");
        setLockLoadingStates(prev => ({ ...prev, [canvasIdx]: false }))
        return;
      }


      // Determine the new lock state based on current is_locked value
      const newLockState = !selectedSheet.is_locked;
      const response = await newPut('sheet/lockUnlock', {
        sheet_id: sheet.id,
        is_locked: newLockState
      });
      if (response?.status_code === 200) {
        // Update Redux state with is_locked instead of status
        dispatch(updateSheetLock({
          sheetId: sheet.id,
          is_locked: newLockState
        }));
        setisLock(newLockState);
        setSelectedSheet({ ...selectedSheet, is_locked: newLockState })
        // Refresh the design details to get updated status
        // await getDesignDetail();

        console.log(`Sheet ${newLockState ? 'Locked' : 'Unlocked'}`, response);
      }
      setTimeout(() => {
        setLockLoadingStates(prev => ({ ...prev, [canvasIdx]: false }))
      }, 2000)
    } catch (error) {
      console.error("Error in lockAndUnlockSheet:", error);
      setTimeout(() => {
        setLockLoadingStates(prev => ({ ...prev, [canvasIdx]: false }))
      }, 2000)
    }
  };

  const getSheetDetails = async (sheetId) => {
    const response = await newGet_V3(`sheet/getSheetDetail?sheet_id=${sheetId}`);
    if (selectedSheetElement) {

      const element = response?.result?.layers.find(layer => {
        return layer.id === selectedSheetElement.id;
      });
      if (element) {
        setSelectedSheetElement(element);
        setSelectedSheet(response.result); // Set the selected sheet when element is found
      }
    }
    const sheet = response.result
    await dispatch(updateSheet({
      sheetId: selectedSheetId,
      updates: {
        name: sheet?.sheet_name || "Untitled",
        status_id: sheet?.sheet_status_id || 1,
        is_locked: sheet?.sheet_is_locked || false,
        position: {
          x: sheet?.position?.[0] || null,
          y: sheet?.position?.[1] || null
        },
        elements: sheet.layers?.map((layer) => ({
          ...layer,
          id: layer.id,
          type: 'Image',
          sheet_id: sheet.sheet_id || sheet.id,
          x: 0,
          y: 0,
          width: 500,
          height: 500,
          image_data: {
            data: layer?.image_data?.data || '',
            format: layer?.image_data?.format || 'PNG',
            size: layer?.image_data?.size || [500, 500]
          },
          draggable: false,
          blend_mode: layer?.blend_mode || 'norm',
          opacity: layer?.opacity || 1,
          cornerRadius: layer?.cornerRadius || 0,
          position: {
            x: layer?.position?.[0] || 0,
            y: layer?.position?.[1] || 0
          },
          rotation: layer?.rotation || 0,
          visible: layer?.visible !== undefined ? layer?.visible : true,
          zIndex: layer?.zIndex || 0
        })) || []
      }
    }))
  }

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      position: 'fixed',
      marginTop: 0,
      minWidth: 250,
      color: 'rgb(55, 65, 81)',
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        fontWeight: 400,
        fontSize: 14,
        '& .MuiSvgIcon-root': {

          color: theme.palette.text.secondary,
          marginRight: theme.spacing(0.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[300],
      }),
    },
  }));


  const handleMenuClick = (e, sheet) => {
    e?.preventDefault();
    e?.stopPropagation();
    e.cancelBubble = true;

    const stage = stageRef.current;
    const stageBox = stage.container().getBoundingClientRect();
    const pointerPos = stage.getPointerPosition();

    const absolutePos = {
      x: stageBox.left + pointerPos.x,
      y: stageBox.top + pointerPos.y
    };

    setMenuPosition(absolutePos);
    setAnchorEl(e.currentTarget);
    setMenuTargetSheet(sheet);

    setSelectedSheetId(sheet.id);
    setSelectedSheetLayerId(sheet?.elements?.[0]?.id);
  };

  const handleMenuClose = () => {
    setMenuPosition(null);
    setAnchorEl(null);
    setMenuTargetSheet(null);
  };




  // Add this function to handle sheet deletion
  const handleDeleteSheet = async (sheetId) => {
    try {
      // Show confirmation dialog first
      const confirmation = await showConfirmationDialog(
        t("deleteSheetConfirmation"),
        t("deleteSheetWarning"),
        "warning",
        t("cancel"),
        t("delete")
      );

      // Only proceed if user confirms
      if (confirmation) {
        const response = await newPut('sheet/delete', {
          sheet_id: sheetId
        });

        if (response?.status_code === 200) {
          // Create a plain object action
          const action = {
            type: 'konva/removeSheet',
            payload: sheetId
          };

          // Dispatch the plain object action
          dispatch(action);

          // Close menu and update canvas
          handleMenuClose();
          setSheetCount(prev => prev - 1);
          await getDesignDetail();
        }
      }
    } catch (error) {
      console.error("Error deleting sheet:", error);
      showAlert(t("sheetDeleteError"), "error");
    }
  };

  // Update the useEffect for loading images
  useEffect(() => {
    // Load menu icon
    const menuIconImg = new window.Image();
    menuIconImg.src = threeDots;
    menuIconImg.onload = () => {
      setMenuIcon(menuIconImg);
    };

    // Load lock icons
    const lockOpenImg = new window.Image();
    const lockCloseImg = new window.Image();

    lockOpenImg.src = lockOpen;
    lockCloseImg.src = lockClose;

    lockOpenImg.onload = () => {
      setLockIcon(prev => ({ ...prev, open: lockOpenImg }));
    };

    lockCloseImg.onload = () => {
      setLockIcon(prev => ({ ...prev, close: lockCloseImg }));
    };
  }, []);

  const getStatusInfo = (statusId) => {
    switch (statusId) {
      case 1:
        return { text: "statusDraft", color: "#E6E6E6", textColor: "#000", width: '100' };
      case 2:
        return { text: "statusAwaitingApproval", color: "#F1D2B0", textColor: "#000", width: '165' };
      case 3:
        return { text: "statusApproved", color: "#B2DEC8", textColor: "#000", width: '120' };
      case 4:
        return { text: "statusReturned", color: "#F6B8B0", textColor: "#000", width: '120' };
      case 5:
        return { text: "statusDeleted", color: "red", textColor: "#000", width: '120' };
      default:
        return { text: "statusDraft", color: "#E6E6E6", textColor: "#000", width: '100' };
    }
  };

  // Add these handlers for sheet name editing
  const handleDoubleClickSheetName = (sheet, e) => {
    const textNode = e.target;
    const stage = stageRef.current;

    setEditingText({
      id: sheet.id,
      text: sheet.name || "Untitled",
      textNode: textNode
    });
  };

  const handleTextChange = (e) => {
    setEditingText(prev => ({
      ...prev,
      text: e.target.value
    }));
  };

  const handleTextBlur = async () => {
    if (editingText) {
      try {
        const response = await newPut('sheet/rename', {
          sheet_id: editingText.id,
          new_sheet_name: editingText.text || "Untitled"
        });

        if (response.status_code === 200) {
          dispatch(updateSheetName({
            sheetId: editingText.id,
            name: editingText.text
          }));
          console.log("Sheet Name Saved", response);
        }
      } catch (error) {
        console.error("Error updating sheet name:", error);
      }
      setEditingText(null);
    }
  };

  const handleTextKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleTextBlur();
    }
    if (e.key === 'Escape') {
      setEditingText(null);
    }
  };

  const handleContextMenu = (e) => {
    e.evt.preventDefault();

    const stage = e.target.getStage();
    const position = stage.getPointerPosition();

    // Only show context menu if we have a selected sheet
    if (selectedSheetId) {
      setMenuPosition({
        x: e.evt.clientX,
        y: e.evt.clientY
      });

      // Show appropriate menu items based on sheet status
      const sheet = canvas?.layers?.[0]?.groups?.find(group => group.id === selectedSheetId);
      if (sheet) {
        setMenuTargetSheet(sheet);
      }
    }
  };


  const handleDesignStatusChange = (event) => {
    setDesignStatus(event.target.value);
  };

  const approveReturnSheet = async (statusId, sheet_id = selectedSheetId) => {
    if (statusId === 3) {
      try {
        const response = await newPut(`approval/approve`, {
          "sheet_ids": [sheet_id]
        })
        if ([200, 201].includes(response?.status_code)) {
          dispatch(updateSheetStatus({
            sheetId: sheet_id,
            status: 3  // 3 for approved status
          }));
        }
      }
      catch (error) {
        console.log("error", error)
      }
    }
    else if (statusId === 4) {
      const modalElement = modalRef.current;
      const modalInstance = new window.bootstrap.Modal(modalElement);
      modalInstance.show();
      // setReturnModalOpen(true)
      // try {
      //     const response = newPut(`approval/return`, {
      //         "sheet_ids": [sheet_id]
      //     })
      //     console.log("response", response)
      // }
      // catch (error) {
      //     console.log("error", error)
      // }
    }
  };

  const isChannelManager = () => {
    return [3, 4].includes(userSettings?.role_id)
  };

  const [returnComment, setReturnComment] = useState("");

  const handleCommentChange = (event) => {
    setReturnComment(event.target.value);
  };

  const handleReturnSheet = async () => {
    try {
      const response = await newPut(`approval/return`, {
        "sheet_ids": [selectedSheetId],
        "return_reason": returnComment
      });

      if ([200, 201].includes(response?.status_code)) {
        dispatch(updateSheetStatus({
          sheetId: selectedSheetId,
          status: 4  // 4 for returned status
        }));
        // Close modal or reset comment if needed
        setReturnComment('');
      }
    } catch (error) {
      console.error("Error in handleReturnSheet:", error);
    }
  };

  useEffect(() => {
    if (selectedImages && selectedImages.length > 0) {
      // Handle new images being added
      const lastImage = selectedImages[selectedImages.length - 1];
      if (lastImage && lastImage.kind === 'image') {
        // Add the image to your canvas/layer logic here
        const layer = canvas?.layers?.[0];
        if (layer) {
          dispatch(updateLayerElement({
            layerId: layer.id,
            element: lastImage
          }));
        }
      }
    }
  }, [selectedImages]);


  const handleOnDrop = async (e, sheetId) => {
    e.preventDefault();

    if (!sheetId) {
      console.log("No sheet ID provided");
      return;
    }

    const droppedSheet = canvas?.layers?.[0]?.groups?.find((sheet) => sheet.id === sheetId);

    if (droppedSheet) {
      try {
        // Safely parse JSON data
        let droppedData;
        const jsonData = e.dataTransfer.getData('application/json');

        if (!jsonData) {
          console.log('No JSON data found in drop event');
          return;
        }

        try {
          droppedData = JSON.parse(jsonData);
        } catch (parseError) {
          console.error('Failed to parse dropped JSON data:', parseError);
          console.log('Raw JSON data:', jsonData);
          return;
        }

        // Get current number of elements to determine z-index
        const currentElements = droppedSheet.elements || [];
        const isFirstElement = currentElements.length === 0 || !sheetHasElement(droppedSheet);
        const zIndex = isFirstElement ? 0 : currentElements.length + 1;

        if (droppedData.kind === 'text') {
          let layerResponse;
          // console.log("Handling text drop with data:", droppedData);
          // const layerResponse = await newPost_V3('sheet/createSheetLayer', {
          //   sheet_id: sheetId,
          //   layer_type: "text"
          // });

          // if ([200, 201,500,401].includes(layerResponse?.status_code)) {
          console.log("Layer created successfully:", layerResponse);
          const id = layerResponse?.result?.layer_id || Date.now();
          dispatch(addElement({
            sheetId,
            element: {
              id,
              type: 'Text',
              props: {
                ...droppedData.props,
                layerId: id,
                zIndex: zIndex
              }
            }
          }));
          // }
        } else if (droppedData.kind === 'image') {
          let blob
          try {
            const response = await fetch(droppedData.props.src);
            blob = await response.blob();
          } catch (error) {
            blob = await imageGet("download_image", { image_url: droppedData.props.src });
          }

          const file = new File([blob], `image_${Date.now()}.jpg`, { type: blob.type });
          const objectURL = URL.createObjectURL(file);
          file.objectURL = objectURL;

          const formData = new FormData();
          formData.append("files", file);
          formData.append("design_id", designId);
          formData.append('board_id', boardId);

          const uploadResponse = await newImagePost(`file/upload`, formData);
          if ([201, 200].includes(uploadResponse?.status_code)) {
            const layerData = await newPut('layer/changeBackgroundLayerImage', {
              layer_id: droppedSheet.elements[droppedSheet.elements?.length - 1].id,
              layer_type: isFirstElement ? "background" : "image",
              image_url: uploadResponse?.result[0].file_url
            });
            if ([200, 201].includes(layerData?.status_code)) {
              await getDesignDetails(designId);
            }
          }
        } else if (droppedData.kind === 'shape') {
          manageShapeDrop(droppedData, sheetId, zIndex, isFirstElement);
        }
      } catch (error) {
        console.error('Error handling drop:', error);
      }
    }
  };


  const manageShapeDrop = async (droppedData, sheetId, zIndex, isFirstElement) => {
    try {
      // Check if src exists
      if (!droppedData.props?.src) {
        console.error('No source URL found in dropped data:', droppedData);
        return;
      }

      dispatch(addElement({
        sheetId,
        element: {
          id: 'shape_' + Date.now(),
          type: 'Image',
          draggable: true,
          x: droppedData.props.x || 50,
          y: droppedData.props.y || 50,
          width: droppedData.props.width || 200,
          height: droppedData.props.height || 200,
          image_data: {
            data: droppedData.props.src,
            format: 'SVG',
            size: [droppedData.props.width || 200, droppedData.props.height || 200]
          },
          opacity: 1,
          rotation: 0,
          cornerRadius: 0,
          blend_mode: 'normal',
          cropStrategy: 'fit',
          zIndex: zIndex,
          isBackground: isFirstElement
        }
      }));

    } catch (error) {
      console.error('Error converting shape to base64:', error);
      console.error('Dropped data that caused error:', droppedData);
    }
  }

  const getDesignDetails = async (designId) => {
    const urlParams = new URLSearchParams(window.location.search);
    const design_id = urlParams.get("design_id");
    try {
      const designData = await newGet_V3(`design/getDesignDetail?design_id=${design_id || designId}`);
      if (designData?.status_code == 200) {
        // setSheetCount(designData?.result?.sheets.length);
        dispatch(initializeCanvas({
          id: designId,
          sheets: designData?.result?.sheets || []
        }));
        setTimeout(() => {
          dispatch(initializeCanvas({
            id: designId,
            sheets: designData?.result?.sheets || []
          }));
        })
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  // Add this helper function to get actual sheet position from stage
  const getActualSheetPosition = (sheet, index) => {
    // First try to get position from the sheet's transform
    const sheetGroup = stageRef.current?.findOne(`#sheet-${sheet.id}`);
    if (sheetGroup) {
      const pos = sheetGroup.absolutePosition();
      return {
        x: pos.x,
        y: pos.y
      };
    }
    // Fallback to default position if transform not found
    return {
      x: sheet.position?.x || 50 + (index * 600),
      y: sheet.position?.y || 50
    };
  };

  // Add this helper function to get mouse position relative to stage
  const getRelativeMousePosition = (e) => {
    const stage = stageRef.current;
    if (!stage) return null;

    const stageBox = stage.container().getBoundingClientRect();
    const scale = stage.scaleX();

    // Get stage's absolute position and scale
    const stagePos = {
      x: stage.x(),
      y: stage.y()
    };

    // Calculate mouse position relative to stage
    return {
      x: (e.clientX - stageBox.left - stagePos.x) / scale,
      y: (e.clientY - stageBox.top - stagePos.y) / scale
    };
  };

  const handleImageSelect = (e, element, id, sheet) => {
    if (isChannelManager()) {
      return;
    }
    setSelectedImageId(element.id === selectedImageId ? null : element.id);
    setSelectedSheetElement(element)
    setSelectedSheetId(id)
    setSelectedSheet(sheet)
    if (element.id) {
      if (element.id.toString().includes('shape')) {
        setSelectedId(element.id);
      } else {
        if (element.image_type === "background") {
          setSelectedId(`background_${element.id}`);
          const selectedSheet = canvas?.layers?.[0]?.groups?.find(sheet =>
            sheet.elements?.some(el => el.id === element.id)
          );
          if (selectedSheet) {
            setSelectedSheet(selectedSheet);
          }
        } else {
          setSelectedId(`image_${element.id}`);
        }
      }
      setShowSideModal(true);
    } else {
      setSelectedId(null);
      setShowSideModal(false);
    }
  };

  const handleBackgroundSelect = (e, element) => {
    if (isChannelManager()) {
      return;
    }
    if (element.id) {
      if (element?.elements) {
        const el = element.elements.find(el => el.image_type === "background")
        setSelectedId(`background_${el.id}`);
        setSelectedSheetElement(el);
        setSelectedSheet(element);
        setSelectedSheetId(element.id);
        setShowSideModal(true);
      }

    } else {
      setSelectedId(null);
      setShowSideModal(false);
    }
  };

  const handleTextSelect = (textId) => {
    setSelectedId(`text_${textId}`);
    setShowSideModal(true);

    // Find the selected text element
    const textElement = canvas?.layers?.[0]?.groups?.find(group =>
      group.elements?.find(el => el.id === textId)
    )?.elements?.find(el => el.id === textId);

    if (textElement) {
      setSelectedSheetElement(textElement);
    }
  };


  // Add this function before the return statement
  const handleStageClick = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    const clickedOnBackground = e.target.name() === 'background-rect';

    // Only deselect if clicking on empty space and not holding Ctrl/Cmd
    if ((clickedOnEmpty || clickedOnBackground) && !e.evt.ctrlKey && !e.evt.metaKey) {
      setSelectedImageId(null);
      setSelectedTextId(null);
      setSelectedId(null);
      setShowSideModal(false);
      setSelectedSheet(null);
      setSelectedSheetIds([]);

      // Clear transformer
      if (transformerRef.current) {
        transformerRef.current.nodes([]);
        transformerRef.current.getLayer().batchDraw();
      }
    }
  };

  // Add this function to handle opening the create popup
  const openCreatePopUp = (e) => {
    e.cancelBubble = true;
    const stage = stageRef.current;
    const stageBox = stage.container().getBoundingClientRect();
    const pointerPos = stage.getPointerPosition();
    const absolutePos = {
      x: stageBox.left + pointerPos.x + 15,
      y: stageBox.top + pointerPos.y + 5
    };
    setCreatePopupPosition(absolutePos);
  };

  // Add this function to handle closing the create popup
  const handleCreatePopupClose = () => {
    setCreatePopupPosition(null);
  };


  const handleSheetClick = (e, sheet) => {
    // Check if Ctrl (or Cmd on Mac) is pressed
    const isMultiSelectKey = e.evt.ctrlKey || e.evt.metaKey;

    if (isMultiSelectKey) {
      // For multi-select
      setSelectedSheetIds(prev => {
        const isAlreadySelected = prev.includes(sheet.id);
        if (isAlreadySelected) {
          // Remove from selection if already selected
          const newSelection = prev.filter(id => id !== sheet.id);
          // Update transformer
          const nodes = newSelection.map(id =>
            stageRef.current.findOne(`#sheet-${id}`)
          );
          transformerRef.current.nodes(nodes);
          return newSelection;
        } else {
          // Add to selection
          const newSelection = [...prev, sheet.id];
          // Update transformer
          const nodes = newSelection.map(id =>
            stageRef.current.findOne(`#sheet-${id}`)
          );
          transformerRef.current.nodes(nodes);
          return newSelection;
        }
      });
    } else {
      // Single select
      setSelectedSheetIds([sheet.id]);
      setSelectedSheet(sheet);
      setSelectedSheetId(sheet.id);

      // Update transformer for single selection
      const node = stageRef.current.findOne(`#sheet-${sheet.id}`);
      transformerRef.current.nodes([node]);
    }

    // Always update the transformer layer
    transformerRef.current.getLayer().batchDraw();
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    const stage = stageRef.current;
    if (!stage) return;

    const stageBox = stage.container().getBoundingClientRect();
    const scale = stage.scaleX();

    // Calculate relative position
    const mouseX = (e.clientX - stageBox.left - stage.x()) / scale;
    const mouseY = (e.clientY - stageBox.top - stage.y()) / scale;

    // Find which sheet we're over
    const sheets = canvas?.layers?.[0]?.groups || [];
    let foundSheet = false;

    // Check each sheet using their actual stage positions
    sheets.forEach((sheet) => {
      const sheetNode = stage.findOne(`#sheet-${sheet.id}`);
      if (sheetNode) {
        const sheetPos = sheetNode.position();
        const sheetBounds = {
          left: sheetPos.x,
          right: sheetPos.x + 500,
          top: sheetPos.y - 35,
          bottom: sheetPos.y + 500
        };

        if (
          mouseX >= sheetBounds.left &&
          mouseX <= sheetBounds.right &&
          mouseY >= sheetBounds.top &&
          mouseY <= sheetBounds.bottom
        ) {
          setDragSheetId(sheet.id);
          foundSheet = true;
        }
      }
    });

    if (!foundSheet) {
      setDragSheetId(null);
    }
  }

  const handleOnDropContainer = (e) => {
    e.preventDefault();

    const stage = stageRef.current;
    if (!stage) return;

    const stageBox = stage.container().getBoundingClientRect();
    const scale = stage.scaleX();

    // Calculate relative position
    const mouseX = (e.clientX - stageBox.left - stage.x()) / scale;
    const mouseY = (e.clientY - stageBox.top - stage.y()) / scale;

    // Find which sheet we're dropping on using actual stage positions
    const sheets = canvas?.layers?.[0]?.groups || [];
    let targetSheetId = null;

    sheets.forEach((sheet) => {
      const sheetNode = stage.findOne(`#sheet-${sheet.id}`);
      if (sheetNode) {
        const sheetPos = sheetNode.position();
        const sheetBounds = {
          left: sheetPos.x,
          right: sheetPos.x + 500,
          top: sheetPos.y - 35,
          bottom: sheetPos.y + 500
        };

        if (
          mouseX >= sheetBounds.left &&
          mouseX <= sheetBounds.right &&
          mouseY >= sheetBounds.top &&
          mouseY <= sheetBounds.bottom
        ) {
          targetSheetId = sheet.id;
        }
      }
    });

    // Only proceed if we found a valid target sheet
    if (targetSheetId) {
      console.log('Dropping on sheet:', targetSheetId, 'at position:', { x: mouseX, y: mouseY });
      handleOnDrop(e, targetSheetId);
    }

    setDragSheetId(null);
  }

  // Add this helper function to get sheet position
  const getSheetPosition = (sheet, index) => {
    // Check if sheet has position array from API
    if (sheet.position) {
      return sheet.position;

    }

    // If no position is saved, use default grid layout
    return {
      x: 50 + (index * 600),
      y: 50
    };
  };

  const selectionRectRef = useRef();
  const transformerRef = useRef();
  const selection = useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0
  });

  const updateSelectionRect = () => {
    return;
    const node = selectionRectRef.current;
    if (!node) return;

    const stage = stageRef.current;
    const stageScale = stage.scaleX();
    const stagePos = stage.position();

    // Calculate selection bounds in stage coordinates
    const pos = {
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2)
    };

    // Convert to actual stage coordinates
    const actualPos = {
      x: (pos.x - stagePos.x) / stageScale,
      y: (pos.y - stagePos.y) / stageScale,
      width: pos.width / stageScale,
      height: pos.height / stageScale
    };

    node.setAttrs({
      visible: selection.current.visible,
      ...actualPos,
      fill: 'rgba(0, 161, 255, 0.3)',
      stroke: 'rgba(0, 161, 255, 0.7)',
      strokeWidth: 1
    });
    node.getLayer().batchDraw();
  };

  const handleMouseDown = (e) => {
    if (e.evt.button !== 0 || isMenuOpen) return;

    const clickedOnEmpty = e.target === e.target.getStage();
    const clickedOnBackground = e.target.name() === 'background-rect';

    if (!clickedOnEmpty && !clickedOnBackground) return;

    const stage = e.target.getStage();
    const pointer = stage.getPointerPosition();

    selection.current.visible = true;
    selection.current.x1 = pointer.x;
    selection.current.y1 = pointer.y;
    selection.current.x2 = pointer.x;
    selection.current.y2 = pointer.y;

    setIsSelecting(true);
    updateSelectionRect();
  };

  const handleMouseMove = (e) => {
    if (!selection.current.visible) return;

    const stage = e.target.getStage();
    const pointer = stage.getPointerPosition();

    selection.current.x2 = pointer.x;
    selection.current.y2 = pointer.y;
    updateSelectionRect();
  };

  const handleMouseUp = () => {
    if (!selection.current.visible) return;

    const stage = stageRef.current;
    const selBox = {
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x2 - selection.current.x1),
      height: Math.abs(selection.current.y2 - selection.current.y1)
    };

    // Transform selection box coordinates to account for stage transform
    const transform = {
      x: stage.x(),
      y: stage.y(),
      scale: stage.scaleX()
    };

    const selectedIds = [];
    canvas?.layers?.[0]?.groups?.forEach(sheet => {
      const sheetPos = {
        x: (sheet.position?.x || 50) * transform.scale + transform.x,
        y: (sheet.position?.y || 50) * transform.scale + transform.y,
        width: 500 * transform.scale,
        height: 500 * transform.scale
      };

      if (intersects(selBox, sheetPos)) {
        selectedIds.push(sheet.id);
      }
    });

    setSelectedSheetIds(selectedIds);
    if (selectedIds.length > 0) {
      setSelectedSheetId(selectedIds[0]);
      const nodes = selectedIds.map(id =>
        stageRef.current.findOne(`#sheet-${id}`)
      );
      transformerRef.current.nodes(nodes);
      transformerRef.current.getLayer().batchDraw();
    }

    selection.current.visible = false;
    setIsSelecting(false);
    updateSelectionRect();
  };

  // Helper function to check intersection between two rectangles
  const checkIntersection = (r1, r2) => {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    );
  };

  // Add right-click handler
  const handleContextMenus = (e) => {
    e.evt.preventDefault();

    const stage = e.target.getStage();
    const position = stage.getPointerPosition();

    // Only show context menu if we have a selected sheet
    if (selectedSheetId) {
      setMenuPosition({
        x: e.evt.clientX,
        y: e.evt.clientY
      });

      // Show appropriate menu items based on sheet status
      const sheet = canvas?.layers?.[0]?.groups?.find(group => group.id === selectedSheetId);
      if (sheet) {
        setMenuTargetSheet(sheet);
      }
    }
  };

  // Add helper function for checking rectangle intersection
  const intersects = (r1, r2) => {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    );
  };

  // Modify the sheet render to show selection
  const renderSheet = (sheet, index) => (
    <Group
      key={sheet.id}
      id={`sheet-${sheet.id}`}
      x={sheet.position?.x ?? (50 + (index * 600))}
      y={sheet.position?.y ?? 50}
      draggable={!isMenuOpen}
      // onClick={(e) => handleSheetClick(e, sheet)}
      onDragStart={(e) => {
        if (isMenuOpen) {
          e.evt.preventDefault();
          return;
        }
        handlePageDragStart(e);
      }}
      onDragEnd={(e) => {
        if (isMenuOpen) {
          e.evt.preventDefault();
          return;
        }
        handlePageDragEnd(e, sheet.id);
      }}
    >
      <Rect
        name="background-rect"
        width={500}
        height={500}
        shadowColor="rgba(0,0,0,0.1)"
        shadowBlur={10}
        shadowOffset={{ x: 0, y: 2 }}
        cornerRadius={2}
        onClick={(e) => handleBackgroundSelect(e, sheet)}
        onDragOver={(e) => {
          e.cancelBubble = true;
          setDragSheetId(sheet.id);
        }}
      />

      {/* Sheet Header */}
      <Group y={-35}>
        {/* Header Background */}
        <Rect
          width={500}
          height={30}
          fill="transparent"
          cornerRadius={[5, 5, 0, 0]}
        />
        <Html>
          <div style={{
            width: '500px',
            height: '30px',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 10px'
          }}>
            {/* Left side - Sheet name and status */}
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}>
              {/* Sheet Name */}
              {editingText && editingText.id === sheet.id ? (
                <input
                  type="text"
                  value={editingText.text}
                  onChange={handleTextChange}
                  onBlur={handleTextBlur}
                  onKeyDown={handleTextKeyDown}
                  autoFocus
                  style={{
                    width: 'auto',
                    minWidth: '150px',
                    height: '24px',
                    fontSize: '14px',
                    padding: '0 5px',
                    margin: '0',
                    border: 'none',
                    borderRadius: '3px',
                    background: 'transparent',
                    fontFamily: 'inherit',
                    outline: 'none'
                  }}
                />
              ) : (
                <span
                  style={{
                    fontSize: '14px',
                    cursor: 'pointer',
                    padding: '3px 5px',
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "230px"
                  }}
                  title={sheet.name || "Untitled"}
                  onDoubleClick={(e) => handleDoubleClickSheetName(sheet, e)}
                >
                  {sheet.name || "Untitled"}
                </span>
              )}

              {/* Status Tag */}
              {!isChannelManager() && (
                <div style={{
                  backgroundColor: getStatusInfo(sheet.status_id).color,
                  borderRadius: '12px',
                  padding: '3px 12px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: (getStatusInfo(sheet.status_id).width) + 'px',
                  height: '24px'
                }}>
                  <span style={{
                    fontSize: '12px',
                    color: getStatusInfo(sheet.status_id).textColor,
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    fontWeight: "700"
                  }}>
                    {t(getStatusInfo(sheet.status_id).text)}
                  </span>
                </div>
              )}
            </div>

            {/* Right side controls */}
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px'
            }}>
              {/* Lock Icon */}
              {lockIcon && !isChannelManager() && (
                <img
                  src={sheet.is_locked ? lockClose : lockOpen}
                  alt="Lock"
                  style={{
                    width: '19px',
                    height: '21px',
                    cursor: lockLoadingStates[index] ? 'wait' : 'pointer'
                  }}
                  onClick={() => lockAndUnlockSheet(sheet, index)}
                />
              )}

              {/* Divider */}
              {sheet.status_id !== 3 && !isChannelManager() && isProjectActive() && (
                <div style={{
                  width: '1px',
                  height: '20px',
                  backgroundColor: '#ddd'
                }} />
              )}

              {/* Menu Icon */}
              {sheet.status_id !== 3 && !isChannelManager() && menuIcon && isProjectActive() && (
                <img
                  src={threeDots}
                  alt="Menu"
                  style={{
                    width: '19px',
                    height: '21px',
                    cursor: 'pointer'
                  }}
                  onClick={(e) => handleMenuClick(e, sheet)}
                  key="menu-icon"
                />
              )}

              {/* Approval Dropdown */}
              {userSettings?.permissions && isChannelManager() && (
                <>
                  {sheet.status_id != 2 ? (
                    <p className={`font-noto-sans fs-12 fw-600 px-2 mb-0 ${sheetStatus.find(status => status.id === sheet.status_id)?.name.toLowerCase() || 'draft'}`} style={{
                      borderRadius: "13px",
                      padding: "2px"
                    }}>
                      {sheetStatus.find(status => status.id === sheet.status_id)?.name || 'Draft'}
                    </p>
                  ) : (
                    <div className="rounded">
                      <Select
                        value={sheet.status_id}
                        name="status"
                        onChange={handleDesignStatusChange}
                        onClick={() => setSelectedSheetId(sheet.id)}
                        displayEmpty
                        disabled={sheet.status_id != 2}
                        inputProps={{ "aria-label": "Without label" }}
                        className={`font-noto-sans konva-select`}
                        style={{ color: 'black', fontSize: '12px', fontWeight: '600' }}
                      >
                        {sheetStatus.map((status) => (
                          <MenuItem
                            key={status.id}
                            value={status.id}
                            sx={{ fontSize: '14px' }}
                            style={{
                              display: sheet.status_id === status.id ? 'none' : null
                            }}
                            onClick={() => approveReturnSheet(status.id, sheet.id)}
                          >
                            {status.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Html>
      </Group>

      {/* Sheet Content */}
      <Rect
        width={500}
        height={500}
        fill={sheet.backgroundColor || "white"}
        stroke={dragSheetId === sheet.id ? "#4CAF50" : "#ddd"}
        strokeWidth={dragSheetId === sheet.id ? 2 : 1}
        shadowColor="rgba(0,0,0,0.1)"
        shadowBlur={10}
        shadowOffset={{ x: 0, y: 2 }}
        cornerRadius={2}
        // onClick={(e) => { handleBackgroundSelect(e, sheet) }}
        onDragOver={(e) => {
          e.cancelBubble = true;
          setDragSheetId(sheet.id);
        }}
      />

      {/* Sheet Elements */}
      {sheet.elements?.map(element => {
        switch (element.type) {
          case 'Image':
            return (
              <DraggableImage
                key={element.id}
                element={{
                  ...element,
                  draggable: sheet?.zIndex == 0 ? false : (!isMenuOpen && !sheet.is_locked)
                }}
                sheetId={sheet.id}
                handleDragEnd={handleDragEnd}
                handleDragStart={handleDragStart}
                handleTransformEnd={handleTransformEnd}
                isSelected={selectedImageId === element.id}
                onSelect={(e) => {
                  handleImageSelect(e, element, sheet.id, sheet);
                  setShowCommentSection(false);
                }}
              />
            );
          case 'Text':
            return (
              <DraggableText
                key={element.id}
                element={{
                  ...element,
                  draggable: !isMenuOpen && !sheet.is_locked
                }}
                sheetId={sheet.id}
                handleDragEnd={handleDragEnd}
                handleDragStart={handleDragStart}
                handleDoubleClick={handleDoubleClick}
                handleTransformEnd={handleTransformEnd}
                isSelected={selectedId === `text_${element.id}`}
                onSelect={handleTextSelect}
              />
            );
          default:
            return null;
        }
      })}

    </Group>
  );

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', height: '100%' }}
      onDragOver={(e) => handleDragOver(e)}
      onDrop={(e) => handleOnDropContainer(e)}

    >  {
        (error || isCommentDeleted)
          ? <CountdownPopup />
          : null
      }

      {/* n && !isSelecting */}
      <Stage
        ref={stageRef}
        width={stageSize.width}
        height={stageSize.height}
        onWheel={handleWheel}
        draggable={!isMenuOpen}
        scaleX={scale}
        scaleY={scale}
        x={stagePosition.x}
        y={stagePosition.y}
        // onContextMenu={handleContextMenus}
        onClick={handleStageClick}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onContextMenu={(e) => { e.evt.preventDefault() }}
      >
        <Layer>
          {canvas?.layers?.[0]?.groups?.map(renderSheet)}
          {/* Add the "+" button after the last sheet */}
          {canvas?.layers?.[0]?.groups?.length > 0 && !isChannelManager() && isProjectActive() && (
            <Group
              x={(canvas.layers[0].groups.length * 600) + 100}
              y={250}
              onClick={(e) => openCreatePopUp(e)}
              cursor={addingNewSheetLoading ? "wait" : "pointer"}
              onMouseEnter={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = 'pointer';
              }}
              onMouseLeave={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = 'default';
              }}
            >
              <Circle
                radius={15}
                fill="#000"
                shadowColor="rgba(0,0,0,0.2)"
                shadowBlur={5}
                shadowOffset={{ x: 0, y: 2 }}
              />
              <Text
                text="+"
                x={-5.5}
                y={-8}
                fontSize={20}
                fill="white"
                fontStyle="bold"
              />
            </Group>
          )}
          {/* Add a "+" button if there are no sheets */}
          {!isChannelManager() && (!canvas?.layers?.[0]?.groups || canvas.layers[0].groups.length === 0) && (
            <Group
              x={300}
              y={250}
              onClick={(e) => openCreatePopUp(e)}
              cursor={addingNewSheetLoading ? "wait" : "pointer"}
              onMouseEnter={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = 'pointer'; // Set the cursor to pointer
              }}
              onMouseLeave={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = 'default'; // Reset to default cursor
              }}
            >
              <Circle
                radius={15}
                fill="#000"
                shadowColor="rgba(0,0,0,0.2)"
                shadowBlur={5}
                shadowOffset={{ x: 0, y: 2 }}
              />
              <Text
                text="+"
                x={-5}
                y={-8}
                fontSize={20}
                fill="white"
                fontStyle="bold"
              />
            </Group>
          )}
          <Rect
            ref={selectionRectRef}
            fill="rgba(0, 161, 255, 0.3)"
            visible={false}
          />
          <Transformer
            ref={transformerRef}
            boundBoxFunc={(oldBox, newBox) => oldBox} // Prevent resizing
            enabledAnchors={[]} // Hide resize handles
            borderStroke="#0096FF" // Selection border color
            borderStrokeWidth={2}
            padding={0}
            rotateEnabled={false}
            keepRatio={false}
          />
        </Layer>
      </Stage>
      {
        sheet_status_id !== 3 &&
        <>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            open={Boolean(menuPosition)}
            anchorReference="anchorPosition"
            anchorPosition={
              menuPosition
                ? { top: menuPosition.y, left: menuPosition.x }
                : undefined
            }
            onClose={handleMenuClose}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            slotProps={{
              paper: {
                style: {
                  transform: 'none',
                }
              }
            }}
          >
            {(sheet_status_id === 1 || sheet_status_id === 4) && !selectedSheet?.is_locked &&
              <>
                {/* {selectedSheet?.elements?.[0]?.image_data?.data &&
                                <MenuItem
                                    onClick={() => {
                                        handleMenuClose();
                                    }}
                                    disableRipple
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_design"
                                >
                                    <img className="mx-2" src={replace} alt='lockOpen' width={19} height={21} />
                    {t("sheetDropdownReplace")}
                                </MenuItem>
                } */}
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    handleDeleteSheet(selectedSheetId);
                  }}
                  disableRipple
                >
                  <img className="mx-2" src={remove} alt='remove' width={19} height={21} />
                  {t("sheetDropdownRemove")}
                </MenuItem>
              </>
            }
            {(sheet_status_id === 1 || sheet_status_id === 4) && !selectedSheet?.is_locked && sheetHasElement(selectedSheet) &&
              <Divider sx={{ my: 0.5 }} />
            }
            {sheet_status_id !== 3 && sheetHasElement(selectedSheet) &&
              (sheet_status_id === 1 || sheet_status_id === 4 ? (
                <MenuItem
                  onClick={() => {
                    handleMenuClose();

                  }}
                  disableRipple
                  data-bs-toggle="modal"
                  data-bs-target="#approvalModal">
                  {/* <MoreHorizIcon /> */}
                  <img className="mx-2" src={verified} alt='lockOpen' width={19} height={21} />
                  {selectedSheet?.is_locked ? `${t("sheetDropdownApproval")}` : `${t("sheetDropdownLockApproval")}`}
                </MenuItem>
              ) : <>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                  }}
                  disableRipple
                  data-bs-toggle="modal"
                  data-bs-target="#cancelModal">
                  {/* <MoreHorizIcon /> */}
                  <img className="mx-2" src={verified} alt='lockOpen' width={19} height={21} />
                  {t("sheetDropdownCancelApproval")}
                </MenuItem>
                {
                  userSettings?.role_id === 2
                    ? sheetStatus.map((status) => status.id !== 2 && (
                      <MenuItem
                        key={status.id}
                        value={status.id}
                        sx={{ fontSize: '14px' }}
                        style={{
                          display: selectedSheetId.status_id === status.id ? 'none' : null
                        }}
                        onClick={() => approveReturnSheet(status.id)}
                      >
                        {status.label}
                      </MenuItem>
                    ))
                    : null
                }
              </>
              )
            }
          </StyledMenu>
        </>
      }
      {/* Request Approval */}
      <div className="modal fade" id="approvalModal" tabIndex="-1" aria-labelledby="approvalModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content px-3">
            <div className="modal-header border-0 font-noto-sans">
              <div className='upload_design  fs-20' style={{ fontWeight: "700" }}>
                {t("approvalPopupHeader")}
              </div>
            </div>
            <div className="modal-body font-noto-sans">
              <div className="d-flex  gap-3">
                <p className="fw-400 fs-16 " > {t("approvalPopupText")} </p>
              </div>
              <div className="modal-footer border-0">
                <p type="button" className="cancel_modal_footer" data-bs-dismiss="modal" > {t("approvalPopupButton1")} </p>
                <button style={{ paddingLeft: "6px" }} type="button" className="send_modal_footer" data-bs-dismiss="modal" onClick={() => { lockNRequestApproval(); handleLockNRequestApproval() }}>
                  {t("approvalPopupButton2")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Cancel Approval */}
      <div className="modal fade" id="cancelModal" tabIndex="-1" aria-labelledby="cancelModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content px-3">
            <div className="modal-header border-0 font-noto-sans">
              <div className='upload_design  fs-20' style={{ fontWeight: "700" }}>
                {t("cancelPopupHeader")}
              </div>
            </div>
            <div className="modal-body font-noto-sans">
              <div className="d-flex  gap-3">
                <p className="fw-400 fs-16 " > {t("cancelPopupText")}</p>
              </div>
              <div className="modal-footer border-0">
                <p type="button" className="cancel_modal_footer" data-bs-dismiss="modal" >{t("cancelPopupButton1")}</p>
                <button style={{ paddingLeft: "6px" }} type="button" className="send_modal_footer"
                  data-bs-dismiss="modal" onClick={() => { cancelRequestApproval(); handleCancelRequestApproval(); }}>
                  {t("cancelPopupButton2")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {returnModalOpen && */}
      <div className="modal fade " id="returnModal" ref={modalRef} tabIndex="-1" aria-labelledby="returnModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content px-3">
            <div className="modal-header border-0 font-noto-sans">
              <div className='upload_design  fs-20' style={{ fontWeight: "700" }}>
                {t("returnPopupText")}
              </div>
            </div>
            <div className="modal-body font-noto-sans">
              <div className="d-flex gap-3">
                <p className="fw-400 fs-16 " > {t("returnPopupHeader")}</p>
              </div>
              <textarea
                type='text'
                rows="2"
                name="return_comments"
                onChange={handleCommentChange}
                value={returnComment}
                className="form-control from-input"
                id="return_comments"
                placeholder={t("returnPopupPlaceholder")}
                maxLength={500} />
              <div className="modal-footer border-0">
                <p type="button" className="cancel_modal_footer" data-bs-dismiss="modal" >{t("cancel")}</p>
                <button style={{ paddingLeft: "10px" }} type="button" className="send_modal_footer" data-bs-dismiss="modal" onClick={() => handleReturnSheet()}  >  {t("returnPopupButton")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Create Popup Menu */}
      <StyledMenu
        id="create-menu"
        MenuListProps={{
          'aria-labelledby': 'create-button',
        }}
        open={Boolean(createPopupPosition)}
        anchorReference="anchorPosition"
        anchorPosition={
          createPopupPosition
            ? { top: createPopupPosition.y, left: createPopupPosition.x }
            : undefined
        }
        onClose={handleCreatePopupClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            style: {
              transform: 'none',
            }
          }
        }}
      >
        {/* <MenuItem
          onClick={() => {
            handleCreatePopupClose();
            addNewSheet();
          }}
          disableRipple
        >
          <span className="mx-2">Create Blank Sheet</span>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            setCreateSheetFromImage(true)
            setUploadPopupOpen(true)
            setSelectedSheetElement(null);
            handleCreatePopupClose();
          }}
          disableRipple
        // data-bs-toggle="modal"
        // data-bs-target="#add_design"
        >
          <span className="mx-2">{t("ADD_SHEET")}</span>
        </MenuItem>
      </StyledMenu>

      {uploadPopUpOpen && <UploadComponent
        instanceId="normal-upload"
        screen="visionStudio"
        // layerId={layerId}
        isMultiSelect={true}
        setPopupState={setUploadPopupOpen}
        getSheetDetails={getSheetDetails}
        getDesignDetails={getDesignDetails}
      />}

    </div >
  );
};

export default KonvaCanvas;