import image1 from '../../assets/image/image1.jpg';
import image2 from '../../assets/image/image2.png';
import image3 from '../../assets/image/image3.jpg';
import image4 from '../../assets/image/image4.png';
import image5 from '../../assets/image/image5.png';

export const layoutData = {
  layouts: [
    {
      elements: [
        {
          type: 'image',
          id: `image_${Date.now()}`,
          properties: {
            src: image1
          }
        },
        {
          type: 'image',
          id: `image_${Date.now()}`,
          properties: {
            src: image2
          }
        },
        {
          type: 'image',
          id: `image_${Date.now()}`,
          properties: {
            src: image3
          }
        },
        {
          type: 'image',
          id: `image_${Date.now()}`,
          properties: {
            src: image4
          }
        },
        {
          type: 'image',
          id: `image_${Date.now()}`,
          properties: {
            src: image5
          }
        }
      ]
    }
  ]
};